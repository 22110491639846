<template>

  <div class="intro-y box mt-5 max-w-[600px] mx-auto">
    <div
      class="flex flex-col sm:flex-row items-center p-5 border-gray-200"
    >
      <h2 class="font-medium text-base mx-auto">Alumnos Registrados por Comunidad</h2>
    </div>
    <div id="hoverable-table" class="p-5 pt-0">
      <div class="preview">
        <div class="overflow-x-auto">
          <table class="table">
            <thead>
            <tr>
              <th
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Comunidad
              </th>
              <th
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Total Comunidad
              </th>
              <th
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Porcentaje del Total
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="comunidad in comunidadesOrdenadas"
              :key="`comunidad-${comunidad.indice_herramienta}`"
              class="hover:bg-gray-200"
            >
              <td class="border">
                <router-link v-if="comunidad.cantidadAlumnosRegistrados_herramienta!==0" :to="{name: 'admin.stats.alumnos.comunidad', params: {comunidad: comunidad.codigo_herramienta}}">
                  {{ comunidad.nombre_herramienta }}
                </router-link>
                <p v-else>{{ comunidad.nombre_herramienta }}</p>
                </td>
              <td class="border">

                <router-link v-if="comunidad.cantidadAlumnosRegistrados_herramienta!==0" :to="{name: 'admin.stats.alumnos.comunidad', params: {comunidad: comunidad.codigo_herramienta}}">
                  {{ comunidad.cantidadAlumnosRegistrados_herramienta }}
                </router-link>
                <p v-else>{{ comunidad.cantidadAlumnosRegistrados_herramienta }}</p>
                </td>
              <td class="border">
                <router-link v-if="comunidad.cantidadAlumnosRegistrados_herramienta!==0" :to="{name: 'admin.stats.alumnos.comunidad', params: {comunidad: comunidad.codigo_herramienta}}">
                  {{ (comunidad.porcentajeAlumnosRegistrados_herramienta * 100).toFixed(2) }} %
                </router-link>
                <p v-else>{{ (comunidad.porcentajeAlumnosRegistrados_herramienta * 100).toFixed(2) }} %</p>
                </td>
            </tr>
            <tr>
              <th class="border">Total</th>
              <th class="border">{{ sumaAlumnos }}</th>
              <th class="border">{{ sumaAlumnosPorcentajes }} %</th>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { orderBy } from 'lodash'
import { mapState } from 'vuex'
export default defineComponent({
  name: 'AlumnosRegistradosComunidad',
  props: {
    comunidades: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('administradores/auth', ['administrador', 'usuario']),
    sumaAlumnos() {
      let suma = 0
      this.$props.comunidades.forEach(campus => {
        suma += campus.cantidadAlumnosRegistrados_herramienta ?? 0
      })

      return suma
    },
    sumaAlumnosPorcentajes() {
      let suma = 0
      this.$props.comunidades.forEach(campus => {
        suma += campus.porcentajeAlumnosRegistrados_herramienta * 100 ?? 0
      })

      return Math.round(suma)
    },
    comunidadesOrdenadas() {
      return orderBy(
        this.$props.comunidades,
        'orden_herramienta'
      )
    }
  }
})
</script>

<style scoped>

</style>
