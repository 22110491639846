<template>
  <div id="hoverable-table" class="p-5 pt-0 mt-8">
    <div class="preview">
      <div class="overflow-x-auto">
        <table class="table">
          <thead>
          <tr>
            <th
              class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
            >
              Convocatoria
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="convocatoria in convocatoriasOrdenadas"
            :key="`convocatoria-${convocatoria.indice}`"
            class="hover:bg-gray-200"
          >
            <td class="border">
              <router-link v-if="convocatoria.cantidadInteresAlumnos!==0" :to="{name: 'admin.actions.reminders.show', params: {convocatoria: convocatoria.codigo}}">
                {{ convocatoria.nombre }}
              </router-link>
              <p v-else>{{ convocatoria.nombre }}
              </p>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { orderBy } from 'lodash'

export default defineComponent({
  data() {
    return {
      convocatorias: []
    }
  },

  computed: {
    convocatoriasOrdenadas() {
      return orderBy(
        this.convocatorias,
        'orden'
      )
    }
  },

  methods: {
    ...mapActions('administradores/convocatorias', ['obtenerConvocatoriasSinReactividad'])
  },

  mounted() {
    this.obtenerConvocatoriasSinReactividad()
      .then(convocatorias => {
        this.convocatorias = convocatorias
      })
  }
})
</script>

<style scoped>

</style>
