import administradores from './administradores'
import alumnos from './alumnos'
import colaboradores from './colaboradores'
import ErrorPage from '@/views/error-page/Main'

const routes = [
  ...administradores,
  ...alumnos,
  ...colaboradores,
  {
    path: '/error-page',
    name: 'error-page',
    meta: { requiresAuth: false },
    component: ErrorPage
  },
  {
    path: '/:pathMatch(.*)*',
    meta: { requiresAuth: false },
    redirect: '/login'
  }
]

export default routes
