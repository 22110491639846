<template>
  <div>
    <!--    <DarkModeSwitcher />-->
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <div class="my-auto">
            <img
              alt="Vibrart Logo 2025"
              class="-intro-x w-2/3 -mt-16"
              :src="require(`@/assets/images/logos.png`)"
            />
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 md:my-10 xl:my-0">
          <div class="my-auto">
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              <img
                alt="Vibrart Logo 2025"
                class="w-2/3 mx-auto mb-10"
                :src="require(`@/assets/images/logos.png`)"
              />
            </div>

            <div class="mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
              <!--- Inicio -->
              <h2
                v-if="$slots.inicio"
                class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
              >
                <slot name="inicio"></slot>
              </h2>

              <!-- Form -->
              <div
                v-if="$slots.form"
                class="intro-x mt-8"
              >
                <slot name="form"></slot>
              </div>

              <!-- Actions -->
              <div
                v-if="$slots.actions"
                class="intro-x mt-5 xl:mt-8 text-center xl:text-left"
              >
                <slot name="actions"></slot>
              </div>

              <!-- Contraseña Olvidada -->
              <div
                v-if="$slots.forgotten"
                class="intro-x flex justify-items-around text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
              >
                <slot name="forgotten"></slot>
              </div>

              <!-- Extras -->
              <div
                v-if="$slots.extra"
                class="intro-x flex justify-items-around text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
              >
<!--                <p class="cursor-pointer mr-auto">
                  ¿Olvidaste tu Contraseña?
                </p>-->

                <slot name="extra"></slot>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->

        <!-- BEGIN: Failed Notification Content -->
        <slot name="failed-notificacion"></slot>
        <!-- END: Failed Notification Content -->
        <!-- BEGIN: Success Notification Content -->
        <slot name="success-notificacion"></slot>
        <!-- END: Success Notification Content -->
      </div>
    </div>
  </div>
</template>

<script>
</script>

<style>
.app-version {
  color: rgba(255,255,255, .5);
}
</style>
