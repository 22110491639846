<template>
<div class="py-4" v-if="cargado===1">
  <h1 class="text-xl">Alumnos Registrados del Campus {{ campus.nombre }}</h1>
  <TablaAlumnos :alumnos="alumnos"></TablaAlumnos>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import TablaAlumnos from '@/components/alumnos/TablaAlumnos'
import { orderBy } from 'lodash'
export default defineComponent({
  name: 'AlumnosRegistradosPorCampus',
  components: { TablaAlumnos },
  data() {
    return {
      campus: null,
      cargado: 0,
      alumnos: []
    }
  },
  computed: {
    alumnosOrdenados() {
      return orderBy(
        this.alumnos,
        'orden'
      )
    }
  },
  methods: {
    ...mapActions('administradores/convocatorias', ['obtenerCampusPorCodigo', 'obtenerAlumnosPorCampus']),
    buscarAlumnos: function () {
      if (this.cargado < 1) {
        return
      }
      this.obtenerAlumnosPorCampus({
        campusIndice: this.campus.indice
      })
        .then(alumnos => {
          this.alumnos = alumnos
        })
    }
  },
  mounted() {
    this.obtenerCampusPorCodigo(this.$route.params.campus)
      .then(campus => {
        this.campus = campus
        this.cargado++
        this.buscarAlumnos()
      })
  }
})
</script>

<style scoped>

</style>
