<template>
  <SeccionDatos>
    <template #titulo>
      Información
    </template>

    <template #default>
      <DatoGroup
        v-for="participacionDato in participacionesDatosOrdenados"
        :key="`${participacionTipoIndice}-participanteTipo-${participacionDato.indice}`"
      >
        <Dato>
          <template #nombre>
            {{ participacionDato.nombre }} <span v-if="participacionDato.opcional">- (Opcional)</span>
          </template>
          <template #descripcion>
            {{ participacionDato.descripcion }}
          </template>
          <template #dato>
            <Component
              :is="participacionDato.tipo"
              :extensiones="participacionDato.extensiones"
              :tabla="participacionDato.tablas"
              v-model="datos[participacionDato.indice].valor"
              @titulo="(val) => datos[participacionDato.indice].titulo = val"
            />
          </template>
        </Dato>
      </DatoGroup>
    </template>
  </SeccionDatos>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { orderBy } from 'lodash'
import SeccionDatos from '@/views/alumnos/convocatorias/participaciones/crear/partials/SeccionDatos.vue'
import Dato from '@/views/alumnos/convocatorias/participaciones/crear/partials/form/Dato.vue'
import DatoGroup from '@/views/alumnos/convocatorias/participaciones/crear/partials/form/DatoGroup.vue'

// Datos Tipos
import Archivo from '@/views/alumnos/convocatorias/participaciones/crear/partials/tipos/Archivo.vue'
import Numero from '@/views/alumnos/convocatorias/participaciones/crear/partials/tipos/Numero.vue'
import SeleccionUnica from '@/views/alumnos/convocatorias/participaciones/crear/partials/tipos/SeleccionUnica.vue'
import Texto from '@/views/alumnos/convocatorias/participaciones/crear/partials/tipos/Texto.vue'
import TextoArea from '@/views/alumnos/convocatorias/participaciones/crear/partials/tipos/TextoArea.vue'

export default defineComponent({
  name: 'LlenarDatos',
  components: {
    DatoGroup,
    Dato,
    SeccionDatos,
    Archivo,
    Numero,
    SeleccionUnica,
    Texto,
    TextoArea
  },
  props: {
    participacionTipoIndice: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      participacionTipo: {},
      participacionesDatos: [],
      datos: {}
    }
  },

  computed: {
    participacionesDatosOrdenados() {
      return orderBy(
        this.participacionesDatos,
        'orden'
      )
    }
  },

  methods: {
    ...mapActions('alumnos/convocatorias', ['obtenerParticipacionesDatosPorParticipacionTipoIndice'])
  },

  watch: {
    datos: {
      deep: true,
      handler() {
        this.$emit('update', this.datos)
      }
    }
  },

  mounted() {
    this.obtenerParticipacionesDatosPorParticipacionTipoIndice(this.$props.participacionTipoIndice)
      .then(participacionesDatos => {
        this.participacionesDatos = participacionesDatos

        participacionesDatos.forEach(participacionDato => {
          let valorPredeterminado = null

          switch (participacionDato.tipo) {
            case 'Texto' || 'TextoArea' || 'SeleccionUnica':
              valorPredeterminado = ''
              break
            case 'Numero':
              valorPredeterminado = 0
              break

            default:
              valorPredeterminado = ''
          }

          this.datos[participacionDato.indice] = {
            orden: participacionDato.orden,
            tipo: participacionDato.tipo,
            nombre: participacionDato.nombre,
            llenado: participacionDato.llenado,
            clave: participacionDato.clave,
            valor: valorPredeterminado,
            titulo: '',
            opcional: participacionDato.opcional,
            noSubirAirtable: participacionDato.noSubirAirtable,
            relacionar: !!participacionDato.tablas
          }
        })

        this.$emit('update', this.datos)
      })
  }
})
</script>

<style scoped>

</style>
