import { orderBy } from 'lodash'

export function convocatoriasPorEdicion(state) {
  return (edicionIndice) => {
    return orderBy(
      state.convocatorias.filter(convocatoria => {
        return convocatoria.ediciones_indice === edicionIndice
      }),
      'orden'
    )
  }
}

export function edicionesOrdenadas(state) {
  return orderBy(
    state.ediciones,
    'orden'
  )
}

export function convocatoriasActivasOrdenadas(state) {
  return orderBy(
    state.convocatorias.filter(convocatoria => convocatoria.activo),
    'orden'
  )
}

export function convocatoriasOrdenadas(state) {
  return orderBy(
    state.convocatorias,
    'orden'
  )
}
