export default function () {
  return {
    convocatorias: [],
    participacionesDatos: [],
    participacionesTipos: [],
    participaciones: [],
    ediciones: [],
    visualizaciones: {}
  }
}
