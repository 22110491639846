import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth && !await to.meta.validate()) {
    if (to.path.indexOf('/admin') > -1) {
      next({ name: 'admin.login' })
    } else if (to.path.indexOf('/colaboradores') > -1) {
      next({ name: 'colab.login' })
    } else {
      next({ path: '/login' })
    }
  } else {
    next()
  }
})

export default router
