<template>
  <div class="intro-y box mt-5">
    <div
      class="flex flex-col sm:flex-row items-center p-5 border-gray-200"
    >
      <h2 class="font-medium text-base mx-auto">Interesados por Campus y Convocatoria</h2>
    </div>
    <div id="hoverable-table" class="p-5 pt-0">
      <div class="preview">
        <div class="overflow-x-auto">
          <table class="table">
            <thead>
            <tr>
              <th
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Campus
              </th>
              <th
                v-for="convocatoria in convocatoriasAutorizadas"
                :key="`convocatoria-titulo-${convocatoria.indice}`"
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                {{ convocatoria.nombre_herramienta }}
              </th>
              <th
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Total Campus
              </th>
              <!--                <th
                                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
                              >
                                Porcentaje del Total
                              </th>-->
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="campus in campusesAutorizados"
              :key="`campus-${campus.indice_herramienta}`"
              class="hover:bg-gray-200"
            >
              <td class="border">{{ campus.nombre_herramienta }}</td>
              <td
                v-for="convocatoria in convocatoriasAutorizadas"
                :key="`campus-${campus.indice}-convocatoria-celda-${convocatoria.indice}`"
                class="border"
              >
                <router-link v-if="campus[convocatoria.campos_cantidadInteres_herramienta]!==0" :to="{name: 'admin.stats.intereses.campus', params: {campus: campus.codigo_herramienta, convocatoria: convocatoria.codigo_herramienta}}">
                  {{ campus[convocatoria.campos_cantidadInteres_herramienta] }}
                </router-link>
                <p v-else>
                  {{ campus[convocatoria.campos_cantidadInteres_herramienta] }}
                </p>
              </td>
              <td class="border">{{ sumaCampus(campus) }}</td>
              <!--                <td class="border">{{ sumaCampusPorcentaje(campus).toFixed(2) }} %</td>-->
            </tr>
            <tr>
              <th class="border">Total</th>
              <th
                v-for="convocatoria in convocatoriasAutorizadas"
                :key="`convocatoria-pie-${convocatoria.indice}`"
                class="border"
              >
                {{ sumaConvocatoria(convocatoria) }}
              </th>
              <th class="border">{{ sumaTotal }}</th>
              <!--                <th class="border">{{ sumaTotalPorcentajes }} %</th>-->
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { orderBy } from 'lodash'
import { mapState } from 'vuex'
export default defineComponent({
  name: 'AlumnosInteresadosCampusConvocatoria',
  props: {
    convocatorias: {
      type: Array,
      required: true
    },
    campuses: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('administradores/auth', ['administrador', 'usuario']),
    sumaTotalPorcentajes() {
      let suma = 0
      this.$props.campuses.forEach(campus => {
        suma += this.sumaCampusPorcentaje(campus)
      })

      return Math.round(suma)
    },
    sumaTotal() {
      let suma = 0
      this.$props.campuses.forEach(campus => {
        suma += this.sumaCampus(campus)
      })

      return suma
    },
    convocatoriasAutorizadas() {
      return orderBy(
        this.$props.convocatorias.filter((convocatoria) => {
          return this.administrador.convocatorias_indice ? this.administrador.convocatorias_indice === convocatoria.indice_herramienta : true
        }),
        'orden_herramienta'
      )
    },
    sumaCampus() {
      return (campus) => {
        let suma = 0
        this.$props.convocatorias.forEach(convocatoria => {
          suma += campus[convocatoria.campos_cantidadInteres_herramienta] ?? 0
        })

        return suma
      }
    },
    campusesAutorizados() {
      return orderBy(
        this.$props.campuses.filter((campus) => {
          return this.administrador.campuses_indice ? this.administrador.campuses_indice === campus.indice_herramienta : true
        }),
        'orden_herramienta'
      )
    },
    sumaConvocatoria() {
      return (convocatoria) => {
        let suma = 0

        this.campusesAutorizados.forEach(campus => {
          suma += campus[convocatoria.campos_cantidadInteres_herramienta]
        })

        return suma
      }
    }
  }

})
</script>

<style scoped>

</style>
