<template>
  <div>
    <!--    <DarkModeSwitcher />-->
    <Login>
      <template #inicio>
        Inicio de Sesión para Administradores
      </template>
      <template #form>
        <input
          @input="logging.errors = []"
          v-model="validate.email.$model"
          type="text"
          class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
          :class="{ 'border-theme-5': validate.email.$error }"
          placeholder="Correo"
        />
        <template v-if="validate.email.$error">
          <div
            v-for="(error, index) in validate.email.$errors"
            :key="index"
            class="text-theme-5 mt-2"
          >
            {{ error.$message }}
          </div>
        </template>
        <input
          @input="logging.errors = []"
          v-model="validate.password.$model"
          type="password"
          class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
          :class="{ 'border-theme-5': validate.password.$error }"
          placeholder="Contraseña"
          @keydown.enter="login"
        />
        <template v-if="validate.password.$error">
          <div
            v-for="(error, index) in validate.password.$errors"
            :key="index"
            class="text-theme-5 mt-2"
          >
            {{ error.$message }}
          </div>
        </template>
        <template v-if="logging.errors">
          <div
            v-for="(error, index) in logging.errors"
            :key="index"
            class="text-theme-5 mt-6"
          >
            {{ error }}
          </div>
        </template>
      </template>
      <template #actions>
        <button
          @click="login"
          class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
        >
          Ingresar
        </button>
      </template>
      <template #failed-notificacion>
        <div
          id="failed-notification-content"
          class="toastify-content hidden flex"
        >
          <XCircleIcon class="text-theme-5" />
          <div class="ml-4 mr-4">
            <div class="font-medium">Inicio de Sesión Incorrecto</div>
            <div class="text-gray-600 mt-1">
              Campos incompletos.
            </div>
          </div>
        </div>
      </template>
      <template #success-notificacion>
        <div
          id="success-notification-content"
          class="toastify-content hidden flex"
        >
          <CheckCircleIcon class="text-theme-9" />
          <div class="ml-4 mr-4">
            <div class="font-medium">Inicio de Sesión exitoso</div>
            <div class="text-gray-600 mt-1">
              En un momento serás redirigido.
            </div>
          </div>
        </div>
      </template>
    </Login>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, ref } from 'vue'
import { email, helpers, minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { fbAuth } from '@/plugins/firebase'
import { useStore } from 'vuex'
import Login from '@/components/form/Login.vue'
// import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'

export default defineComponent({
  components: {
    // DarkModeSwitcher
    Login
  },
  setup() {
    const store = useStore()

    onMounted(() => {
      store.dispatch('administradores/auth/handleAuthStateChange')
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })
    const formData = reactive({
      email: '',
      password: ''
    })

    const logging = ref({
      errors: []
    })

    const rules = {
      email: {
        email: helpers.withMessage('Es necesaria una dirección de correo válida', email),
        required: helpers.withMessage('El campo es obligatorio', required)
      },
      password: {
        minLength: helpers.withMessage('Tu contraseña debe ser de al menos 8 caracteres', minLength(8)),
        required: helpers.withMessage('El campo es obligatorio', required)
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))
    const login = () => {
      validate.value.$touch()

      if (validate.value.$invalid) {
        console.log(validate.value.$invalid)
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {
        signInWithEmailAndPassword(fbAuth, formData.email, formData.password)
          .then((user) => {
            Toastify({
              node: cash('#success-notification-content')
                .clone()
                .removeClass('hidden')[0],
              duration: 750,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
          })
          .catch((error) => {
            switch (error.code) {
              case 'auth/invalid-email':
                logging.value.errors.push('Email invalido')
                break
              case 'auth/user-not-found':
                logging.value.errors.push('El correo no se encuentra asociado a ninguna cuenta')
                break
              case 'auth/wrong-password':
                logging.value.errors.push('La contraseña no se encuentra asociado a ninguna cuenta')
                break
              default:
                logging.value.errors.push('El correo/contraseña no se encuentra asociado a ninguna cuenta')
                break
            }
          })
      }
    }

    return {
      logging,
      formData,
      validate,
      login
    }
  }
})
</script>

<style>
@media(max-width: 1279px) {
  .app-version {
    color: rgba(255,255,255, .5) !important;
  }
}
</style>
