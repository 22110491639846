<template>
  <div class="bg-white overflow-hidden sm:rounded-md grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-4">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
})
</script>

<style scoped>

</style>
