import auth from './auth'
import alumnos from './alumnos'
import convocatorias from './convocatorias'
import cuenta from './cuenta'
import documentos from './documentos'

export default {
  namespaced: true,
  state: {
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'alumnos.dashboard',
        title: 'Inicio'
      },
      {
        icon: 'UserIcon',
        pageName: 'alumnos.perfil',
        title: 'Perfil'
      }
    ]
  },
  modules: {
    auth,
    alumnos,
    convocatorias,
    cuenta,
    documentos
  }
}
