import { fbFirestore } from '@/plugins/firebase'
import { collection, onSnapshot, query, where, limit, getDocs } from 'firebase/firestore'

export function obtenerEdiciones({ rootState, commit }) {
  return new Promise((resolve) => {
    if (rootState.alumnos.convocatorias.ediciones.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'ediciones' })

    const q = query(collection(fbFirestore, 'ediciones'))
    onSnapshot(q, (querySnapshop) => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(() => commit('AGREGAR', { table: 'ediciones', payload: change.doc.data() }), i)
            break
          }

          case 'modified': {
            setTimeout(() => commit('EDITAR', { table: 'ediciones', payload: change.doc.data() }), i)
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'ediciones', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerConvocatorias({ rootState, commit }) {
  return new Promise((resolve) => {
    if (rootState.alumnos.convocatorias.convocatorias.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'convocatorias' })

    const q = query(collection(fbFirestore, 'convocatorias'))
    onSnapshot(q, (querySnapshop) => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(() => commit('AGREGAR', { table: 'convocatorias', payload: change.doc.data() }), i)
            break
          }

          case 'modified': {
            setTimeout(() => commit('EDITAR', { table: 'convocatorias', payload: change.doc.data() }), i)
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'convocatorias', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerConvocatoriaPorCodigo(_, codigo) {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(fbFirestore, 'convocatorias'),
      where('codigo', '==', codigo),
      limit(1)
    )

    getDocs(q)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          resolve(doc.data())
        })
      })
      .catch(reject)
  })
}

export function obtenerParticipacionPorCodigo(_, codigo) {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(fbFirestore, 'participaciones'),
      where('codigo', '==', codigo),
      limit(1)
    )

    getDocs(q)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          resolve(doc.data())
        })
      })
      .catch(reject)
  })
}

export function obtenerParticipacionesTiposPorConvocatoriaIndice({ rootState, commit }, convocatoriaIndice) {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(fbFirestore, 'participacionesTipos'),
      where('convocatorias_indice', '==', convocatoriaIndice)
    )

    getDocs(q)
      .then((querySnapshot) => {
        resolve(
          querySnapshot.docs.map(doc => doc.data())
        )
      })
      .catch(reject)
  })
}

export function obtenerAlumnoPorMatricula(_, matricula) {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(fbFirestore, 'alumnos'),
      where('matricula', '==', matricula),
      limit(1)
    )

    getDocs(q)
      .then((querySnapshot) => {
        querySnapshot.docs.forEach(doc => {
          resolve(doc.data())
        })
      })
      .catch(reject)
  })
}

export function validarMatriculaAlumno(_, matricula) {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(fbFirestore, 'alumnos'),
      where('matricula', '==', matricula),
      limit(1)
    )

    getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.docs.length === 0) {
          reject(new Error('No existe la matricula'))
        }

        querySnapshot.docs.forEach(doc => {
          const alumno = doc.data()
          resolve({
            matricula: alumno.matricula,
            nombre: alumno.nombre,
            validado: true
          })
        })
      })
      .catch(reject)
  })
}

export function obtenerParticipacionTipoPorIndice(_, participacionTipoIndice) {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(fbFirestore, 'participacionesTipos'),
      where('indice', '==', participacionTipoIndice),
      limit(1)
    )

    getDocs(q)
      .then((querySnapshot) => {
        resolve(
          querySnapshot.docs.map(doc => doc.data())
        )
      })
      .catch(reject)
  })
}

export function obtenerPatrocinadores({ rootState, commit }) {
  return new Promise((resolve) => {
    if (rootState.alumnos.convocatorias.patrocinadores.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'patrocinadores' })

    const q = query(collection(fbFirestore, 'patrocinadores'))
    onSnapshot(q, (querySnapshop) => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(() => commit('AGREGAR', { table: 'patrocinadores', payload: change.doc.data() }), i)
            break
          }

          case 'modified': {
            setTimeout(() => commit('EDITAR', { table: 'patrocinadores', payload: change.doc.data() }), i)
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'patrocinadores', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerParticipacionesPorAlumnoIndice(_, alumnoIndice) {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(fbFirestore, 'participaciones'),
      where('alumnos_indices', 'array-contains', alumnoIndice)
    )

    getDocs(q)
      .then((querySnapshot) => {
        resolve(
          querySnapshot.docs.map(doc => doc.data())
        )
      })
      .catch(reject)
  })
}

export function obtenerParticipacionesColaborandoPorAlumnoIndice(_, alumnoIndice) {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(fbFirestore, 'participaciones'),
      where('alumnos_indices', 'array-contains', alumnoIndice)
    )

    getDocs(q)
      .then((querySnapshot) => {
        resolve(
          querySnapshot.docs.map(doc => doc.data())
        )
      })
      .catch(reject)
  })
}

export function obtenerParticipacionesDatosPorParticipacionTipoIndice(_, participacionTipoIndice) {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(fbFirestore, 'participacionesDatos'),
      where('participacionesTipos_indice', '==', participacionTipoIndice)
    )

    getDocs(q)
      .then((querySnapshot) => {
        resolve(
          querySnapshot.docs.map(doc => doc.data())
        )
      })
      .catch(reject)
  })
}

export function obtenerParticipantesTiposPorParticipacionTipoIndice(_, participacionTipoIndice) {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(fbFirestore, 'participantesTipos'),
      where('participacionesTipos_indice', '==', participacionTipoIndice)
    )

    getDocs(q)
      .then((querySnapshot) => {
        resolve(
          querySnapshot.docs.map(doc => doc.data())
        )
      })
      .catch(reject)
  })
}

export function obtenerTablaParaDatosConvocatoria(_, tabla) {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(fbFirestore, tabla)
    )

    getDocs(q)
      .then((querySnapshot) => {
        resolve(
          querySnapshot.docs.map(doc => doc.data())
        )
      })
      .catch(reject)
  })
}
