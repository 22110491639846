<template>
  <div class="py-5">
    <h1 class="text-2xl mb-5 font-bold" v-if="loaded && participaciones.length">
      {{ convocatoria.nombre }}
    </h1>
    <p v-if="administrador.finalistas">Finalistas: {{ participaciones.filter(participacion => participacion.finalista).length }}</p>

    <p v-if="convocatoria.galeria">
      Ver como Galería: <input type="checkbox" :value="galeria" :checked="galeria" @click="galeria = !galeria" />
    </p>
    <div v-if="administrador.tutorial_seleccion">
      <div
      >
        <div class="flex flex-wrap flex-row items-center mt-5">
          <h1 class="text-xl font-bold">Guía para visualizar participaciones</h1>

          <div>
            <ChevronDownIcon @click="mostrarTutorial = false" v-if="mostrarTutorial" class="h-5 w-5 text-gray-400 cursor-pointer ml-5" aria-hidden="true" />
            <ChevronRightIcon @click="mostrarTutorial = true" v-else class="h-5 w-5 text-gray-400 cursor-pointer ml-5" aria-hidden="true" />
          </div>
        </div>
        <div v-if="mostrarTutorial" class="container mx-auto">
          <Video :link="administrador.tutorial_seleccion" />
        </div>
      </div>
    </div>
    <div
      v-if="participaciones.length"
    >
      <div
        v-for="participacionTipo in participacionesTiposOrdenadas"
        :key="`participacionTipo-${participacionTipo.indice}`"
      >
        <div class="flex flex-wrap flex-row items-center mt-5">
          <h1 class="text-xl font-bold">{{ participacionTipo.nombre }}
            (<span v-if="administrador.seleccionar">{{ participacionesOrdenadas(participacionTipo.indice).filter(participacion => participacion.seleccionada).length }}/</span>
            <span>{{ participacionesOrdenadas(participacionTipo.indice).length }}</span>)
          </h1>

          <div>
            <ChevronDownIcon @click="participacionTipo.mostrar = false" v-if="participacionTipo.mostrar" class="h-5 w-5 text-gray-400 cursor-pointer ml-5" aria-hidden="true" />
            <ChevronRightIcon @click="participacionTipo.mostrar = true" v-else class="h-5 w-5 text-gray-400 cursor-pointer ml-5" aria-hidden="true" />
          </div>
        </div>
        <GaleriaParticipaciones v-if="loaded && participaciones.length && participacionTipo.mostrar && galeria" class="mt-5">
          <Participacion
            finalistas
            :galeria="galeria"
            v-for="participacion in participacionesOrdenadas(participacionTipo.indice)"
            :key="`participacion-${participacion.indice}`"
            :participacion="participacion"
          />
        </GaleriaParticipaciones>
        <ListaParticipaciones v-if="loaded && participaciones.length && participacionTipo.mostrar && !galeria" class="mt-5">
          <Participacion
            finalistas
            :galeria="galeria"
            v-for="participacion in participacionesOrdenadas(participacionTipo.indice)"
            :key="`participacion-${participacion.indice}`"
            :participacion="participacion"
          />
        </ListaParticipaciones>
      </div>
    </div>
    <p v-else-if="loaded && !participaciones.length">
      No hay finalistas de {{ convocatoria.nombre }} aún.
    </p>
    <p v-else>
      Cargando...
    </p>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import ListaParticipaciones from '@/views/administradores/components/participaciones/ListaParticipaciones.vue'
import Participacion from '@/views/administradores/components/participaciones/Participacion.vue'
import { orderBy } from 'lodash'
import Video from '@/views/administradores/components/participaciones/previsualizadores/Video.vue'
import GaleriaParticipaciones from '@/views/administradores/components/participaciones/GaleriaParticipaciones.vue'

export default defineComponent({
  components: { GaleriaParticipaciones, Video, Participacion, ListaParticipaciones },
  data() {
    return {
      convocatoria: {},
      participacionesDatos: [],
      loaded: false,
      mostrarTutorial: false,
      unsuscribe: [],
      galeria: false
    }
  },
  computed: {
    ...mapState('administradores/convocatorias', ['participaciones', 'participacionesTipos', 'visualizaciones']),
    ...mapState('administradores/auth', ['administrador']),
    visualizada() {
      return (participacionIndice) => {
        return this.visualizaciones ? this.visualizaciones.participaciones_indices ? this.visualizaciones.participaciones_indices.includes(participacionIndice) : false : false
      }
    },
    participacionesTiposOrdenadas() {
      return orderBy(
        this.participacionesTipos.filter(participacionTipo => {
          return participacionTipo.convocatorias_indice === this.convocatoria.indice && this.participacionesTiposIndicesPorConvocatoria.includes(participacionTipo.indice)
        }),
        'orden'
      )
    },
    participacionesTiposIndicesPorConvocatoria() {
      return this.participaciones.map((participacion) => {
        return participacion.participacionesTipos_indice
      })
    },
    participacionesOrdenadas() {
      return (participacionTipoIndice) => {
        return orderBy(this.participaciones.filter(participacion => participacion.participacionesTipos_indice === participacionTipoIndice), 'orden', 'desc')
      }
    }
  },
  methods: {
    ...mapActions('administradores/convocatorias',
      [
        'obtenerVisualizaciones',
        'obtenerConvocatoriaPorIndice',
        'obtenerParticipacionesTipos',
        'obtenerParticipacionesFinalistasPorConvocatoriaIndice',
        'obtenerParticipacionesDatos'
      ]
    )
  },
  mounted() {
    this.obtenerConvocatoriaPorIndice(this.$route.params.convocatoria)
      .then(convocatoria => {
        this.convocatoria = convocatoria

        this.obtenerParticipacionesTipos().then(() => {
          this.obtenerParticipacionesFinalistasPorConvocatoriaIndice({
            convocatoriaIndice: this.$route.params.convocatoria
          })
            .then(unsuscribe => {
              this.unsuscribe.push(unsuscribe)
              this.obtenerParticipacionesDatos()
                .then(() => {
                  this.loaded = true
                })
            })
        })
      })
  },
  beforeRouteLeave() {
    this.unsuscribe.forEach(unsub => {
      unsub()
    })
  }
})
</script>

<style scoped>

</style>
