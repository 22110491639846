import { createStore } from 'vuex'
import administradores from './administradores'
import colaboradores from './colaboradores'
import alumnos from './alumnos'
import main from './main'

const store = createStore({
  modules: {
    administradores,
    colaboradores,
    alumnos,
    main
  }
})

export function useStore() {
  return store
}

export default store
