<template>
  <div>
    <!-- BEGIN: Hoverable Table -->
    <AlumnosRegistradosEstudios :estudios="estudios"/>
    <!-- END: Hoverable Table -->
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import Airtable from 'airtable'
import AlumnosRegistradosEstudios from '@/views/administradores/components/estadisticas/AlumnosRegistradosEstudios'
const base = new Airtable({ apiKey: 'patmSk0sdzVet2VQD.067b5939a8026476fb7e425b473c39417eecafad467e1ca526516af244112bdc' }).base('appYzmr5BAYcj9vHc')

export default defineComponent({
  components: { AlumnosRegistradosEstudios },
  data() {
    return {
      estudios: []
    }
  },
  computed: {
    ...mapState('administradores/auth', ['administrador', 'usuario'])
  },
  methods: {
    obtenerDatos() {
      this.estudios = []

      base('estudios').select().eachPage((records, fetchNextPage) => {
        const recs = []

        records.forEach(record => {
          record.fields.id = record.id
          recs.push(record.fields)
        })
        this.estudios.push(...recs)

        fetchNextPage()
      })
    }
  },
  mounted() {
    if (this.administrador.global) {
      this.obtenerDatos()
    }
  }
})
</script>

<style scoped>

</style>
