import { fbFirestore } from '@/plugins/firebase'
import { collection, onSnapshot, query } from 'firebase/firestore'

export function obtenerEdiciones({ rootState, commit }) {
  return new Promise((resolve) => {
    if (rootState.alumnos.convocatorias.ediciones.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'ediciones' })

    const q = query(collection(fbFirestore, 'ediciones'))
    onSnapshot(q, (querySnapshop) => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(() => commit('AGREGAR', { table: 'ediciones', payload: change.doc.data() }), i)
            break
          }

          case 'modified': {
            setTimeout(() => commit('EDITAR', { table: 'ediciones', payload: change.doc.data() }), i)
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'ediciones', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerConvocatorias({ rootState, commit }) {
  return new Promise((resolve) => {
    if (rootState.alumnos.convocatorias.convocatorias.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'convocatorias' })

    const q = query(collection(fbFirestore, 'convocatorias'))
    onSnapshot(q, (querySnapshop) => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(() => commit('AGREGAR', { table: 'convocatorias', payload: change.doc.data() }), i)
            break
          }

          case 'modified': {
            setTimeout(() => commit('EDITAR', { table: 'convocatorias', payload: change.doc.data() }), i)
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'convocatorias', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}
