<template>
  <img class="w-full" :src="link"  />
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Imagen',
  props: {
    link: {
      type: String,
      required: true
    }
  }
})
</script>

<style scoped>

</style>
