import { fbFirestore } from '@/plugins/firebase'
import { collection, onSnapshot, query } from 'firebase/firestore'

export function obtenerTablas({ rootState, commit }) {
  return new Promise((resolve) => {
    if (rootState.administradores.bridge.tablas.length) {
      resolve()
      return
    }
    commit('LIMPIAR_TABLAS')

    const q = query(collection(fbFirestore, 'tablas'))

    onSnapshot(q, (querySnapshot) => {
      const tablas = []

      querySnapshot.forEach(doc => {
        const tabla = doc.data()
        tabla.updating = false
        tablas.push(tabla)
      })

      commit('LIMPIAR_TABLAS')
      commit('AGREGAR_TABLAS', tablas)

      resolve()
    })
  })
}

export function actualizandoTabla({ commit }, { tabla, updating }) {
  commit('ACTUALIZANDO_TABLA', { tabla, updating })
}
