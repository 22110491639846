<template>
  <!-- BEGIN: Profile Info -->
  <div class="intro-y box px-5 pt-5 mt-5">
    <div
      class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
    >
      <div
        class="flex flex-1 px-5 items-center justify-center lg:justify-start"
      >
        <div
          class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative"
        >
          <img
            :alt="alumnoActual.nombre"
            class="rounded-full"
            :src="alumnoActual.fotografia ? alumnoActual.fotografia : `https://ui-avatars.com/api/?name=${encodeURI(`${alumnoActual.nombrePrimero}+${alumnoActual.apellidoPaterno}`)}&color=FFFFFF&background=666666`"
          />
          <div
            @click="uploadPhoto"
            class="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-theme-1 rounded-full p-2"
          >
            <CameraIcon class="w-4 h-4 text-white" />
          </div>
        </div>
        <div class="ml-5">
          <div
            class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg"
          >
           {{ alumnoActual.nombre }}
          </div>
          <div class="text-gray-600">{{ alumnoActual.apodo }}</div>
        </div>
      </div>

      <div
        class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
      >
        <div class="font-medium text-center lg:text-left lg:mt-3">
          Género
        </div>
        <div
          class="flex flex-col justify-center items-center lg:items-start mt-0"
        >
          <div class="truncate sm:whitespace-normal flex items-center">
            {{ alumnoActual.genero }}
          </div>
        </div>

        <div class="font-medium text-center lg:text-left lg:mt-3">
          Fecha de Nacimiento
        </div>
        <div
          class="flex flex-col justify-center items-center lg:items-start mt-0"
        >
          <div class="truncate sm:whitespace-normal flex items-center">
            {{ alumnoActual.nacimiento }}
          </div>
        </div>

        <div class="font-medium text-center lg:text-left lg:mt-3">
          Edad
        </div>
        <div
          class="flex flex-col justify-center items-center lg:items-start mt-0"
        >
          <div class="truncate sm:whitespace-normal flex items-center">
            {{ alumnoActual.edad }}
          </div>
        </div>
      </div>

      <div
        class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
      >
        <div class="font-medium text-center lg:text-left lg:mt-3">
          Matrícula
        </div>
        <div
          class="flex flex-col justify-center items-center lg:items-start mt-0"
        >
          <div class="truncate sm:whitespace-normal flex items-center">
            {{ alumnoActual.matricula }}
          </div>
        </div>

        <div class="font-medium text-center lg:text-left lg:mt-3">
          Programa
        </div>
        <div
          class="flex flex-col justify-center items-center lg:items-start mt-0"
        >
          <div class="truncate sm:whitespace-normal flex items-center">
            {{ alumnoActual.programas_nombre }}
          </div>
          <div class="truncate sm:whitespace-normal flex items-center">
            {{ alumnoActual.departamentos_nombre }}
          </div>
        </div>

        <div class="font-medium text-center lg:text-left lg:mt-3">
          Campus
        </div>
        <div
          class="flex flex-col justify-center items-center lg:items-start mt-0"
        >
          <div class="truncate sm:whitespace-normal flex items-center">
            {{ alumnoActual.campuses_nombre }}
          </div>
        </div>
      </div>
      <div
        class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
      >
        <div class="font-medium text-center lg:text-left lg:mt-3">
          Correos
        </div>
        <div
          class="flex flex-col justify-center items-center lg:items-start mt-0"
        >
          <div class="truncate sm:whitespace-normal flex items-center">
            {{ alumnoActual.correoPersonal }}
          </div>
          <div class="truncate sm:whitespace-normal flex items-center mt-0">
            {{ alumnoActual.correoTec }}
          </div>
        </div>

        <div class="font-medium text-center lg:text-left lg:mt-3">
          Celular
        </div>
        <div
          class="flex flex-col justify-center items-center lg:items-start mt-0"
        >
          <div class="truncate sm:whitespace-normal flex items-center">
            {{ alumnoActual.celular }}
          </div>
        </div>

        <div
          v-if="alumnoActual.comunidades_nombre"
          class="font-medium text-center lg:text-left lg:mt-3"
        >
          Comunidad
        </div>
        <div
          v-if="alumnoActual.comunidades_nombre"
          class="flex flex-col justify-center items-center lg:items-start mt-0"
        >
          <div class="truncate sm:whitespace-normal flex items-center">
            {{ alumnoActual.comunidades_nombre }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Profile Info -->
</template>

<script>
import { defineComponent } from 'vue'
import { fbStorage, fbFunctions } from '@/plugins/firebase'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import Airtable from 'airtable'
import { httpsCallable } from 'firebase/functions'
const base = new Airtable({ apiKey: 'patmSk0sdzVet2VQD.067b5939a8026476fb7e425b473c39417eecafad467e1ca526516af244112bdc' }).base('appYzmr5BAYcj9vHc')
const updateRecord = httpsCallable(fbFunctions, 'at-updateRecord')

export default defineComponent({
  props: {
    alumno: {
      type: Object,
      required: true
    }
  },
  computed: {
    alumnoActual() {
      return this.$props.alumno
    }
  },
  methods: {
    uploadPhoto() {
      const input = document.createElement('input')
      input.type = 'file'

      input.onchange = e => {
        const file = e.target.files[0]
        const photoRef = ref(fbStorage, `/alumnos/${this.alumnoActual.indice}/${file.name}`)
        uploadBytes(photoRef, file).then((response) => {
          getDownloadURL(response.ref).then((link) => {
            base('alumnos').update(this.alumnoActual.id, {
              fotografia_imagen: [
                {
                  filename: file.name,
                  url: link
                }
              ],
              fotografia_valorManual: link
            })
              .then(() => {
                updateRecord({ table: 'alumnos', id: this.alumnoActual.id })
                  .then(() => {
                    this.loading = false
                  })
              })
          })
        })
      }

      input.click()
    }
  }
})
</script>

<style scoped>

</style>
