<template>
  <TomSelect
    :multiple="multiple"
    v-model="value"
    :options="config"
    class="w-full"
    @change="$emit('update:modelValue', value)"
  >
    <option selected>{{ placeholder }}</option>
    <option
      v-for="(item, key) in items"
      :key="`item-${key}`"
      :value="item[optVal]"
    >
      {{ item[optText] }}
    </option>
    <slot></slot>
  </TomSelect>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'VibrartSelect',

  props: {
    modelValue: {
      type: [String, Number, Array, Object],
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: '-- Seleccionar --'
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    optVal: {
      type: String,
      required: false,
      default: 'id'
    },
    optText: {
      type: String,
      required: false,
      default: 'nombre'
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      value: this.$props.modelValue,
      config: {
        placeholder: 'Selecciona',
        maxOptions: 1000
      }
    }
  }
})
</script>

<style scoped>

</style>
