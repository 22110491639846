<template>
  <div class="py-4" v-if="cargado===2">
    <h1 class="text-xl" >
      Alumnos del campus {{ campus.nombre }} del nivel de estudio {{estudios.nombre}}
    </h1>

    <TablaAlumnos :alumnos="alumnos" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import TablaAlumnos from '@/components/alumnos/TablaAlumnos.vue'
import { orderBy } from 'lodash'

export default defineComponent({
  name: 'AlumnosRegistradosPorCampusEstudios',
  components: { TablaAlumnos },
  data() {
    return {
      cargado: 0,
      estudios: null,
      campus: null,
      alumnos: []
    }
  },
  computed: {
    alumnosOrdenados() {
      return orderBy(
        this.alumnos,
        'orden'
      )
    }
  },
  methods: {
    ...mapActions('administradores/convocatorias', ['obtenerCampusPorCodigo', 'obtenerAlumnosPorNivelEstudiosCampus', 'obtenerEstudiosPorCodigo']),
    buscarAlumnos() {
      if (this.cargado < 2) {
        return
      }

      this.obtenerAlumnosPorNivelEstudiosCampus({
        campusIndice: this.campus.indice,
        estudiosIndice: this.estudios.indice
      })
        .then(alumnos => {
          this.alumnos = alumnos
        })
    }
  },

  mounted() {
    this.obtenerEstudiosPorCodigo(this.$route.params.estudios)
      .then(estudios => {
        this.estudios = estudios
        this.cargado++
        this.buscarAlumnos()
      })
    this.obtenerCampusPorCodigo(this.$route.params.campus)
      .then(campus => {
        this.campus = campus
        this.cargado++
        this.buscarAlumnos()
      })
  }
})
</script>

<style scoped>

</style>
