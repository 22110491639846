import LayoutAlumnos from '@/layouts/alumnos/Main'
import Dashboard from '@/views/alumnos/dashboard/Main'
import Profile from '@/views/alumnos/profile/Main'
import Login from '@/views/alumnos/login/Main'
import Register from '@/views/alumnos/register/Main'
import CrearParticipacion from '@/views/alumnos/convocatorias/participaciones/crear/Main.vue'
import validate from './validate'
import EditarParticipacion from '@/views/alumnos/convocatorias/participaciones/editar/EditarParticipacion.vue'

const routes = [
  {
    path: '/',
    component: LayoutAlumnos,
    children: [
      /*
         * SYSTEM
         */
      {
        path: '',
        name: 'alumnos.dashboard',
        meta: { requiresAuth: true, validate },
        component: Dashboard
      },
      {
        path: 'perfil',
        name: 'alumnos.perfil',
        meta: { requiresAuth: true, validate },
        component: Profile
      },
      {
        path: 'convocatorias/:codigo/participaciones/crear',
        name: 'convocatorias.participaciones.create',
        meta: { requiresAuth: true, validate },
        component: CrearParticipacion
      },
      {
        path: 'convocatorias/:convocatoria/participaciones/:participacion/editar',
        name: 'convocatorias.participaciones.edit',
        meta: { requiresAuth: true, validate },
        component: EditarParticipacion
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: { requiresAuth: false, validate },
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    meta: { requiresAuth: false, validate },
    component: Register
  }
]

export default routes
