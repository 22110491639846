<template>
  <a v-if="documento.liga && documento.liga !== ''"
    :href="documento.liga"
    class="btn my-4 w-full"
    target="_blank"
  >
    {{ documento.documentosTipos_nombre }}
  </a>
  <button
    v-else
    class="btn my-4 w-full"
    @click="$emit('mostrar', documento)"
  >
    {{ documento.documentosTipos_nombre }}
  </button>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BotonDocumento',

  props: {
    documento: {
      type: Object,
      required: true
    }
  }
})
</script>

<style scoped>

</style>
