<template>
  <div class="embed-responsive aspect-ratio-16/9">
    <iframe class="embed-responsive-item" :src="link" frameborder="0" allowfullscreen>
    </iframe>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Video',
  props: {
    link: {
      type: String,
      required: true
    }
  }
})
</script>

<style scoped>
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}
.aspect-ratio-16\/9 {
  padding-bottom: 56.25%;
}
</style>
