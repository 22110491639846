export default function () {
  return {
    campuses: [],
    comunidades: [],
    departamentos: [],
    estudios: [],
    generos: [],
    generosTipos: [],
    nacimientosAnios: [],
    nacimientosDias: [],
    nacimientosMeses: [],
    programas: []
  }
}
