<template>
  <div class="mt-8">
    <h3 class="text-gray-600 text-lg mb-2">Selecciona las Convocatorias que te interesa seguir</h3>

    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 lg:col-span-10">
        <TomSelect
          multiple
          v-model="convocatorias_indices"
        >
          <option
            v-for="convocatoria in convocatoriasActivasOrdenadas"
            :key="`convocatoriaInteres-${convocatoria.indice}`"
            :value="convocatoria.indice"
            :selected="convocatorias_indices ?? convocatorias_indices.includes(convocatoria.indice)"
          >
            {{ convocatoria.nombre }}
          </option>
        </TomSelect>
      </div>
      <div class="col-span-12 lg:col-span-2">
        <button
          @click="guardarIntereses"
          class="btn btn-dark w-full"
          :disabled="guardando"
        >
          {{ guardando ? 'Guardando...' : 'Seguir' }}
        </button>
      </div>
    </div>

    <div class="grid grid-flow-row-dense sm:grid-cols-1 md:grid-cols:1 lg:grid-cols-1 xl:grid-cols-1">
      <Convocatoria
        v-for="convocatoria in convocatoriasDeInteres"
        :key="`convocatoria-${convocatoria.indice}`"
        :convocatoria="convocatoria"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { fbFirestore } from '@/plugins/firebase'
import { doc, updateDoc } from 'firebase/firestore'
import { mapActions, mapGetters } from 'vuex'
import Convocatoria from '@/views/alumnos/components/convocatorias/Convocatoria'
import Airtable from 'airtable'

export default defineComponent({
  components: { Convocatoria },
  props: {
    alumno: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      guardando: false,
      convocatorias_indices: [],
      alumnoProceso: {
        convocatorias_indices: []
      }
    }
  },
  computed: {
    ...mapGetters('alumnos/convocatorias', ['convocatoriasActivasOrdenadas']),
    convocatoriasDeInteres() {
      return this.convocatoriasActivasOrdenadas.filter((convocatoria) => {
        return this.alumnoProceso.convocatorias_indices.includes(convocatoria.indice)
      })
    }
  },
  methods: {
    ...mapActions('alumnos/convocatorias', ['obtenerConvocatorias']),
    guardarIntereses() {
      this.guardando = true

      const base = new Airtable({ apiKey: 'patmSk0sdzVet2VQD.067b5939a8026476fb7e425b473c39417eecafad467e1ca526516af244112bdc' }).base('appYzmr5BAYcj9vHc')
      base('alumnos').update(this.alumnoProceso.id, {
        convocatorias_relacionesManuales: this.convocatorias_indices
      }).then(() => {
        const alumnoRef = doc(fbFirestore, 'alumnos', this.alumnoProceso.id)

        updateDoc(alumnoRef, {
          convocatorias_indices: this.convocatorias_indices
        }).then(() => {
          this.guardando = false
          this.alumnoProceso = this.$props.alumno
        }).catch(() => {
          this.guardando = false
        })
      }).catch((error) => {
        console.error('Error?', error)
        this.guardando = false
      })
    }
  },
  mounted() {
    this.obtenerConvocatorias()
    this.alumnoProceso = this.$props.alumno
    this.convocatorias_indices = this.$props.alumno.convocatorias_indices
  }
})
</script>

<style scoped>

</style>
