<template>
  <div v-if="cargado === 2" class="py-4">
    <h1 class="text-xl">
      Alumnos del campus {{ campus.nombre }} interesados en la convocatoria {{ convocatoria.nombre }}
    </h1>

    <TablaAlumnos :alumnos="alumnos" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { orderBy } from 'lodash'
import TablaAlumnos from '@/components/alumnos/TablaAlumnos.vue'

export default defineComponent({
  name: 'InteresesCampus',
  components: { TablaAlumnos },
  data() {
    return {
      cargado: 0,
      convocatoria: null,
      campus: null,
      alumnos: []
    }
  },

  computed: {
    alumnosOrdenados() {
      return orderBy(
        this.alumnos,
        'orden'
      )
    }
  },
  methods: {
    ...mapActions('administradores/convocatorias', ['obtenerConvocatoriaPorCodigo', 'obtenerCampusPorCodigo', 'obtenerAlumnosInteresadosPorCampusYConvocatoria']),
    buscarAlumnos() {
      if (this.cargado < 2) {
        return
      }

      this.obtenerAlumnosInteresadosPorCampusYConvocatoria({
        campusIndice: this.campus.indice,
        convocatoriaIndice: this.convocatoria.indice
      })
        .then(alumnos => {
          this.alumnos = alumnos
        })
    }
  },

  mounted() {
    this.obtenerConvocatoriaPorCodigo(this.$route.params.convocatoria)
      .then(convocatoria => {
        this.convocatoria = convocatoria
        this.cargado++
        this.buscarAlumnos()
      })

    this.obtenerCampusPorCodigo(this.$route.params.campus)
      .then(campus => {
        this.campus = campus
        this.cargado++
        this.buscarAlumnos()
      })
  }
})
</script>

<style scoped>

</style>
