<template>
  <div
    class="flex flex-col sm:flex-row items-center p-5 border-gray-200"
  >
    <h2 class="font-medium text-base mx-auto">Estadísticas de {{ campus.nombre_herramienta }}</h2>
  </div>
  <div id="hoverable-table" class="p-5 pt-0">
    <div class="preview">
      <div class="overflow-x-auto">
        <table class="table">
          <thead>
          <tr>
            <th
              class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
            >
              Estudios
            </th>
            <th
              class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
            >
              Cantidad de Alumnos
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="estudios in estudiosOrdenados"
            :key="`estudios-${estudios.indice_herramienta}`"
            class="hover:bg-gray-200"
          >
            <td class="border">
              <router-link v-if="campus[estudios.campoCantidadRegistrados_herramienta]!==0" :to="{name: 'admin.stats.estudios.intereses', params:{estudios:estudios.codigo_herramienta, campus: campus.codigo_herramienta}}">
              {{ estudios.nombre_herramienta }}
              </router-link>
              <p v-else>{{ estudios.nombre_herramienta }}</p>
            </td>
            <td class="border">
              <router-link v-if="campus[estudios.campoCantidadRegistrados_herramienta]!==0" :to="{name: 'admin.stats.estudios.intereses', params:{estudios:estudios.codigo_herramienta, campus: campus.codigo_herramienta}}">
                {{ campus[estudios.campoCantidadRegistrados_herramienta] }}
              </router-link>
              <p v-else>{{ campus[estudios.campoCantidadRegistrados_herramienta] }}</p>
            </td>
          </tr>
          <tr>
            <th class="border">Total</th>
            <th class="border">{{ sumaCampus(campus) }}</th>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { orderBy } from 'lodash'

export default defineComponent({
  name: 'AlumnosNivelEstudios',

  props: {
    campus: {
      type: Object,
      required: true
    },
    estudios: {
      type: Array,
      required: true
    }
  },

  computed: {
    sumaCampus() {
      return (campus) => {
        let suma = 0
        this.$props.estudios.forEach(conv => {
          suma += campus[conv.campoCantidadRegistrados_herramienta] ?? 0
        })

        return suma
      }
    },
    estudiosOrdenados() {
      return orderBy(
        this.$props.estudios,
        'orden_herramienta'
      )
    }
  }
})
</script>

<style scoped>

</style>
