<template>
  <div class="col-span-12 lg:col-span-4 flex flex-row flex-wrap items-center">
    <div class="flex-shrink-0 w-full mb-2 lg:pr-10">
      <div class="flex flex-col gap-2" v-if="$slots.datoPersonalizado">
        <b class="lg:hidden"><slot name="nombre"></slot></b>
        <p class="text-gray-600 pr-2 lg:hidden"><slot name="descripcion"></slot></p>
        <slot name="datoPersonalizado"></slot>
      </div>
      <b v-if="!$slots.datoPersonalizado">
        <slot name="nombre"></slot>
      </b>
      <p class="text-gray-600 pr-2" v-if="!$slots.datoPersonalizado">
        <slot name="descripcion"></slot>
      </p>
    </div>
  </div>
  <div class="col-span-12 lg:col-span-8 flex flex-row flex-wrap items-center align-middle">
    <slot name="dato"></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Dato'
})
</script>

<style scoped>

</style>
