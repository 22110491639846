export function ASIGNAR_USUARIO(state, payload) {
  state.usuario = payload
}

export function ASIGNAR_ADMINISTRADOR(state, payload) {
  state.administrador = payload
}

export function ASIGNAR_GLOBAL(state, payload) {
  state.global = payload
}

export function CAMBIAR_ACCESO(state, payload) {
  state.loggedIn = payload
}

export function MENU_ASIGNADO(state, payload) {
  this.state.administradores.menuAsignado = payload
}

export function ACTUALIZACION_OTORGADA(state, payload) {
  this.state.administradores.actualizacionOtorgada = payload
}

export function AGREGAR_ITEM_MENU(_, item) {
  this.state.administradores.menu.push(item)
}

export function AGREGAR_MENU(state, administrador) {
  if (!this.state.administradores.menuAsignado) {
    const subMenuEstadisticas = {
      icon: 'HomeStats',
      title: 'Estadisticas',
      subMenu: []
    }

    const subMenuSeleccion = {
      icon: 'HomeStats',
      title: 'Selección',
      subMenu: []
    }

    const subMenuCalificacion = {
      icon: 'HomeStats',
      title: 'Calificación',
      subMenu: []
    }

    const menuCampuses = {
      title: 'Campus',
      subMenu: [
        {
          pageName: 'admin.stats.campus.interesesConvocatoria',
          title: 'Intereses por Convocatoria'
        },
        {
          pageName: 'admin.stats.campus.alumnosPorEstudios',
          title: 'Alumnos por Nivel de Estudios'
        }
      ]
    }

    const menuConvocatorias = {
      title: 'Convocatoria',
      subMenu: [
        {
          pageName: 'admin.stats.convocatoria.interesesCampus',
          title: 'Intereses por Campus'
        }
      ]
    }

    const menuGlobal = {
      title: 'Global',
      subMenu: [
        {
          pageName: 'admin.stats.global.alumnosRegistradosCampus',
          title: 'Alumnos por Campus'
        },
        {
          pageName: 'admin.stats.global.alumnosRegistradosCampusEstudios',
          title: 'Alumnos por Campus y Nivel de Estudio'
        },
        {
          pageName: 'admin.stats.global.alumnosRegistradosComunidad',
          title: 'Alumnos por Comunidad'
        },
        {
          pageName: 'admin.stats.global.alumnosRegistradosConvocatoria',
          title: 'Alumnos por Convocatoria'
        },
        {
          pageName: 'admin.stats.global.alumnosRegistradosEstudios',
          title: 'Alumnos por Nivel de Estudios'
        },
        {
          pageName: 'admin.stats.global.alumnosInteresadosCampusConvocatoria',
          title: 'Interesados por Campus y Convocatoria'
        }
      ]
    }

    if (administrador.global) {
      subMenuEstadisticas.subMenu.push(menuCampuses)
      subMenuEstadisticas.subMenu.push(menuConvocatorias)
      subMenuEstadisticas.subMenu.push(menuGlobal)
    } else if (administrador.campuses_indice) {
      subMenuEstadisticas.subMenu.push(menuCampuses)
    } else if (administrador.convocatorias_indice) {
      subMenuEstadisticas.subMenu.push(menuConvocatorias)
    }

    this.state.administradores.menuAsignado = true
    if (subMenuEstadisticas.subMenu.length) {
      this.state.administradores.menu.push(subMenuEstadisticas)
    }
    if (subMenuSeleccion.subMenu.length) {
      this.state.administradores.menu.push(subMenuSeleccion)
    }
    if (subMenuCalificacion.subMenu.length) {
      this.state.administradores.menu.push(subMenuCalificacion)
    }
  }

  if (
    !this.state.administradores.actualizacionOtorgada &&
    administrador.actualizacion
  ) {
    this.state.administradores.menu.push({
      icon: 'HomeDatabase',
      pageName: 'admin.bridge',
      title: 'Bridge'
    })
    this.state.administradores.actualizacionOtorgada = true
  }
}
