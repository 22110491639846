<template>
  <div class="embed-responsive aspect-ratio-16/9">
    <audio controls :link="link">
      <source :src="link" type="audio/mpeg">
      Tu explorador no es compatible.
    </audio>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Audio',
  props: {
    link: {
      type: String,
      required: true
    }
  }
})
</script>
