<template>
  <li v-if="!galeria">
    <div class="block hover:bg-gray-50">
      <div class="flex items-center px-4 py-4 sm:px-6">
        <div class="min-w-0 flex-1 flex items-center">
          <div class="min-w-0 flex px-4 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <div class="flex items-center">
                <p class="text-sm font-medium text-indigo-600 truncate" :class="participacion.seleccionada ? 'mt-1' : ''">{{ participacion.nombre }}</p>
                <StarIcon class="w-6 h-6 ml-2 text-theme-12" v-if="!finalistas && administrador.finalistas && participacion.finalista" />
                <StarIcon class="w-6 h-6 ml-2 text-theme-7" v-if="!finalistas && administrador.seleccionar && participacion.seleccionada" />
                <EyeIcon class="w-6 h-6 ml-2 text-theme-7" v-if="!finalistas && visualizada(participacion.indice)" />
              </div>
              <p class="mt-2 flex items-center text-sm text-gray-500">
                {{ participacion.descripcion }}
              </p>
            </div>
          </div>
        </div>
        <div>
          <ChevronDownIcon @click="mostrarDetalle = !mostrarDetalle" v-if="mostrarDetalle" class="h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" />
          <ChevronRightIcon @click="mostrarDetalle = !mostrarDetalle" v-else class="h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" />
        </div>
      </div>
    </div>

    <DetalleParticipacion :finalistas="finalistas" :participacion="participacion" v-if="mostrarDetalle" />
  </li>

  <div class="border border-gray-400 rounded self-start" v-else>
    <div v-if="imagen" class="h-[400px] rounded-tl rounded-tr" :style="`background-image: url('${imagen}');`" style="background-size: cover; background-position: center;">

    </div>

    <div class="flex items-center my-4 pl-4">
      <p class="text-sm font-medium text-indigo-600 truncate" :class="participacion.seleccionada ? 'mt-1' : ''">{{ participacion.nombre }}</p>
      <StarIcon class="w-6 h-6 ml-2 text-theme-12" v-if="!finalistas && administrador.finalistas && participacion.finalista" />
      <StarIcon class="w-6 h-6 ml-2 text-theme-7" v-if="!finalistas && administrador.seleccionar && participacion.seleccionada" />
      <EyeIcon class="w-6 h-6 ml-2 text-theme-7" v-if="!finalistas && visualizada(participacion.indice)" />

      <ChevronDownIcon @click="mostrarDetalle = !mostrarDetalle" v-if="mostrarDetalle" class="h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" />
      <ChevronRightIcon @click="mostrarDetalle = !mostrarDetalle" v-else class="h-5 w-5 text-gray-400 cursor-pointer" aria-hidden="true" />
    </div>

    <DetalleParticipacion :finalistas="finalistas" :participacion="participacion" :galeria="galeria" v-if="mostrarDetalle" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import DetalleParticipacion from '@/views/administradores/components/participaciones/DetalleParticipacion.vue'

export default defineComponent({
  components: { DetalleParticipacion },
  props: {
    finalistas: {
      type: Boolean,
      default: false
    },
    participacion: {
      required: true,
      type: Object
    },
    galeria: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mostrarDetalle: false
    }
  },
  computed: {
    ...mapState('administradores/convocatorias', ['visualizaciones']),
    ...mapState('administradores/auth', ['administrador']),
    imagen() {
      return this.participacion[this.participacion.galeria] ? this.participacion[this.participacion.galeria] : false
    },
    visualizada() {
      return (participacionIndice) => {
        return this.visualizaciones && this.visualizaciones.participaciones_indices && Array.isArray(this.visualizaciones.participaciones_indices) ? this.visualizaciones.participaciones_indices.includes(participacionIndice) : false
      }
    }
  }
})
</script>

<style scoped>

</style>
