<template>
  <div v-if="procesando" class="col-span-12 pt-10">
    <div class="w-20 h-20 mx-auto">
      <LoadingIcon icon="puff" />
    </div>
    <h1 class="text-2xl mb-20 mt-10 text-center">
      {{ status }}
    </h1>
  </div>
  <div v-if="error.mostrar">
    <div class="w-20 h-20 mx-auto">
      <XCircleIcon class="w-20 h-20 text-theme-5" />
    </div>
    <h1 class="text-2xl mb-20 mt-10 text-center">
      {{ error.mensaje }}
    </h1>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { fbStorage, fbFunctions } from '@/plugins/firebase.js'
import { getDownloadURL, uploadBytes, ref as storageRef } from 'firebase/storage'
import Airtable from 'airtable'
import { httpsCallable } from 'firebase/functions'

const airtable = new Airtable({ apiKey: 'patmSk0sdzVet2VQD.067b5939a8026476fb7e425b473c39417eecafad467e1ca526516af244112bdc' })
const base = airtable.base('appYzmr5BAYcj9vHc')
const updateRecord = httpsCallable(fbFunctions, 'at-updateRecord')

export default defineComponent({
  name: 'ProcesarParticipacion',

  emits: ['procesado'],

  props: {
    convocatoria: {
      type: Object,
      required: true
    },
    participacion: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      participacionAirtable: {},
      participacionesDatos: [],
      nuevaParticipacion: {},
      procesando: true,
      error: {
        mostrar: false,
        mensaje: ''
      },
      idsParticipantes: [],
      status: 'Validando Participación...'
    }
  },

  methods: {
    ...mapActions('alumnos/convocatorias', ['obtenerParticipacionesDatosPorParticipacionTipoIndice', 'obtenerAlumnoPorMatricula']),
    validarParticipacion() {
      // Datos del Administrador
      this.nuevaParticipacion.alumnos_relacionManual = [this.$props.participacion.administrador.administrador_indice]
      this.nuevaParticipacion.administradorPrimerNombre_valorManual = this.$props.participacion.administrador.administradorPrimerNombre_valorManual
      this.nuevaParticipacion.administradorSegundoNombre_valorManual = this.$props.participacion.administrador.administradorSegundoNombre_valorManual
      this.nuevaParticipacion.administradorApellidoPaterno_valorManual = this.$props.participacion.administrador.administradorApellidoPaterno_valorManual
      this.nuevaParticipacion.administradorApellidoMaterno_valorManual = this.$props.participacion.administrador.administradorApellidoMaterno_valorManual
      this.nuevaParticipacion.administradorMatricula_valorManual = this.$props.participacion.administrador.administradorMatricula_valorManual
      this.nuevaParticipacion.administradorCelular_valorManual = this.$props.participacion.administrador.administradorCelular_valorManual
      this.nuevaParticipacion.administradorCorreoPersonal_valorManual = this.$props.participacion.administrador.administradorCorreoPersonal_valorManual
      this.nuevaParticipacion.campuses_relacionManual = this.$props.participacion.administrador.campuses_relacionManual

      // Datos de la Participacion
      this.nuevaParticipacion.participacionesTipos_relacionManual = [this.$props.participacion.participacionTipo_indice]

      // Revisando datos
      this.status = 'Revisando Datos de la Participación...'
      this.participacionesDatos.forEach(participacionDato => {
        if (this.$props.participacion.datos[participacionDato.indice].tipo !== 'Archivo') {
          if (this.$props.participacion.datos[participacionDato.indice].relacionar) {
            this.nuevaParticipacion[participacionDato.llenado] = [this.$props.participacion.datos[participacionDato.indice].valor]
          } else {
            this.nuevaParticipacion[participacionDato.llenado] = this.$props.participacion.datos[participacionDato.indice].valor
          }
          // Subir el archivo
          // this.status = `Subiendo el archivo ${this.$props.participacion.datos[participacionDato.indice].valor.name}`
        } else {
          // this.nuevaParticipacion[participacionDato.clave] = this.$props.participacion.datos[participacionDato.indice]
        }
        // this.nuevaParticipacion[participacionDato.clave] = this.$props.participacion.datos[participacionDato.indice]
      })

      return true
    },
    crearParticipacionBase() {
      return new Promise((resolve, reject) => {
        base('participaciones').create(this.nuevaParticipacion)
          .then(registro => {
            resolve(registro)
          })
          .catch(reject)
      })
    },
    async agregarParticipantes() {
      this.status = 'Agregando Participantes'

      for (let i = 0; i < this.$props.participacion.participantes.length; i++) {
        const participante = this.$props.participacion.participantes[i]
        const alumno = await this.obtenerAlumnoPorMatricula(participante.matricula)
        const record = await base('participantes').create({
          participantesTipos_relacionManual: [participante.participantesTipos_indice],
          participaciones_relacionManual: [this.participacionAirtable.id],
          alumnos_relacionManual: [alumno.indice],
          credito_valorManual: participante.participanteTipo.nombre === participante.credito ? null : participante.credito
        })

        this.idsParticipantes.push(record.id)
      }
    },
    async subirArchivos() {
      this.status = 'Subiendo Archivos'
      const fields = {
      }
      const partAt = this.participacionAirtable
      const propsDatos = this.$props.participacion.datos

      for (let i = 0; i < this.participacionesDatos.length; i++) {
        const partDato = this.participacionesDatos[i]

        if (propsDatos[partDato.indice].tipo === 'Archivo' && this.$props.participacion.datos[partDato.indice].valor) {
          const extension = this.$props.participacion.datos[partDato.indice].valor.name.split('.').pop()

          this.status = `Subiendo el archivo ${propsDatos[partDato.indice].valor.name}`
          const nombreArchivo = `${partAt.fields.codigo_herramienta}_${partDato.clave}.${extension}`
          const docRef = storageRef(fbStorage, `/participaciones/${this.convocatoria.codigo}/${nombreArchivo}`)
          await uploadBytes(docRef, propsDatos[partDato.indice].valor)
          const link = await getDownloadURL(docRef)
          if (!partDato.noSubirAirtable) {
            fields[partDato.llenado] = [
              {
                url: link,
                filename: nombreArchivo
              }
            ]
          }
          fields[`${partDato.clave}Firebase_valorManual`] = link
        }
      }
      await base('participaciones').update(partAt.id, fields)

      return true
    },
    mostrarError(texto) {
      this.procesando = false
      this.error.mensaje = texto
      this.error.mostrar = true
    }
  },

  async mounted() {
    this.participacionesDatos = await this.obtenerParticipacionesDatosPorParticipacionTipoIndice(this.$props.participacion.participacionTipo_indice)
    if (!this.validarParticipacion()) {
      this.mostrarError('No se pudieron validar los datos de tu participación.')
    }

    this.participacionAirtable = await this.crearParticipacionBase()
    await this.agregarParticipantes()
    await this.subirArchivos()
    this.status = 'Terminando de registar la participación'
    await updateRecord({ table: 'participaciones', id: this.participacionAirtable.id })

    for (let i = 0; i < this.idsParticipantes.length; i++) {
      await updateRecord({ table: 'participantes', id: this.idsParticipantes[i] })
    }

    this.$emit('procesado')
  }
})
</script>

<style scoped>

</style>
