<template>
  <div>
    <vibrart-select
      v-model="participacionTipoIndice"
      :items="participacionesTiposOrdenadas"
      @change="actualizarTipo"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import VibrartSelect from '@/components/vibrat-select/vibrart-select.vue'
import { orderBy } from 'lodash'

export default defineComponent({
  name: 'SeleccionarTipo',

  props: {
    modelValue: {
      type: String,
      required: true
    },
    convocatoria: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  components: { VibrartSelect },

  data() {
    return {
      participacionesTipos: [],
      participacionTipoIndice: this.$props.modelValue
    }
  },

  computed: {
    participacionesTiposOrdenadas() {
      return orderBy(
        this.participacionesTipos,
        'orden'
      )
    },
    participacionTipoPorIndice() {
      return this.participacionesTipos.find(participacionTipo => {
        return participacionTipo.indice === this.participacionTipoIndice
      })
    }
  },

  methods: {
    ...mapActions('alumnos/convocatorias', ['obtenerParticipacionesTiposPorConvocatoriaIndice']),
    actualizarTipo() {
      this.$emit('update:modelValue', this.participacionTipoIndice)
      this.$emit('update:participacionTipo', this.participacionTipoPorIndice)
    }
  },

  mounted() {
    this.obtenerParticipacionesTiposPorConvocatoriaIndice(this.$props.convocatoria.indice)
      .then(participacionesTipos => {
        this.participacionesTipos = participacionesTipos
      })
  }
})
</script>

<style scoped>

</style>
