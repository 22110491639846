<template>
  <div class="lg:flex bg-slate-100 rounded-xl p-8 md:p-0 dark:bg-slate-800 w-full border-t border-b rounded-none border-gray-400" :class="convocatoria.activo ? '' : 'opacity-50'">
    <div class="w-full xl:w-1/4 flex mx-auto block">
      <img class="w-full my-auto" :src="convocatoria.logotipo" :alt="convocatoria.nombre" />
    </div>
    <div class="w-full xl:w-2/4 mx-auto pt-6 md:p-8 text-center md:text-left block">
      <div class="flex items-center h-full flex-col lg:flex-row">
        <p class="text-sky-500 dark:text-sky-400 lg:px-12">
          {{ convocatoria.descripcion }}
        </p>
      </div>
    </div>
    <div class="w-full xl:w-1/4 mx-auto pt-6 md:p-8 text-center md:text-left block">
      <div class="flex items-center justify-center h-full">
        <div>
          <BotonDocumento
            v-for="documento in documentos"
            :key="documento"
            @mostrar="toggleMostrarDocumento"
            :documento="documento" />
        </div>
        <!--        <button
                  class="btn w-full"
                  v-for="documento in documentos" :key="documento" @click="documento.mostrar = !documento.mostrar">
                  {{ documento.documentosTipos_nombre }}
                </button>-->
      </div>
    </div>
  </div>

  <Documento v-show="documento.mostrar" :documento-codigo="documento.codigo" v-for="documento in documentos" :key="documento" class="mr-2" />
</template>
<slot></slot>
<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import Documento from '@/components/convocatorias/Documento.vue'
import BotonDocumento from '@/views/alumnos/components/convocatorias/BotonDocumento.vue'

export default defineComponent({
  components: { BotonDocumento, Documento },
  props: {
    convocatoria: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      documentos: []
    }
  },
  methods: {
    ...mapActions('alumnos/documentos', ['obtenerDocumentosPorConvocatoriaIndice']),
    toggleMostrarDocumento(documento) {
      documento.mostrar = !documento.mostrar
    }
  },
  mounted() {
    this.obtenerDocumentosPorConvocatoriaIndice(this.$props.convocatoria.indice)
      .then((documentos) => {
        this.documentos = documentos
      })
  }
})
</script>

<style scoped>

</style>
