<template>
  <div class="grid grid-flow-row-dense sm:grid-cols-1 md:grid-cols:1 lg:grid-cols-1 xl:grid-cols-1">
    <Convocatoria
      v-for="convocatoria in convocatoriasActivasOrdenadas"
      :key="`convocatoria-${convocatoria.indice}`"
      :convocatoria="convocatoria"
    />

    <Patrocinadores />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Convocatoria from '@/components/convocatorias/Convocatoria.vue'
import Patrocinadores from '@/components/patrocinadores/Patrocinadores'

export default defineComponent({
  components: { Patrocinadores, Convocatoria },
  computed: {
    ...mapGetters('administradores/convocatorias', ['convocatoriasActivasOrdenadas'])
  },
  methods: {
    ...mapActions('administradores/convocatorias', ['obtenerConvocatorias'])
  },
  mounted() {
    this.obtenerConvocatorias()
  }
})
</script>

<style scoped>

</style>
