import validate from './validate'

// Layout
import LayoutAdministradores from '@/layouts/administradores/Main'

// Dashboard
import Dashboard from '@/views/administradores/dashboard/Main'

// Documentos
import Documentos from '@/views/administradores/documentos/Main'
import Documento from '@/views/administradores/documentos/Show'

// Bridge
import Bridge from '@/views/bridge/Main'

// Auth
import Register from '@/views/administradores/register/Main'
import Login from '@/views/administradores/login/Main'

// Estadisticas Campus
import CampusInteresesConvocatoria from '@/views/administradores/estadisticas/campus/InteresesConvocatoria'
import CampusAlumnosEstudios from '@/views/administradores/estadisticas/campus/AlumnosNivelEstudios'
// Estadisticas Convocatoria
import ConvocatoriaInteresesCampus from '@/views/administradores/estadisticas/convocatoria/InteresesCampus'
// Estadisticas Globales
import GlobalAlumnosRegistradosCampus from '@/views/administradores/estadisticas/global/AlumnosRegistradosCampus'
import GlobalAlumnosRegistradosCampusEstudios from '@/views/administradores/estadisticas/global/AlumnosRegistradosCampusEstudios'
import GlobalAlumnosRegistradosComunidad from '@/views/administradores/estadisticas/global/AlumnosRegistradosComunidad'
import GlobalAlumnosRegistradosConvocatoria from '@/views/administradores/estadisticas/global/AlumnosRegistradosConvocatoria'
import GlobalAlumnosRegistradosEstudios from '@/views/administradores/estadisticas/global/AlumnosRegistradosEstudios'
import GlobalAlumnosInteresadosCampusConvocatoria from '@/views/administradores/estadisticas/global/AlumnosInteresadosCampusConvocatoria'
// Seleccion
import Seleccion from '@/views/administradores/seleccion/Main.vue'
import Finalistas from '@/views/administradores/finalistas/Main.vue'
// Calificacion
import Calificacion from '@/views/administradores/calificacion/Main.vue'

// Alumnos
import AlumnosInteresesConvocatoria from '@/views/administradores/estadisticas/alumnos/AlumnosInteresesConvocatoria.vue'
import AlumnosNivelEstudios from '@/views/administradores/estadisticas/alumnos/AlumnosNivelEstudios'
import InteresesCampus from '@/views/administradores/estadisticas/alumnos/InteresesCampus'
import AlumnosRegistradosCampusEstudios from '@/views/administradores/estadisticas/alumnos/AlumnosRegistradosPorCampusEstudios'
import AlumnosRegistradosComunidad from '@/views/administradores/estadisticas/alumnos/AlumnosRegistradosComunidad'
import AlumnosConvocatoria from '@/views/administradores/estadisticas/alumnos/AlumnosConvocatoria'
import AlumnosRegistradosEstudios from '@/views/administradores/estadisticas/alumnos/AlumnosRegistradosEstudios'
import AlumnosRegistradosPorCampus from '@/views/administradores/estadisticas/alumnos/AlumnosRegistradosPorCampus'

// Acciones
import AccionesRecordatoriosConvocatorias from '@/views/administradores/acciones/recordatorios/Main'
import AccionesRecordatoriosAlumnos from '@/views/administradores/acciones/recordatorios/Show'

const routes = [
  // Layout
  {
    path: '/admin',
    component: LayoutAdministradores,
    children: [
      // Dashboard
      {
        path: '',
        name: 'admin.dashboard',
        meta: { requiresAuth: true, validate },
        component: Dashboard
      },
      // Bridge
      {
        path: 'bridge',
        name: 'admin.bridge',
        meta: { requiresAuth: true, validate },
        component: Bridge
      },
      // Documentos
      {
        path: 'documentos',
        name: 'admin.documentos.index',
        meta: { requiresAuth: true, validate },
        component: Documentos
      },
      {
        path: 'documentos/:documentoCodigo',
        name: 'admin.documentos.show',
        meta: { requiresAuth: true, validate },
        component: Documento
      },
      // Seleccion
      {
        path: 'seleccion/:convocatoria',
        name: 'admin.seleccion.show',
        meta: { requiresAuth: true, validate },
        component: Seleccion
      },
      // Finalistas
      {
        path: 'finalistas/:convocatoria',
        name: 'admin.finalistas.show',
        meta: { requiresAuth: true, validate },
        component: Finalistas
      },
      // Calificación
      {
        path: 'calificacion/:convocatoria',
        name: 'admin.calificacion.show',
        meta: { requiresAuth: true, validate },
        component: Calificacion
      },
      // Estadisticas Campus
      {
        path: 'estadisticas/campus/convocatoria/intereses',
        name: 'admin.stats.campus.interesesConvocatoria',
        meta: { requiresAuth: true, validate },
        component: CampusInteresesConvocatoria
      },
      {
        path: 'estadisticas/campus/alumnos/estudios',
        name: 'admin.stats.campus.alumnosPorEstudios',
        meta: { requiresAuth: true, validate },
        component: CampusAlumnosEstudios
      },
      // Estadisticas Convocatoria
      {
        path: 'estadisticas/convocatoria/campus/intereses',
        name: 'admin.stats.convocatoria.interesesCampus',
        meta: { requiresAuth: true, validate },
        component: ConvocatoriaInteresesCampus
      },
      // Estadisticas Globales
      {
        path: 'estadisticas/global/alumnos/registros/campus',
        name: 'admin.stats.global.alumnosRegistradosCampus',
        meta: { requiresAuth: true, validate },
        component: GlobalAlumnosRegistradosCampus
      },
      {
        path: 'estadisticas/global/alumnos/registros/campus/estudios',
        name: 'admin.stats.global.alumnosRegistradosCampusEstudios',
        meta: { requiresAuth: true, validate },
        component: GlobalAlumnosRegistradosCampusEstudios
      },
      {
        path: 'estadisticas/global/alumnos/registros/comunidad',
        name: 'admin.stats.global.alumnosRegistradosComunidad',
        meta: { requiresAuth: true, validate },
        component: GlobalAlumnosRegistradosComunidad
      },
      {
        path: 'estadisticas/global/alumnos/registros/convocatoria',
        name: 'admin.stats.global.alumnosRegistradosConvocatoria',
        meta: { requiresAuth: true, validate },
        component: GlobalAlumnosRegistradosConvocatoria
      },
      {
        path: 'estadisticas/global/alumnos/registros/estudios',
        name: 'admin.stats.global.alumnosRegistradosEstudios',
        meta: { requiresAuth: true, validate },
        component: GlobalAlumnosRegistradosEstudios
      },
      {
        path: 'estadisticas/global/alumnos/interesados/campus/convocatoria',
        name: 'admin.stats.global.alumnosInteresadosCampusConvocatoria',
        meta: { requiresAuth: true, validate },
        component: GlobalAlumnosInteresadosCampusConvocatoria
      },
      // Alumnos
      {
        path: 'convocatorias/:convocatoria/campuses/:campus',
        name: 'admin.stats.alumnos.intereses',
        meta: { requiresAuth: true, validate },
        component: AlumnosInteresesConvocatoria
      },
      {
        path: 'estudios/:estudios/campuses/:campus',
        name: 'admin.stats.estudios.intereses',
        meta: { requiresAuth: true, validate },
        component: AlumnosNivelEstudios
      },
      {
        path: 'campuses/:campus/convocatorias/:convocatoria',
        name: 'admin.stats.intereses.campus',
        meta: { requiresAuth: true, validate },
        component: InteresesCampus
      },
      {
        path: 'campuses/:campus/estudios/:estudios',
        name: 'admin.stats.campus.estudios',
        meta: { requiresAuth: true, validate },
        component: AlumnosRegistradosCampusEstudios
      },
      {
        path: 'comunidades/:comunidad/alumnos',
        name: 'admin.stats.alumnos.comunidad',
        meta: { requiresAuth: true, validate },
        component: AlumnosRegistradosComunidad
      },
      {
        path: 'convocatorias/:convocatoria/alumnos',
        name: 'admin.stats.alumnos.convocatoria',
        meta: { requiresAuth: true, validate },
        component: AlumnosConvocatoria
      },
      {
        path: 'alumnos/global/:estudios',
        name: 'admin.stats.alumnos.estudios',
        meta: { requiresAuth: true, validate },
        component: AlumnosRegistradosEstudios
      },
      {
        path: 'alumnos/interesados/:campus',
        name: 'admin.stats.alumnos.campus',
        meta: { requiresAuth: true, validate },
        component: AlumnosRegistradosPorCampus
      },
      // Acciones
      {
        path: 'acciones/recordatorios',
        name: 'admin.actions.reminders.index',
        meta: { requiresAuth: true, validate },
        component: AccionesRecordatoriosConvocatorias
      },
      {
        path: 'acciones/recordatorios/:convocatoria',
        name: 'admin.actions.reminders.show',
        meta: { requiresAuth: true, validate },
        component: AccionesRecordatoriosAlumnos
      }
    ]
  },
  // Auth
  {
    path: '/admin/register/:administradorIndice',
    name: 'admin.register',
    meta: { requiresAuth: false, validate },
    component: Register
  },
  {
    path: '/admin/login',
    name: 'admin.login',
    meta: { requiresAuth: false, validate },
    component: Login
  }
]

export default routes
