<template>
  <li
    class="pl-3 pr-4 py-3 text-sm"
  >
    <div class="flex items-center justify-between">
      <div class="ml-4 flex-shrink-0">
        <button class="font-medium text-indigo-600 hover:text-indigo-500">
          {{ participante.credito }}:
        </button>
      </div>
      <div class="w-0 flex-1 flex items-center">
        <span class="ml-2 flex-1 w-0 truncate"> {{ participante.alumnos_nombre }}</span>

        <!-- BEGIN: Información Participante Toggle -->
        <div class="text-center">
          <a
            href="javascript:;"
            :name="`participante-${participante.indice}-cred-${participante.credito}`"
            class="tooltip btn btn-primary"
          >Más Info</a
          >
        </div>
        <!-- END: Información Participante Toggle -->
        <!-- BEGIN: Información Participante Tooltip -->
        <div class="tooltip-content">
          <TippyContent :options="{maxWidth: '90vw', width: '800px'}" :to="`participante-${participante.indice}-cred-${participante.credito}`">
            <div class="relative flex items-center py-1">
              <div class="w-16 h-16 image-fit">
                <img
                  alt="Fotografía"
                  class="rounded-full"
                  :src="participante.alumnos_fotografia ? participante.alumnos_fotografia : `https://ui-avatars.com/api/?name=${encodeURI(`${participante.alumnos_nombre}`)}&color=FFFFFF&background=666666`"
                />
              </div>
              <div class="ml-4 mr-auto">
                <div
                  class="font-medium dark:text-gray-300 leading-relaxed uppercase text-lg"
                >
                  {{ participante.alumnos_nombre }}
                </div>
                <div class="text-gray-600">
                  <div
                    v-for="(campo, index) in campos"
                    :key="`participante-${participante.indice}-camp-${index}`"
                    class="flex flex-wrap"
                  >
                    <div class="w-20 font-bold">{{ campo.titulo }}:</div>
                    <div class="w-56 break-words">{{ participante[campo.clave] }}</div>
                  </div>
                </div>
              </div>
            </div>
          </TippyContent>
        </div>
        <!-- END: Información Participante Tooltip -->
      </div>
    </div>
  </li>
</template>

<script>
import { defineComponent } from 'vue'

class Campo {
  constructor(titulo, clave) {
    this.titulo = titulo
    this.clave = clave
  }
}

export default defineComponent({
  props: {
    participante: {
      type: Object,
      required: true
    }
  },

  name: 'ParticipanteParticipacion',

  data() {
    return {
      campos: [
        new Campo('Campus', 'campuses_nombre'),
        new Campo('Estudios', 'estudios_nombre'),
        new Campo('Matricula', 'alumnos_matricula'),
        new Campo('Correo', 'alumnos_correoPersonal'),
        new Campo('Celular', 'alumnos_celular')
      ]
    }
  }
})
</script>

<style scoped>

</style>
