<template>
  <div>
    <Perfil :alumno="alumno" />

    <ConvocatoriasInteres :alumno="alumno" />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import ConvocatoriasInteres from '@/views/alumnos/components/convocatorias/Interes'
import Perfil from '@/views/alumnos/components/cuenta/Perfil'

export default defineComponent({
  components: { Perfil, ConvocatoriasInteres },

  setup() {
    const store = useStore()
    const alumno = computed(() => store.state.alumnos.auth.alumno)

    return {
      alumno
    }
  }
})
</script>

<style scoped>

</style>
