
export function tieneAccesoEvento(state) {
  return (eventoIndice) => {
    return state.usuario ? state.usuario.eventos_indices.includes(eventoIndice) : false
  }
}

export function tieneAccesoModulo(state) {
  return (codigoModulo) => {
    return state.usuario ? state.usuario.modulos_codigos.includes(codigoModulo) : false
  }
}

export function tieneAccesoInterfaz(state) {
  return (codigoInterfaz) => {
    return state.usuario ? state.usuario.interfaces_codigos.includes(codigoInterfaz) : false
  }
}
