<template>
  <div class="intro-y box mt-5">
    <div
      class="flex flex-col sm:flex-row items-center p-5 border-gray-200"
    >
      <h2 class="font-medium text-base mx-auto">Alumnos Registrados por Campus y Nivel de Estudios</h2>
    </div>
    <div id="hoverable-table" class="p-5 pt-0">
      <div class="preview">
        <div class="overflow-x-auto">
          <table class="table">
            <thead>
            <tr>
              <th
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Campus
              </th>
              <th
                v-for="estudio in estudiosOrdenados"
                :key="`estudioTitulo-${estudio.indice_herramienta}`"
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                {{ estudio.nombre_herramienta }}
              </th>
              <th
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Total Campus
              </th>
              <th
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Porcentaje del Total
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="campus in campusesAutorizados"
              :key="`campus-${campus.indice_herramienta}`"
              class="hover:bg-gray-200"
            >
              <td class="border">{{ campus.nombre_herramienta }}</td>
              <td
                v-for="estudio in estudiosOrdenados"
                :key="`estudioTitulo-${estudio.indice_herramienta}`"
                class="border"
              >

                <router-link v-if="campus[estudio.campoCantidadRegistrados_herramienta]!==0" :to="{name: 'admin.stats.campus.estudios', params: {campus: campus.codigo_herramienta, estudios: estudio.codigo_herramienta}}">
                  {{ campus[estudio.campoCantidadRegistrados_herramienta] }}
                </router-link>
                <p v-else>{{ campus[estudio.campoCantidadRegistrados_herramienta] }}</p>
              </td>
              <td class="border"> {{ campus.cantidadAlumnosRegistrados_herramienta }}</td>
              <td class="border">{{ (campus.porcentajeAlumnosRegistrados_herramienta * 100).toFixed(2) }} %</td>
            </tr>
            <tr>
              <th class="border">Total</th>
              <th
                v-for="estudio in estudiosOrdenados"
                :key="`estudioTitulo-${estudio.indice_herramienta}`"
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                {{ sumaEstudios(estudio) }}
              </th>
              <th class="border">{{ sumaAlumnos }}</th>
              <th class="border">{{ sumaAlumnosPorcentajes }} %</th>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { orderBy } from 'lodash'
import { mapState } from 'vuex'
export default defineComponent({
  name: 'AlumnosRegistradosCampusEstudios',
  props: {
    estudios: {
      type: Array,
      required: true
    },
    campuses: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('administradores/auth', ['administrador', 'usuario']),
    sumaAlumnosPorcentajes() {
      let suma = 0
      this.$props.campuses.forEach(campus => {
        suma += campus.porcentajeAlumnosRegistrados_herramienta * 100 ?? 0
      })

      return Math.round(suma)
    },
    sumaAlumnos() {
      let suma = 0
      this.$props.campuses.forEach(campus => {
        suma += campus.cantidadAlumnosRegistrados_herramienta ?? 0
      })

      return suma
    },
    sumaEstudios() {
      return (estudio) => {
        let suma = 0
        this.$props.campuses.forEach(campus => {
          suma += campus[estudio.campoCantidadRegistrados_herramienta] ?? 0
        })

        return suma
      }
    },
    campusesAutorizados() {
      return orderBy(
        this.$props.campuses.filter((campus) => {
          return this.administrador.campuses_indice ? this.administrador.campuses_indice === campus.indice_herramienta : true
        }),
        'orden_herramienta'
      )
    },
    estudiosOrdenados() {
      return orderBy(
        this.$props.estudios,
        'orden_herramienta'
      )
    }
  }
})
</script>

<style scoped>

</style>
