<template>
  <!-- BEGIN: Basic Table -->
  <div class="intro-y box mt-8">
    <div
      class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200"
    >
      <h2 class="font-medium text-base mr-auto">Tablas</h2>
      <div
        class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
      >
        <button class="btn btn-dark w-36" @click="actualizarTodas">
          Actualizar Todas
        </button>
      </div>
    </div>
    <div id="basic-table" class="p-5">
      <div class="preview">
        <div class="overflow-x-auto">
          <table class="table table-condensed">
            <thead>
            <tr>
              <th
                class="border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Indice
              </th>
              <th
                class="border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Nombre
              </th>
              <th
                class="border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Código
              </th>
              <th
                style="width: 250px;"
                class="border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Estado
              </th>
              <th
                style="width: 250px;"
                class="border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Acciones
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="tabla in tablas" :key="`tabla-${tabla.indice}`">
              <td class="border-b dark:border-dark-5">{{ tabla.indice }}</td>
              <td class="border-b dark:border-dark-5">{{ tabla.nombre }}</td>
              <td class="border-b dark:border-dark-5">{{ tabla.codigo }}</td>
              <td class="border-b dark:border-dark-5">
                {{ tabla.updating ? 'Actualizando...' : 'Pendiente' }}
              </td>
              <td class="border-b dark:border-dark-5">
                <button
                  :class="tabla.updating ? 'btn-warning' : 'btn-success'"
                  class="btn btn-sm w-24 mr-1 my-0"
                  @click="actualizarTabla(tabla)"
                >
                  {{ tabla.updating ? 'Actualizando...' : 'Actualizar' }}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Basic Table -->
</template>

<script>
import { fbFunctions } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'
import { defineComponent, onMounted, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  setup() {
    const store = useStore()
    const tablas = computed(() => store.getters['administradores/bridge/tablasOrdenadas'])

    const obtenerTablas = () => store.dispatch('administradores/bridge/obtenerTablas')
    const actualizandoTabla = (params) => store.dispatch('administradores/bridge/actualizandoTabla', params)

    onMounted(() => {
      obtenerTablas()
    })

    const updateTable = httpsCallable(fbFunctions, 'at-updateTable')
    const actualizarTabla = (tabla) => {
      actualizandoTabla({ tabla, updating: true })
      updateTable({ table: tabla.codigo })
        .then(() => {
          actualizandoTabla({ tabla, updating: false })
        })
        .catch((e) => {
          actualizandoTabla({ tabla, updating: false })
        })
    }

    const actualizarTodas = () => {
      let contador = 0

      tablas.value.forEach(tabla => {
        setTimeout(() => {
          actualizarTabla(tabla)
        }, contador)

        contador += 500
      })
    }

    return {
      actualizarTodas,
      actualizarTabla,
      tablas,
      obtenerTablas
    }
  }
})
</script>

<style scoped>

</style>
