<template>
  <div>
    <Perfil :alumno="alumno" />

    <Cuenta :alumno="alumno" :loading="loading" @alumno-actualizado="editarAlumno" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import Perfil from '@/views/alumnos/components/cuenta/Perfil'
import Cuenta from '@/views/alumnos/components/cuenta/Cuenta'
import Airtable from 'airtable'
import { fbFunctions } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'
const base = new Airtable({ apiKey: 'patmSk0sdzVet2VQD.067b5939a8026476fb7e425b473c39417eecafad467e1ca526516af244112bdc' }).base('appYzmr5BAYcj9vHc')

export default defineComponent({
  components: { Cuenta, Perfil },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('alumnos/auth', ['alumno'])
  },
  methods: {
    editarAlumno(alumno) {
      this.loading = true
      const alumnoAirtable = {
        usuario_indice_valorManual: alumno.usuario_indice,
        programas_relacionManual: [alumno.programas_indice],
        campuses_relacionManual: [alumno.campuses_indice],
        generos_relacionManual: [alumno.generos_indice],
        genero_valorManual: alumno.genero,
        matricula_valorManual: alumno.matricula,
        nombrePrimero_valorManual: alumno.nombrePrimero,
        nombreSegundo_valorManual: alumno.nombreSegundo,
        apellidoPaterno_valorManual: alumno.apellidoPaterno,
        apellidoMaterno_valorManual: alumno.apellidoMaterno,
        apodo_valorManual: alumno.apodo,
        celular_valorManual: alumno.celular,
        nacimientosDias_relacionManual: [alumno.nacimientosDias_indice],
        nacimientosMeses_relacionManual: [alumno.nacimientosMeses_indice],
        nacimientosAnios_relacionManual: [alumno.nacimientosAnios_indice],
        comunidades_relacionManual: [alumno.comunidades_indice],
        convocatorias_relacionesManuales: alumno.convocatorias_indices,
        fotografia_valorManual: alumno.fotografia_link
      }

      if (alumno.fotografia_link) {
        alumnoAirtable.fotografia_imagen = [
          {
            url: alumno.fotografia_link
          }
        ]
      }

      const updateRecord = httpsCallable(fbFunctions, 'at-updateRecord')
      base('alumnos').update(alumno.id, alumnoAirtable)
        .then(() => {
          updateRecord({ table: 'alumnos', id: alumno.id })
            .then(() => {
              this.loading = false
            })
        })
    }
  }
})
</script>

<style scoped>

</style>
