<template>
  <div class="mx-auto my-auto container">
    <div class="intro-y box px-5 pt-5 mt-5">
      <div class="intro-y flex items-center mb-4">
        <h2 class="text-lg font-medium mr-auto">
          {{ alumno ? 'Editar Perfil' : 'Completa tus datos para crear tu usuario'}}
        </h2>
      </div>
      <div class="flex flex-col lg:flex-row pb-5 -mx-5">
        <div
          class="flex flex-1 px-5 items-center justify-center"
        >
          <div
            class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative"
          >
            <img
              class="rounded-full"
              :src="alumnoProceso.fotografia_link ? alumnoProceso.fotografia_link : alumnoProceso.fotografia ? alumnoProceso.fotografia : `https://ui-avatars.com/api/?name=${encodeURI(`${alumnoProceso.nombrePrimero}+${alumnoProceso.apellidoPaterno}`)}&color=FFFFFF&background=666666`"
            />
            <div
              @click="uploadPhoto"
              class="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-theme-1 rounded-full p-2"
            >
              <CameraIcon class="w-4 h-4 text-white" />
            </div>
          </div>
          <div class="ml-5">
            <div
              class="w-24 sm:w-40 truncate sm:whitespace-normal font-medium text-lg"
            >
              {{ alumnoProceso.nombre }}
            </div>
            <div class="text-gray-600">{{ alumnoProceso.apodo }}</div>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col lg:flex-row border-b border-t border-gray-200 dark:border-dark-5 pb-5 -mx-5"
      >
        <div
          class="flex flex-1 px-5 justify-center lg:justify-start intro-y"
        >
          <div class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 dark:border-dark-5 border-t border-t-0 lg:pt-0">
            <!-- matricula -->
            <div class="lg:mt-3">
              <label for="matricula" class="form-label font-bold">Matrícula *</label>
              <input
                v-model="alumnoProceso.matricula"
                id="matricula"
                type="text"
                class="form-control w-full"
                placeholder="Escribe tu Matrícula"
              />
            </div>
            <!-- /matricula -->
            <!-- estudios -->
            <div class="mt-3">
              <label class="font-bold">Modalidad de Estudios *</label>
              <div class="mt-2">
                <TomSelect
                  v-model="alumnoProceso.estudios_indice"
                  @change="actualizarListaCampuses"
                  :options="{
                      placeholder: 'Selecciona tu modalidad de Estudios',
                      maxOptions: 1000
                    }"
                  class="w-full"
                >
                  <option value="default" selected v-if="!alumnoProceso.estudios_indice">
                    -- Selecciona --
                  </option>
                  <option
                    v-for="estudio in estudios"
                    :key="`estudio-${estudio.indice}`"
                    :value="estudio.indice"
                    :selected="alumnoProceso.estudios_indice === estudio.indice"
                  >
                    {{ estudio.nombre }}
                  </option>
                </TomSelect>
              </div>
            </div>
            <!-- /estudios -->
            <!-- campuses -->
            <div class="mt-3">
              <label class="font-bold">Campus *</label>
              <div class="mt-2">
                <TomSelect
                  v-model="alumnoProceso.campuses_indice"
                  :options="{
                      placeholder: 'Selecciona tu Campus',
                      maxOptions: 1000
                    }"
                  class="w-full"
                >
                  <option value="default" selected v-if="!alumnoProceso.campuses_indice">
                    -- Selecciona --
                  </option>
                  <option
                    v-for="campus in campuses"
                    :key="`campus-${campus.indice}`"
                    :value="campus.indice"
                    :selected="alumnoProceso.campuses_indice === campus.indice"
                  >
                    {{ campus.nombre }}
                  </option>
                </TomSelect>
              </div>
            </div>
            <!-- /campuses -->
            <!-- programas -->
            <div class="mt-3">
              <label class="font-bold">Programa *</label>
              <div class="mt-2">
                <TomSelect
                  v-model="alumnoProceso.programas_indice"
                  :options="{
                      placeholder: 'Selecciona tu Programa',
                      maxOptions: 1000
                    }"
                  class="w-full"
                >
                  <option value="default" selected v-if="!alumnoProceso.programas_indice">
                    -- Selecciona --
                  </option>
                  <optgroup
                    v-for="departamento in departamentos"
                    :key="`departamento-${departamento.indice}`"
                    :label="departamento.nombre"
                  >
                    <option
                      v-for="programa in programasPorDepartamento(departamento.indice)"
                      :key="`programa-${programa.indice}`"
                      :value="programa.indice"
                      :selected="alumnoProceso.programas_indice === programa.indice"
                    >
                      {{ programa.nombre }}
                    </option>
                  </optgroup>
                </TomSelect>
              </div>
            </div>
            <!-- /programas -->
            <!-- comunidades -->
            <div class="mt-3">
              <label class="font-bold">Comunidad *</label>
              <div class="mt-2">
                <TomSelect
                  v-model="alumnoProceso.comunidades_indice"
                  :options="{
                      placeholder: 'Selecciona tu Comunidad',
                      maxOptions: 1000
                    }"
                  class="w-full"
                >
                  <option value="defaul" selected v-if="!alumnoProceso.comunidades_indice">
                    -- Selecciona --
                  </option>
                  <option
                    v-for="comunidad in comunidades"
                    :key="`comunidad-${comunidad.indice}`"
                    :value="comunidad.indice"
                    :selected="alumnoProceso.comunidades_indice === comunidad.indice"
                  >
                    {{ comunidad.nombre }}
                  </option>
                </TomSelect>
              </div>
            </div>
            <!-- /comunidades -->
          </div>
        </div>

        <div
          class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
        >
          <!-- nombrePrimero -->
          <div class="mt-3">
            <label for="nombrePrimero" class="form-label font-bold">Nombre *</label>
            <input
              v-model="alumnoProceso.nombrePrimero"
              id="nombrePrimero"
              type="text"
              class="form-control w-full"
              placeholder="Escribe tu Primer Nombre"
            />
          </div>
          <!-- /nombrePrimero -->
          <!-- nombreSegundo -->
          <div class="mt-3">
            <label for="nombreSegundo" class="form-label font-bold">Segundo Nombre</label>
            <input
              v-model="alumnoProceso.nombreSegundo"
              id="nombreSegundo"
              type="text"
              class="form-control w-full"
              placeholder="Escribe tu Segundo Nombre"
            />
          </div>
          <!-- /nombreSegundo -->
          <!-- apellidoPaterno -->
          <div class="mt-3">
            <label for="apellidoPaterno" class="form-label font-bold">Apellido Paterno *</label>
            <input
              v-model="alumnoProceso.apellidoPaterno"
              id="apellidoPaterno"
              type="text"
              class="form-control w-full"
              placeholder="Escribe tu Apellido Paterno"
            />
          </div>
          <!-- /apellidoPaterno -->
          <!-- apellidoMaterno -->
          <div class="mt-3">
            <label for="apellidoMaterno" class="form-label font-bold">Apellido Materno *</label>
            <input
              v-model="alumnoProceso.apellidoMaterno"
              id="apellidoMaterno"
              type="text"
              class="form-control w-full"
              placeholder="Escribe tu Apellido Materno"
            />
          </div>
          <!-- /apellidoMaterno -->
          <!-- apodo -->
          <div class="mt-3">
            <label for="apodo" class="form-label font-bold">Apodo</label>
            <input
              v-model="alumnoProceso.apodo"
              id="apodo"
              type="text"
              class="form-control w-full"
              placeholder="Escribe tu Apodo"
            />
          </div>
          <!-- /apodo -->
        </div>

        <div
          class="mt-6 lg:mt-0 flex-1 dark:text-gray-300 px-5 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
        >
          <!-- celular -->
          <div class="mt-3">
            <label for="celular" class="form-label font-bold">Celular</label>
            <input
              v-model="alumnoProceso.celular"
              id="celular"
              type="text"
              class="form-control w-full"
              placeholder="Escribe tu Celular"
            />
          </div>
          <!-- /celular -->

          <div class="grid grid-cols-3 gap-4">
            <div class="col-span-3">
              <p class="font-bold mt-4 text-center">Fecha de Nacimiento</p>
            </div>
            <!-- nacimientoDia -->
            <div class="mt-1">
              <label class="form-label font-bold">Días *</label>
              <TomSelect
                v-model="alumnoProceso.nacimientosDias_indice"
                :options="{
                      placeholder: 'Selecciona tu Día de Nacimiento',
                      maxOptions: 1000
                    }"
                class="w-full"
              >
                <option
                  v-for="dia in nacimientosDias"
                  :key="`dia-${dia.indice}`"
                  :value="dia.indice"
                  class="option"
                >
                  {{ dia.nombre }}
                </option>
              </TomSelect>
            </div>
            <!-- /nacimientoDia -->
            <!-- nacimientoMes -->
            <div>
              <label class="form-label font-bold">Meses *</label>
              <TomSelect
                v-model="alumnoProceso.nacimientosMeses_indice"
                :options="{
                      placeholder: 'Selecciona tu Mes de Nacimiento',
                      maxOptions: 1000
                    }"
                class="w-full"
              >
                <option
                  v-for="mes in nacimientosMeses"
                  :key="`mes-${mes.indice}`"
                  :value="mes.indice"
                  class="option"
                >
                  {{ mes.nombre }}
                </option>
              </TomSelect>
            </div>
            <!-- /nacimientoMes -->
            <!-- nacimientoAnio -->
            <div>
              <label class="form-label font-bold">Año *</label>
              <TomSelect
                v-model="alumnoProceso.nacimientosAnios_indice"
                :options="{
                      placeholder: 'Selecciona tu Año de Nacimiento',
                      maxOptions: 1000
                    }"
                class="w-full"
              >
                <option
                  v-for="anio in nacimientosAnios"
                  :key="`anio-${anio.indice}`"
                  :value="anio.indice"
                  class="option"
                >
                  {{ anio.nombre }}
                </option>
              </TomSelect>
            </div>
            <!-- /nacimientoAnio -->
          </div>

          <!-- generos -->
          <div class="mt-3">
            <label class="font-bold">Género *</label>
            <div class="mt-2">
              <TomSelect
                @change="reiniciarGenero"
                v-model="alumnoProceso.generosTipos_indice"
                :options="{
                      placeholder: 'Selecciona tu Género',
                      maxOptions: 1000
                    }"
                class="w-full"
              >
                <option value="default" selected v-if="!alumnoProceso.generosTipos_indice">
                  -- Selecciona --
                </option>
                <option
                  v-for="generoTipo in generosTipos"
                  :key="`generoTipo-${generoTipo.indice}`"
                  :value="generoTipo.indice"
                  :selected="alumnoProceso.generosTipos_indice === generoTipo.indice"
                >
                  {{ generoTipo.nombre }}
                </option>
              </TomSelect>
            </div>
          </div>
          <!-- /generos -->

          <!-- generos -->
          <div class="mt-3" v-if="!generoTipoTienePredeterminado && alumnoProceso.generosTipos_indice">
            <label class="font-bold">Pronombre</label>
            <div class="mt-2">
              <TomSelect
                v-model="alumnoProceso.generos_indice"
                :options="{
                      placeholder: 'Selecciona tu Género',
                      maxOptions: 1000
                    }"
                class="w-full"
              >
                <option value="default" selected v-if="!alumnoProceso.generos_indice">
                  -- Selecciona --
                </option>
                <option
                  v-for="genero in generosPorTipoGenero(alumnoProceso.generosTipos_indice)"
                  :key="`genero-${genero.indice}`"
                  :value="genero.indice"
                  :selected="alumnoProceso.generos_indice === genero.indice"
                >
                  {{ genero.pronombres_ejemplo }}
                </option>
              </TomSelect>
            </div>
          </div>
          <!-- /generos -->

          <!-- generoPersonalizado -->
          <div class="mt-3" v-if="!generoTipoTienePredeterminado && alumnoProceso.generosTipos_indice">
            <label for="generoPersonalizado" class="form-label font-bold">Género Personalizado (opcional)</label>
            <input
              v-model="alumnoProceso.genero"
              id="generoPersonalizado"
              type="text"
              class="form-control w-full"
              placeholder="Personaliza tu Género"
            />
          </div>
          <!-- /generoPersonalizado -->

          <!-- convocatorias -->
          <div class="mt-3">
            <label class="font-bold">Convocatorias de Interés * (Selecciona al menos una)</label>
            <div class="mt-2">
              <TomSelect
                multiple
                v-model="alumnoProceso.convocatorias_indices"
                :options="{
                      placeholder: 'Selecciona las Convocatorias de tu Interés',
                      maxOptions: 1000
                    }"
                class="w-full"
              >
                <option
                  v-for="convocatoria in convocatorias"
                  :key="`convocatoria-${convocatoria.indice}`"
                  :value="convocatoria.indice"
                >
                  {{ convocatoria.nombre }}
                </option>
              </TomSelect>
            </div>
          </div>
          <!-- /convocatorias -->
        </div>
      </div>

      <div class="lg:flex lg:justify-between">
        <div class="lg:my-auto mt-3">
          <p class="italic" :class="error ? 'text-theme-11' : ''">Los campos marcados con * son obligatorios</p>
          <p class="italic" :class="foto ? 'text-theme-11' : ''" v-if="foto">La fotografía de perfil es obligatoria</p>
        </div>
        <div class="text-right">
          <button :disabled="loading" type="button" class="btn btn-dark w-full lg:w-48 my-4" @click="enviarCambios">
            {{ alumno ? (loading ? 'Guardando...' : 'Guardar') : (loading ? 'Creando Cuenta' : 'Crear Cuenta') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { orderBy, cloneDeep } from 'lodash'
import { fbStorage } from '@/plugins/firebase'
import { getDownloadURL, uploadBytes, ref as storageRef } from 'firebase/storage'

export default defineComponent({
  props: {
    alumno: {
      type: Object,
      required: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['alumnoActualizado'],
  setup(props, { emit }) {
    const updating = ref(false)
    const store = useStore()
    const alumnoProceso = props.alumno
      ? ref(cloneDeep(props.alumno))
      : ref({
        usuario_indice: store.state.alumnos.auth.usuario.uid,
        estudios_indice: '',
        programas_indice: '',
        campuses_indice: '',
        generosTipos_indice: '',
        generos_indice: '',
        correoPersonal: store.state.alumnos.auth.usuario.email,
        correoTec: '',
        matricula: '',
        nombrePrimero: '',
        nombreSegundo: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        apodo: '',
        celular: '',
        genero: '',
        fotografia: '',
        nacimientosDias_indice: 'rec0cvdRcE5DLVjXL',
        nacimientosMeses_indice: 'recIjf3lIGmt2ou2k',
        nacimientosAnios_indice: 'rec1wM8PgDJpsC70F',
        comunidades_indice: '',
        fotografia_link: '',
        convocatorias_indices: []
      })
    const actualizarListaCampuses = () => {
      store.dispatch('alumnos/cuenta/obtenerCampusesPorIndiceEstudios', alumnoProceso.value.estudios_indice)
      store.dispatch('alumnos/cuenta/obtenerDepartamentosPorEstudioIndice', alumnoProceso.value.estudios_indice)
      store.dispatch('alumnos/cuenta/obtenerProgramasPorEstudioIndice', alumnoProceso.value.estudios_indice)
    }
    const comunidades = computed(() => store.getters['alumnos/cuenta/comunidadesOrdenados'])
    const campuses = computed(() => store.getters['alumnos/cuenta/campusesOrdenados'])
    const convocatorias = computed(() => store.getters['alumnos/convocatorias/convocatoriasActivasOrdenadas'])
    const departamentos = computed(() => store.getters['alumnos/cuenta/departamentosOrdenados'])
    const estudios = computed(() => store.getters['alumnos/cuenta/estudiosOrdenados'])
    const generos = computed(() => store.getters['alumnos/cuenta/generosOrdenados'])
    const generosTipos = computed(() => store.getters['alumnos/cuenta/generosTiposOrdenados'])
    const nacimientosAnios = computed(() => store.getters['alumnos/cuenta/nacimientosAniosOrdenados'])
    const nacimientosDias = computed(() => store.getters['alumnos/cuenta/nacimientosDiasOrdenados'])
    const nacimientosMeses = computed(() => store.getters['alumnos/cuenta/nacimientosMesesOrdenados'])
    const obtenerComunidades = () => store.dispatch('alumnos/cuenta/obtenerComunidades')
    const obtenerEstudios = () => store.dispatch('alumnos/cuenta/obtenerEstudios')
    const obtenerGeneros = () => store.dispatch('alumnos/cuenta/obtenerGeneros')
    const obtenerGenerosTipos = () => store.dispatch('alumnos/cuenta/obtenerGenerosTipos')
    const obtenerNacimientosDias = () => store.dispatch('alumnos/cuenta/obtenerNacimientosDias')
    const obtenerNacimientosMeses = () => store.dispatch('alumnos/cuenta/obtenerNacimientosMeses')
    const obtenerNacimientosAnios = () => store.dispatch('alumnos/cuenta/obtenerNacimientosAnios')
    const obtenerConvocatorias = () => store.dispatch('alumnos/convocatorias/obtenerConvocatorias')
    const programas = computed(() => store.getters['alumnos/cuenta/programasOrdenados'])
    const error = ref(false)
    const foto = ref(false)
    const programasPorDepartamento = computed(() => {
      return (departamentoIndice) => {
        return orderBy(
          programas.value.filter(programa => {
            return programa.departamentos_indice === departamentoIndice
          }),
          'orden'
        )
      }
    })
    const generosPorTipoGenero = computed(() => {
      return (generoTipoIndice) => {
        return orderBy(
          generos.value.filter(genero => {
            return genero.generosTipos_indice === generoTipoIndice
          }),
          'orden'
        )
      }
    })
    const asignarGenero = () => {
      alumnoProceso.value.genero = ''
      alumnoProceso.value.generos_indice = generosTipos.value.find(generoTipo => generoTipo.indice === alumnoProceso.value.generosTipos_indice).generos_referencia_indice
    }
    const generoTipoTienePredeterminado = computed(() => {
      if (!alumnoProceso.value && !alumnoProceso.value.generosTipos_indice) {
        return false
      }
      return generosTipos.value.find(generoTipo => generoTipo.indice === alumnoProceso.value.generosTipos_indice)
        ? !!generosTipos.value.find(generoTipo => generoTipo.indice === alumnoProceso.value.generosTipos_indice).generos_referencia_indice
        : false
    })
    const enviarCambios = () => {
      if (
        !alumnoProceso.value.matricula ||
        !alumnoProceso.value.estudios_indice ||
        !alumnoProceso.value.campuses_indice ||
        !alumnoProceso.value.programas_indice ||
        !alumnoProceso.value.nombrePrimero ||
        !alumnoProceso.value.apellidoPaterno ||
        !alumnoProceso.value.apellidoMaterno ||
        !alumnoProceso.value.nacimientosDias_indice ||
        !alumnoProceso.value.nacimientosMeses_indice ||
        !alumnoProceso.value.nacimientosAnios_indice ||
        !alumnoProceso.value.convocatorias_indices.length ||
        !alumnoProceso.value.comunidades_indice ||
        !alumnoProceso.value.generos_indice
      ) {
        error.value = true
        return
      }
      emit('alumnoActualizado', alumnoProceso.value)
    }

    const uploadPhoto = () => {
      const input = document.createElement('input')
      input.type = 'file'

      input.onchange = e => {
        const file = e.target.files[0]
        const filename = 'alumno-' + +new Date() + '-' + file.name
        const photoRef = storageRef(fbStorage, `/alumnos/${filename}`)
        uploadBytes(photoRef, file).then((response) => {
          getDownloadURL(response.ref).then((link) => {
            alumnoProceso.value.fotografia_link = link
          })
        })
      }

      input.click()
    }

    onMounted(() => {
      obtenerComunidades()
      obtenerNacimientosDias()
      obtenerNacimientosMeses()
      obtenerNacimientosAnios()
      obtenerEstudios()
      obtenerGenerosTipos()
      obtenerGeneros()
      obtenerConvocatorias()
      if (alumnoProceso.value.estudios_indice) {
        actualizarListaCampuses()
      }
    })

    return {
      uploadPhoto,
      updating,
      error,
      foto,
      alumnoActual: alumnoProceso,
      actualizarListaCampuses,
      alumnoProceso,
      comunidades,
      convocatorias,
      campuses,
      departamentos,
      enviarCambios,
      estudios,
      generoTipoTienePredeterminado,
      generosPorTipoGenero,
      generosTipos,
      nacimientosAnios,
      nacimientosDias,
      nacimientosMeses,
      programasPorDepartamento,
      reiniciarGenero: asignarGenero
    }
  }
})
</script>

<style scoped>

</style>
