import { fbFirestore } from '@/plugins/firebase'
import { collection, onSnapshot, query, where } from 'firebase/firestore'

/* export function obtenerDocumentoPorCodigo({ rootState, commit }, documentoCodigo) {
  return new Promise((resolve, reject) => {
    try {
      const q = query(
        collection(fbFirestore, 'documentos'),
        where('codigo', '==', documentoCodigo),
        limit(1)
      )

      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach(doc => {
          resolve(doc.data())
        })
      })
    } catch (e) {
      reject(e)
    }
  })
} */

export function obtenerCampusesPorIndiceEstudios({ rootState, commit }, estudiosIndice) {
  return new Promise((resolve) => {
    commit('LIMPIAR', { table: 'campuses' })

    const q = query(
      collection(fbFirestore, 'campuses'),
      where('estudios_indices', 'array-contains', estudiosIndice)
    )
    onSnapshot(q, (querySnapshop) => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(() => commit('AGREGAR', { table: 'campuses', payload: change.doc.data() }), i)
            break
          }

          case 'modified': {
            setTimeout(() => commit('EDITAR', { table: 'campuses', payload: change.doc.data() }), i)
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'campuses', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerDepartamentosPorEstudioIndice({ rootState, commit }, estudiosIndice) {
  return new Promise((resolve) => {
    commit('LIMPIAR', { table: 'departamentos' })

    const q = query(
      collection(fbFirestore, 'departamentos'),
      where('estudios_indices', 'array-contains', estudiosIndice)
    )
    const unsuscribe = onSnapshot(q, (querySnapshop) => {
      unsuscribe()
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(() => commit('AGREGAR', { table: 'departamentos', payload: change.doc.data() }), i)
            break
          }

          case 'modified': {
            setTimeout(() => commit('EDITAR', { table: 'departamentos', payload: change.doc.data() }), i)
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'departamentos', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerProgramasPorEstudioIndice({ rootState, commit }, estudiosIndice) {
  return new Promise((resolve) => {
    commit('LIMPIAR', { table: 'programas' })

    const q = query(
      collection(fbFirestore, 'programas'),
      where('estudios_indice', '==', estudiosIndice)
    )
    const unsuscribe = onSnapshot(q, (querySnapshop) => {
      unsuscribe()
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(() => commit('AGREGAR', { table: 'programas', payload: change.doc.data() }), i)
            break
          }

          case 'modified': {
            setTimeout(() => commit('EDITAR', { table: 'programas', payload: change.doc.data() }), i)
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'programas', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerEstudios({ rootState, commit }) {
  return new Promise((resolve) => {
    if (rootState.alumnos.cuenta.estudios.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'estudios' })

    const q = query(collection(fbFirestore, 'estudios'))
    onSnapshot(q, (querySnapshop) => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(() => commit('AGREGAR', { table: 'estudios', payload: change.doc.data() }), i)
            break
          }

          case 'modified': {
            setTimeout(() => commit('EDITAR', { table: 'estudios', payload: change.doc.data() }), i)
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'estudios', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerGeneros({ rootState, commit }) {
  return new Promise((resolve) => {
    if (rootState.alumnos.cuenta.generos.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'generos' })

    const q = query(collection(fbFirestore, 'generos'))
    onSnapshot(q, (querySnapshop) => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(() => commit('AGREGAR', { table: 'generos', payload: change.doc.data() }), i)
            break
          }

          case 'modified': {
            setTimeout(() => commit('EDITAR', { table: 'generos', payload: change.doc.data() }), i)
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'generos', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerGenerosTipos({ rootState, commit }) {
  return new Promise((resolve) => {
    if (rootState.alumnos.cuenta.generosTipos.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'generosTipos' })

    const q = query(collection(fbFirestore, 'generosTipos'))
    onSnapshot(q, (querySnapshop) => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(() => commit('AGREGAR', { table: 'generosTipos', payload: change.doc.data() }), i)
            break
          }

          case 'modified': {
            setTimeout(() => commit('EDITAR', { table: 'generosTipos', payload: change.doc.data() }), i)
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'generosTipos', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerComunidades({ rootState, commit }) {
  return new Promise((resolve) => {
    if (rootState.alumnos.cuenta.comunidades.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'comunidades' })

    const q = query(collection(fbFirestore, 'comunidades'))
    onSnapshot(q, (querySnapshop) => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(() => commit('AGREGAR', { table: 'comunidades', payload: change.doc.data() }), i)
            break
          }

          case 'modified': {
            setTimeout(() => commit('EDITAR', { table: 'comunidades', payload: change.doc.data() }), i)
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'comunidades', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerNacimientosDias({ rootState, commit }) {
  return new Promise((resolve) => {
    if (rootState.alumnos.cuenta.nacimientosDias.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'nacimientosDias' })

    const q = query(collection(fbFirestore, 'nacimientosDias'))
    onSnapshot(q, (querySnapshop) => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(() => commit('AGREGAR', { table: 'nacimientosDias', payload: change.doc.data() }), i)
            break
          }

          case 'modified': {
            setTimeout(() => commit('EDITAR', { table: 'nacimientosDias', payload: change.doc.data() }), i)
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'nacimientosDias', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerNacimientosMeses({ rootState, commit }) {
  return new Promise((resolve) => {
    if (rootState.alumnos.cuenta.nacimientosMeses.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'nacimientosMeses' })

    const q = query(collection(fbFirestore, 'nacimientosMeses'))
    onSnapshot(q, (querySnapshop) => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(() => commit('AGREGAR', { table: 'nacimientosMeses', payload: change.doc.data() }), i)
            break
          }

          case 'modified': {
            setTimeout(() => commit('EDITAR', { table: 'nacimientosMeses', payload: change.doc.data() }), i)
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'nacimientosMeses', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerNacimientosAnios({ rootState, commit }) {
  return new Promise((resolve) => {
    if (rootState.alumnos.cuenta.nacimientosAnios.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'nacimientosAnios' })

    const q = query(collection(fbFirestore, 'nacimientosAnios'))
    onSnapshot(q, (querySnapshop) => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(() => commit('AGREGAR', { table: 'nacimientosAnios', payload: change.doc.data() }), i)
            break
          }

          case 'modified': {
            setTimeout(() => commit('EDITAR', { table: 'nacimientosAnios', payload: change.doc.data() }), i)
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'nacimientosAnios', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}
