<template>
  <div>
    <!-- BEGIN: Hoverable Table -->
    <div
      class="intro-y box mt-5 max-w-[800px] mx-auto"
      v-for="campus in campusesAutorizados"
      :key="`campus-${campus.indice_herramienta}`"
    >
      <InteresesConvocatoria :campus="campus" :convocatorias="convocatorias"></InteresesConvocatoria>
    </div>
    <!-- END: Hoverable Table -->
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import Airtable from 'airtable'
import { orderBy } from 'lodash'
import InteresesConvocatoria from '@/views/administradores/components/estadisticas/InteresesConvocatoria'
const base = new Airtable({ apiKey: 'patmSk0sdzVet2VQD.067b5939a8026476fb7e425b473c39417eecafad467e1ca526516af244112bdc' }).base(
  'appYzmr5BAYcj9vHc'
)

export default defineComponent({
  components: { InteresesConvocatoria },
  data() {
    return {
      campuses: [],
      convocatorias: []
    }
  },
  computed: {
    ...mapState('administradores/auth', ['administrador', 'usuario']),
    campusesAutorizados() {
      return orderBy(
        this.campuses.filter(campus => {
          return this.administrador.campuses_indice
            ? this.administrador.campuses_indice === campus.indice_herramienta
            : true
        }),
        'orden_herramienta'
      )
    }
  },
  methods: {
    obtenerDatos() {
      this.campuses = []
      this.convocatorias = []
      const filter = this.administrador.campuses_indice
        ? `AND(indice_herramienta='${this.administrador.campuses_indice}')`
        : ''

      base('campuses')
        .select({
          filterByFormula: filter
        })
        .eachPage((records, fetchNextPage) => {
          const recs = []

          records.forEach(record => {
            record.fields.id = record.id
            recs.push(record.fields)
          })
          this.campuses.push(...recs)

          fetchNextPage()
        })

      base('convocatorias')
        .select({
          filterByFormula: 'AND(activo_herramienta)'
        })
        .eachPage((records, fetchNextPage) => {
          const recs = []

          records.forEach(record => {
            record.fields.id = record.id
            recs.push(record.fields)
          })
          this.convocatorias.push(...recs)

          fetchNextPage()
        })
    }
  },
  mounted() {
    this.obtenerDatos()
  }
})
</script>

<style scoped></style>
