<template>
  <div>
    <!-- BEGIN: Hoverable Table -->
  <AlumnosInteresadosCampusConvocatoria :convocatorias="convocatorias" :campuses="campuses"/>
    <!-- END: Hoverable Table -->
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import Airtable from 'airtable'
import { orderBy } from 'lodash'
import AlumnosInteresadosCampusConvocatoria from '@/views/administradores/components/estadisticas/AlumnosInteresadosCampusConvocatoria'
const base = new Airtable({ apiKey: 'patmSk0sdzVet2VQD.067b5939a8026476fb7e425b473c39417eecafad467e1ca526516af244112bdc' }).base('appYzmr5BAYcj9vHc')

export default defineComponent({
  components: { AlumnosInteresadosCampusConvocatoria },
  data() {
    return {
      convocatorias: [],
      campuses: []
    }
  },
  computed: {
    ...mapState('administradores/auth', ['administrador', 'usuario']),
    sumaCampusPorcentaje() {
      return (campus) => {
        let suma = 0
        this.convocatorias.forEach(convocatoria => {
          suma += campus[convocatoria.campos_porcentajeInteres_herramienta] ?? 0
        })

        return suma * 100
      }
    },
    estudiosOrdenados() {
      return orderBy(
        this.estudios,
        'orden_herramienta'
      )
    }
  },
  methods: {
    obtenerDatos() {
      this.campuses = []
      this.estudios = []

      base('campuses').select({
        filterByFormula: this.administrador.campuses_indice ? `AND(indice_herramienta='${this.administrador.campuses_indice}')` : ''
      }).eachPage((records, fetchNextPage) => {
        const recs = []

        records.forEach(record => {
          record.fields.id = record.id
          recs.push(record.fields)
        })
        this.campuses.push(...recs)

        fetchNextPage()
      })

      base('convocatorias').select({
        filterByFormula: this.administrador.convocatorias_indice ? `AND(indice_herramienta='${this.administrador.convocatorias_indice}', activo_herramienta=1)` : 'AND(activo_herramienta=1)'
      }).eachPage((records, fetchNextPage) => {
        const recs = []

        records.forEach(record => {
          record.fields.id = record.id
          recs.push(record.fields)
        })
        this.convocatorias.push(...recs)

        fetchNextPage()
      })
    }
  },
  mounted() {
    if (this.administrador.global) {
      this.obtenerDatos()
    }
  }
})
</script>

<style scoped>

</style>
