<template>
  <div class="lg:flex bg-slate-100 rounded-xl p-8 md:p-0 dark:bg-slate-800 w-full border-t rounded-none border-gray-400 mt-8" :class="convocatoria.activo ? '' : 'opacity-50'">
    <div class="w-full xl:w-1/4 flex mx-auto block">
      <img class="w-full my-auto" :src="convocatoria.logotipo" :alt="convocatoria.nombre" />
    </div>
    <div class="w-full xl:w-2/4 mx-auto pt-6 md:p-8 text-center md:text-left block">
      <div class="flex items-center h-full flex-col lg:flex-row">
        <p class="text-sky-500 dark:text-sky-400 lg:px-12">
          {{ convocatoria.descripcion }}
        </p>
      </div>
    </div>
    <div class="w-full xl:w-1/4 mx-auto pt-6 md:p-8 text-center md:text-left block">
      <div class="flex flex-wrap flex-row items-center justify-center h-full">
        <div class="w-full">
          <BotonDocumento
            v-for="documento in documentosOrdenados"
            :key="documento"
            @mostrar="toggleMostrarDocumento"
            :documento="documento" />
        </div>
      </div>
    </div>
  </div>

  <Documento
    v-show="documento.mostrar"
    :documento-codigo="documento.codigo"
    v-for="documento in documentosMostrados" :key="documento" class="mr-2 border-t mt-2"
  />

  <div class="grid grid-cols-12 gap-8 border-t mt-2">
    <a
      v-for="participacion in participacionesOrdenadasPorConvocatoria(convocatoria.indice)"
      :key="`c-${convocatoria.indice}-p-${participacion.indice}`"
      class="box col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-4 flex items-center justify-center hover:shadow-xl cursor-pointer text-center mt-6 px-6 text-gray-700"
      :href="`convocatorias/${convocatoria.codigo}/participaciones/${participacion.codigo}/editar`"
    >
      <div class="w-full py-8">
        <p class="text-lg mb-0">{{ participacion.participacionesTipos_nombre }}</p>
        <p class="text-2xl">"{{ participacion.nombre }}"</p>
        <p class="mb-2 text-xs">
          {{ participacion.descripcion }}
        </p>
        <p
          class="text-xs"
          v-for="(participante, key) in participacion.participantes_nombres"
          :key="`participacion-${participacion.indice}-${key}`"
        >
          {{ participante }}
        </p>
        <p v-if="participacion.comentarios && participacion.comentarios.length" class="text-lg mt-4">Comentarios: </p>
        <p v-if="participacion.comentarios && participacion.comentarios.length" class="text-left mt-2" v-html="nl(participacion.comentarios)">
        </p>
      </div>
    </a>
    <router-link
        v-if="(participacionesOrdenadasPorConvocatoria(convocatoria.indice) ? participacionesOrdenadasPorConvocatoria(convocatoria.indice).length < convocatoria.participacionesMaximasAlumno : true) && convocatoria.abierta"
        :to="{name: 'convocatorias.participaciones.create', params: {codigo: convocatoria.codigo}}"
        class="box bg-[rgba(0,0,0,.04)] col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-4 flex flex-row flex-wrap justify-center items-center hover:shadow-xl cursor-pointer text-center mt-6 px-6 text-gray-700"
        >
      <div class="w-full px-8 py-8">
        <PlusIcon v-if="convocatoria.abierta" class="w-24 h-24 text-center" />
        <div class="text-justify">
          <p class="text-2xl mb-2 text-center">{{ convocatoria.botonAgregar }}</p>
          <small v-html="nl(convocatoria.botonAgregarDescripcion)" />
        </div>
      </div>
    </router-link>
    <a
      href="javascript:"
        v-if="!convocatoria.abierta"
        class="box bg-[rgba(0,0,0,.04)] col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-4 flex flex-row flex-wrap justify-center items-center hover:shadow-xl cursor-pointer text-center mt-6 px-6 text-gray-700"
        data-toggle="modal"
        :data-target="`#modal-convocatoria_${convocatoria.indice}`">
      <div class="w-full px-8 py-8">
        <PlusIcon v-if="convocatoria.abierta" class="w-24 h-24 text-center" />
        <div class="text-justify">
          <p class="text-2xl mb-2 text-center">{{ convocatoria.botonAgregar }}</p>
          <small v-html="nl(convocatoria.botonAgregarDescripcion)" />
        </div>
      </div>
    </a>
  </div>

  <!-- BEGIN: Modal Content -->
  <div
    :id="`modal-convocatoria_${convocatoria.indice}`"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-10 text-center">
          La convocatoria aún no está abierta.
        </div>
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import { orderBy } from 'lodash'
import BotonDocumento from '@/views/alumnos/components/convocatorias/BotonDocumento.vue'
import Documento from '@/components/documentos/Documento'

export default defineComponent({
  components: { BotonDocumento, Documento },
  props: {
    convocatoria: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      documentos: [],
      participaciones: [],
      participacionesColaborando: []
    }
  },
  computed: {
    ...mapState('alumnos/auth', ['alumno']),
    documentosOrdenados() {
      return orderBy(
        this.documentos,
        'orden'
      )
    },
    documentosMostrados() {
      return this.documentos.filter(documento => documento.mostrar)
    },
    participacionesOrdenadasPorConvocatoria() {
      return (convocatoriaIndice) => {
        return orderBy(
          this.participaciones.filter(participacion => participacion.convocatorias_indice === convocatoriaIndice),
          'orden'
        )
      }
    },
    nl() {
      return (texto) => {
        return texto ? texto.replaceAll('\n', '<br />') : ''
      }
    }
  },
  methods: {
    ...mapActions('alumnos/documentos', ['obtenerDocumentosPorConvocatoriaIndice']),
    ...mapActions('alumnos/convocatorias', [
      'obtenerParticipacionesPorAlumnoIndice',
      'obtenerParticipacionesColaborandoPorAlumnoIndice'
    ]),
    toggleMostrarDocumento(documento) {
      documento.mostrar = !documento.mostrar
    }
  },
  mounted() {
    this.obtenerParticipacionesPorAlumnoIndice(this.alumno.indice)
      .then((participaciones) => {
        this.participaciones = participaciones
      })
    this.obtenerParticipacionesColaborandoPorAlumnoIndice(this.alumno.indice)
      .then((participacionesColaborando) => {
        this.participacionesColaborando = participacionesColaborando
      })
    this.obtenerDocumentosPorConvocatoriaIndice(this.$props.convocatoria.indice)
      .then((documentos) => {
        this.documentos = documentos
      })
  }
})
</script>

<style scoped>

</style>
