import LayoutColaboradores from '@/layouts/colaboradores/Main'
import validate from './validate'
import Dashboard from '@/views/colaboradores/dashboard/Main'
import Register from '@/views/colaboradores/register/Main'
import Login from '@/views/colaboradores/login/Main'

const routes = [
  {
    path: '/colaboradores',
    component: LayoutColaboradores,
    children: [
      // Dashboard
      {
        path: '',
        name: 'colab.dashboard',
        meta: { requiresAuth: true, validate },
        component: Dashboard
      }
    ]
  },
  // Auth
  {
    path: '/colaboradores/register',
    name: 'colab.register',
    meta: { requiresAuth: false, validate },
    component: Register
  },
  {
    path: '/colaboradores/login',
    name: 'colab.login',
    meta: { requiresAuth: false, validate },
    component: Login
  }
]

export default routes
