<template>
  <div>
    <Register>
      <template #inicio>
        <h2
          class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
        >
          Registro para Alumnos
        </h2>
      </template>
      <template #form>
        <div class="intro-x mt-8">
          <input
            v-model="validate.email.$model"
            type="text"
            class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
            :class="{ 'border-theme-5': validate.email.$error }"
            placeholder="Correo"
          />
          <template v-if="validate.email.$error">
            <div
              v-for="(error, index) in validate.email.$errors"
              :key="index"
              class="text-theme-5 mt-2"
            >
              {{ error.$message }}
            </div>
          </template>
          <input
            v-model="validate.password.$model"
            type="password"
            class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
            :class="{ 'border-theme-5': validate.password.$error }"
            placeholder="Contraseña"
            @keydown.enter="register"
          />
          <template v-if="validate.password.$error">
            <div
              v-for="(error, index) in validate.password.$errors"
              :key="index"
              class="text-theme-5 mt-2"
            >
              {{ error.$message }}
            </div>
          </template>
          <template v-if="registering.errors">
            <div
              v-for="(error, index) in registering.errors"
              :key="index"
              class="text-theme-5 mt-6"
            >
              {{ error }}
            </div>
          </template>
          <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
            <button
              :disabled="registrando"
              class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
              @click="register"
            >
              Registrar
            </button>
            <router-link
              :to="{name: 'login'}"
              tag="button"
              class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
            >
              Iniciar Sesión
            </router-link>
          </div>
          <div
            class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
          >
            <router-link :to="{name: 'colab.register'}">
              ¿Eres colaborador?
            </router-link>
          </div>

          <!-- BEGIN: Failed Notification Content -->
          <div
            id="failed-notification-content"
            class="toastify-content hidden flex"
          >
            <XCircleIcon class="text-theme-5" />
            <div class="ml-4 mr-4">
              <div class="font-medium">Registro Incorrecto</div>
              <div class="text-gray-600 mt-1">
                Campos incompletos.
              </div>
            </div>
          </div>
          <!-- END: Failed Notification Content -->
          <!-- BEGIN: Success Notification Content -->
          <div
            id="success-notification-content"
            class="toastify-content hidden flex"
          >
            <CheckCircleIcon class="text-theme-9" />
            <div class="ml-4 mr-4">
              <div class="font-medium">Registro exitoso</div>
              <div class="text-gray-600 mt-1">
                En un momento serás redirigido.
              </div>
            </div>
          </div>
        </div>
      </template>
    </Register>
  </div>
</template>

<script>
import { reactive, defineComponent, onMounted, toRefs, ref } from 'vue'
import {
  required,
  minLength,
  email,
  helpers
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'
import { fbAuth } from '@/plugins/firebase'
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import Register from '@/components/form/Login.vue'
// import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'

export default defineComponent({
  components: {
    Register
    // DarkModeSwitcher
  },
  setup() {
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })

    const registrando = ref(false)
    let domain = null

    if (window.location.hostname === 'vibrart-2022.web.app' ||
      window.location.hostname === 'vibrart-2022.firebaseapp.com') {
      domain = 'https://vibrart-2022--dev-huo9q2kv.web.app'
    } else if (window.location.hostname === 'vibrart-2022--dev-huo9q2kv.web.app') {
      domain = 'https://vibrart-2022--dev-huo9q2kv.web.app'
    } else {
      domain = window.location.protocol + '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
    }

    const formData = reactive({
      email: '',
      password: ''
    })

    const registering = ref({
      errors: []
    })

    const rules = {
      email: {
        email: helpers.withMessage('Es necesaria una dirección de correo válida', email),
        required: helpers.withMessage('El campo es obligatorio', required)
      },
      password: {
        minLength: helpers.withMessage('Tu contraseña debe ser de al menos 8 caracteres', minLength(8)),
        required: helpers.withMessage('El campo es obligatorio', required)
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))
    const register = () => {
      registrando.value = true
      validate.value.$touch()

      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {
        createUserWithEmailAndPassword(fbAuth, formData.email, formData.password)
          .then(() => {
            sendEmailVerification(fbAuth.currentUser, { url: domain })
              .then(() => {
                registrando.value = false
                Toastify({
                  node: cash('#success-notification-content')
                    .clone()
                    .removeClass('hidden')[0],
                  duration: 1000,
                  newWindow: true,
                  close: true,
                  gravity: 'top',
                  position: 'right',
                  stopOnFocus: true
                }).showToast()
              })
          })
          .catch((error) => {
            if (error.code === 'auth/email-already-in-use') {
              registering.value.errors.push('El correo ya está siendo utilizado')
            }
            registrando.value = false
          })
      }
    }

    return {
      registering,
      registrando,
      formData,
      validate,
      register
    }
  }
})
</script>
