<template>
  <router-view />

  <img v-if="validarRuta" src="./assets/images/Vlive-Media-Logo.png" class="vlivemedia-logo hidden lg:block" alt="Vlive Media - Logo">
  <img v-if="validarRuta" src="./assets/images/Vlive-Media-Logo.png" class="vlivemedia-logo-mobile mx-auto lg:hidden block" alt="Vlive Media - Logo">

  <div class="app-version" @click="updatePage" style="z-index: 10001">
    v{{ appVersion }}
  </div>
</template>

<script>
import packageInfo from '../package.json'
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  setup() {
    if (window.top !== window.self) {
      top.location.href = document.location.href
      alert('SITIO NO OFICIAL DE FESTIVAL VIBRART. NO INGRESES NINGÚN DATO.')
      return
    }

    const route = useRoute()
    const currentVersion = packageInfo.version
    const updatePage = () => {
      window.location.reload()
    }

    const validarRuta = computed(() => {
      return !(route.fullPath.indexOf('register') > -1 || route.fullPath.indexOf('login') > -1)
    })

    return {
      validarRuta,
      appVersion: currentVersion,
      updatePage
    }
  }
})
</script>

<style>
html, body {
  overflow-x: hidden;
}

.app-version {
  color: rgba(0,0,0, .5);
  position: fixed;
  bottom: 3px;
  right: 3px;
  font-size: 12px;
  line-height: 12px;
}

.app-version:hover {
  cursor: pointer;
}

.vlivemedia-logo-mobile {
  margin-top: 20px;
  width: 100px;
  opacity: .5;
}
</style>
