<template>
  <div
    class="flex flex-col sm:flex-row items-center p-5 border-gray-200"
  >
    <h2 class="font-medium text-base mx-auto">Estadísticas de {{ convocatoria.nombre_herramienta }}</h2>
  </div>
  <div id="hoverable-table" class="p-5 pt-0">
    <div class="preview">
      <div class="overflow-x-auto">
        <table class="table">
          <thead>
          <tr>
            <th
              class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
            >
              Campus
            </th>
            <th
              class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
            >
              Cantidad de Alumnos
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="campus in campusesAutorizados"
            :key="`campus-${campus.indice_herramienta}`"
            class="hover:bg-gray-200"
          >
            <td class="border">

              <router-link v-if="campus[convocatoria.campos_cantidadInteres_herramienta]!==0" :to="{name: 'admin.stats.intereses.campus', params: {campus: campus.codigo_herramienta, convocatoria: convocatoria.codigo_herramienta}}">
                {{ campus.nombre_herramienta }}
              </router-link>
              <p v-else>{{ campus.nombre_herramienta }}</p>
            </td>
            <td class="border">

              <router-link v-if="campus[convocatoria.campos_cantidadInteres_herramienta]!==0" :to="{name: 'admin.stats.intereses.campus', params: {campus: campus.codigo_herramienta, convocatoria: convocatoria.codigo_herramienta}}">
                {{
                  campus[convocatoria.campos_cantidadInteres_herramienta]
                }}
              </router-link>
              <p v-else>{{campus[convocatoria.campos_cantidadInteres_herramienta]}}</p>
            </td>
          </tr>
          <tr>
            <th class="border">Total</th>
            <th class="border">{{ sumaConvocatoria(convocatoria) }}</th>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { orderBy } from 'lodash'
import { mapState } from 'vuex'
export default defineComponent({
  name: 'InteresesCampus',
  props: {
    convocatoria: {
      type: Object,
      required: true
    },
    campuses: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('administradores/auth', ['administrador', 'usuario']),
    campusesAutorizados() {
      return orderBy(
        this.$props.campuses.filter(campus => {
          return this.administrador.campuses_indice
            ? this.administrador.campuses_indice === campus.indice_herramienta
            : true
        }),
        'orden_herramienta'
      )
    },
    sumaConvocatoria() {
      return convocatoria => {
        let suma = 0
        this.campusesAutorizados.forEach(camp => {
          suma += camp[convocatoria.campos_cantidadInteres_herramienta] ?? 0
        })

        return suma
      }
    }

  }
})
</script>

<style scoped>

</style>
