<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto ml-1" v-if="!datosFaltantes.length && !fbParticipacion.indice">
        Cargando participación...
      </h2>
      <h2 class="intro-y text-lg font-medium mr-auto ml-1" v-else>
        {{ convocatoria.nombre }} - {{ participacion.nombre }}
      </h2>
    </div>

    <div v-if="datosFaltantes.length">
      <SeccionDatos>
        <template #titulo>
          Información Faltante
        </template>

        <template #default>
          <DatoGroup
            v-for="participacionDato in datosFaltantes"
            :key="`${participacionTipoIndice}-participanteTipo-${participacionDato.indice}`"
          >
            <Dato>
              <template #nombre>
                {{ participacionDato.nombre }} <span v-if="participacionDato.opcional">- (Opcional)</span>
              </template>
              <template #descripcion>
                {{ participacionDato.descripcion }}
              </template>
              <template #dato>
                <Component
                  :is="participacionDato.tipo"
                  :extensiones="participacionDato.extensiones"
                  :tabla="participacionDato.tablas"
                  v-model="participacion.datos[participacionDato.indice].valor"
                  @titulo="(val) => datos[participacionDato.indice].titulo = val"
                />
              </template>
            </Dato>
          </DatoGroup>
        </template>
      </SeccionDatos>
      <div class="container text-right">
        <button
          class="btn btn-primary w-36 ml-2"
          :disabled="actualizando"
          @click="actualizarParticipacion"
        >
          {{ actualizando ? 'Actualizando...' : 'Actualizar'}}
        </button>
      </div>
    </div>
    <div v-else-if="!datosFaltantes.length && fbParticipacion.indice">
      <h1 class="intro-y text-xl font-medium mr-auto ml-1">Tu participación se encuentra completa. ¡Espera noticias!</h1>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import { orderBy } from 'lodash'
import SeccionDatos from '@/views/alumnos/convocatorias/participaciones/crear/partials/SeccionDatos.vue'
import DatoGroup from '@/views/alumnos/convocatorias/participaciones/crear/partials/form/DatoGroup.vue'
import Dato from '@/views/alumnos/convocatorias/participaciones/crear/partials/form/Dato.vue'

// Datos Tipos
import Archivo from '@/views/alumnos/convocatorias/participaciones/crear/partials/tipos/Archivo.vue'
import Numero from '@/views/alumnos/convocatorias/participaciones/crear/partials/tipos/Numero.vue'
import SeleccionUnica from '@/views/alumnos/convocatorias/participaciones/crear/partials/tipos/SeleccionUnica.vue'
import Texto from '@/views/alumnos/convocatorias/participaciones/crear/partials/tipos/Texto.vue'
import TextoArea from '@/views/alumnos/convocatorias/participaciones/crear/partials/tipos/TextoArea.vue'

// AT / FB
import { fbStorage, fbFunctions } from '@/plugins/firebase.js'
import { getDownloadURL, uploadBytes, ref as storageRef } from 'firebase/storage'
import Airtable from 'airtable'
import { httpsCallable } from 'firebase/functions'

const airtable = new Airtable({ apiKey: 'patmSk0sdzVet2VQD.067b5939a8026476fb7e425b473c39417eecafad467e1ca526516af244112bdc' })
const base = airtable.base('appYzmr5BAYcj9vHc')
const updateRecord = httpsCallable(fbFunctions, 'at-updateRecord')

export default defineComponent({
  components: {
    Dato,
    DatoGroup,
    SeccionDatos,
    Archivo,
    Numero,
    SeleccionUnica,
    Texto,
    TextoArea
  },
  data() {
    return {
      actualizando: false,
      datos: {},
      participacionTipoIndice: null,
      participacionesDatos: [],
      participacion: {
        nombre: '',
        datos: {},
        participantes: []
      },
      nuevaParticipacion: {},
      participacionAirtable: {},
      fbParticipacion: {},
      convocatoria: {
        nombre: '',
        terminos: ''
      }
    }
  },

  computed: {
    ...mapState('alumnos/auth', ['alumno']),
    participacionesDatosOrdenados() {
      return orderBy(
        this.participacionesDatos,
        'orden'
      )
    },
    datosFaltantes() {
      return this.participacionesDatosOrdenados.filter((participacionDato) => {
        return !this.fbParticipacion[participacionDato.clave]
      })
    }
  },

  methods: {
    ...mapActions('alumnos/convocatorias', [
      'obtenerConvocatoriaPorCodigo',
      'obtenerParticipacionPorCodigo'
    ]),
    ...mapActions('alumnos/convocatorias', ['obtenerParticipacionesDatosPorParticipacionTipoIndice']),
    actualizarParticipacion() {
      this.actualizando = true

      if (!this.validarParticipacion()) {
        this.actualizando = false
        console.error('No se pudo validar la participación')
      }

      base('participaciones').find(this.fbParticipacion.indice).then(async (participacionAirtable) => {
        this.participacionAirtable = participacionAirtable

        await this.subirArchivos()
        await updateRecord({ table: 'participaciones', id: this.participacionAirtable.id })
        window.location.reload()
      })
    },
    validarParticipacion() {
      this.datosFaltantes.forEach(participacionDato => {
        if (this.participacion.datos[participacionDato.indice].tipo !== 'Archivo') {
          if (this.participacion.datos[participacionDato.indice].relacionar) {
            this.nuevaParticipacion[participacionDato.llenado] = [this.participacion.datos[participacionDato.indice].valor]
          } else {
            this.nuevaParticipacion[participacionDato.llenado] = this.participacion.datos[participacionDato.indice].valor
          }
        } else {
          this.nuevaParticipacion[participacionDato.llenado] = this.participacion.datos[participacionDato.indice].valor
        }
      })

      return true
    },
    async subirArchivos() {
      const fields = {}
      const partAt = this.participacionAirtable
      const propsDatos = this.participacion.datos

      for (let i = 0; i < this.datosFaltantes.length; i++) {
        const partDato = this.datosFaltantes[i]

        if (propsDatos[partDato.indice].tipo === 'Archivo' && this.participacion.datos[partDato.indice].valor) {
          const extension = this.participacion.datos[partDato.indice].valor.name.split('.').pop()
          const nombreArchivo = `${partAt.fields.codigo_herramienta}_${partDato.clave}.${extension}`
          const docRef = storageRef(fbStorage, `/participaciones/${this.convocatoria.codigo}/${nombreArchivo}`)
          await uploadBytes(docRef, propsDatos[partDato.indice].valor)
          const link = await getDownloadURL(docRef)
          if (!partDato.noSubirAirtable) {
            fields[partDato.llenado] = [
              {
                url: link,
                filename: nombreArchivo
              }
            ]
          }
          fields[`${partDato.clave}Firebase_valorManual`] = link
        } else {
          fields[partDato.llenado] = this.participacion.datos[partDato.indice].valor
        }
      }

      await base('participaciones').update(partAt.id, fields)
    }
  },

  async mounted() {
    this.obtenerConvocatoriaPorCodigo(this.$route.params.convocatoria).then((convocatoria) => {
      this.convocatoria = convocatoria

      this.obtenerParticipacionPorCodigo(this.$route.params.participacion).then((participacion) => {
        this.fbParticipacion = participacion
        this.participacion.nombre = participacion.nombre
        this.participacionTipoIndice = participacion.participacionesTipos_indice

        this.obtenerParticipacionesDatosPorParticipacionTipoIndice(participacion.participacionesTipos_indice)
          .then((participacionesDatos) => {
            this.participacionesDatos = participacionesDatos

            participacionesDatos.forEach(participacionDato => {
              let valorPredeterminado = null

              switch (participacionDato.tipo) {
                case 'Texto' || 'TextoArea' || 'SeleccionUnica':
                  valorPredeterminado = ''
                  break
                case 'Numero':
                  valorPredeterminado = 0
                  break

                default:
                  valorPredeterminado = ''
              }

              this.datos[participacionDato.indice] = {
                orden: participacionDato.orden,
                tipo: participacionDato.tipo,
                nombre: participacionDato.nombre,
                llenado: participacionDato.llenado,
                clave: participacionDato.clave,
                valor: valorPredeterminado,
                titulo: '',
                opcional: participacionDato.opcional,
                noSubirAirtable: participacionDato.noSubirAirtable,
                relacionar: !!participacionDato.tablas
              }

              this.datosFaltantes.forEach(datoFaltante => {
                this.participacion.datos[datoFaltante.indice] = datoFaltante
              })
            })
          })
      })
    })
  }
})
</script>
