import { fbFirestore } from '@/plugins/firebase'
import { collection, onSnapshot, query, where, limit } from 'firebase/firestore'

export function obtenerDocumentoPorCodigo({ rootState, commit }, documentoCodigo) {
  return new Promise((resolve, reject) => {
    try {
      const q = query(
        collection(fbFirestore, 'documentos'),
        where('codigo', '==', documentoCodigo),
        limit(1)
      )

      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach(doc => {
          resolve(doc.data())
        })
      })
    } catch (e) {
      reject(e)
    }
  })
}

export function obtenerDocumentosPorConvocatoriaIndice({ rootState, commit }, convocatoriaIndice) {
  return new Promise((resolve, reject) => {
    try {
      const q = query(
        collection(fbFirestore, 'documentos'),
        where('convocatorias_indice', '==', convocatoriaIndice),
        limit(1)
      )

      onSnapshot(q, (querySnapshot) => {
        const documentos = []
        querySnapshot.forEach(doc => {
          const docum = doc.data()
          docum.mostrar = false
          documentos.push(docum)
        })

        resolve(documentos)
      })
    } catch (e) {
      reject(e)
    }
  })
}

export function obtenerDocumentos({ rootState, commit }) {
  return new Promise((resolve) => {
    if (rootState.alumnos.documentos.documentos.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'documentos' })

    const q = query(collection(fbFirestore, 'documentos'))
    onSnapshot(q, (querySnapshop) => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(() => commit('AGREGAR', { table: 'documentos', payload: change.doc.data() }), i)
            break
          }

          case 'modified': {
            setTimeout(() => commit('EDITAR', { table: 'documentos', payload: change.doc.data() }), i)
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'documentos', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerDocumentosNivel01PorDocumentoIndice({ rootState, commit }, documentoIndice) {
  return new Promise((resolve) => {
    if (rootState.alumnos.documentos.documentos.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'documentosNiveles01' })

    const q = query(
      collection(fbFirestore, 'documentosNiveles01'),
      where('documentos_indice', '==', documentoIndice)
    )
    onSnapshot(q, (querySnapshop) => {
      const docs = []

      querySnapshop.forEach(doc => {
        docs.push(doc.data())
      })

      resolve(docs)
    })
  })
}

export function obtenerDocumentosNivel02PorDocumentoIndice({ rootState, commit }, documentoIndice) {
  return new Promise((resolve) => {
    if (rootState.alumnos.documentos.documentos.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'documentosNiveles02' })

    const q = query(
      collection(fbFirestore, 'documentosNiveles02'),
      where('documentos_indice', '==', documentoIndice)
    )
    onSnapshot(q, (querySnapshop) => {
      const docs = []

      querySnapshop.forEach(doc => {
        docs.push(doc.data())
      })

      resolve(docs)
    })
  })
}

export function obtenerDocumentosNivel03PorDocumentoIndice({ rootState, commit }, documentoIndice) {
  return new Promise((resolve) => {
    if (rootState.alumnos.documentos.documentos.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'documentosNiveles03' })

    const q = query(
      collection(fbFirestore, 'documentosNiveles03'),
      where('documentos_indice', '==', documentoIndice)
    )
    onSnapshot(q, (querySnapshop) => {
      const docs = []

      querySnapshop.forEach(doc => {
        docs.push(doc.data())
      })

      resolve(docs)
    })
  })
}
