export function AGREGAR(state, { table, payload }) {
  state[table].push(payload)
}

export function EDITAR(state, { table, payload }) {
  const index = state[table].findIndex(sec => sec.indice === payload.indice)
  state[table][index] = payload
}

export function BORRAR(state, { table, payload }) {
  const index = state[table].findIndex(sec => sec.indice === payload.indice)
  state[table][index] = {}
}

export function LIMPIAR(state, { table }) {
  state[table] = []
}

export function ASIGNAR(state, { table, payload }) {
  state[table] = payload
}
