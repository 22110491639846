<template>
  <div>
    <!-- Titulo -->
    <div class="px-5 my-10">
      <div class="font-medium text-center text-lg">
        <slot name="titulo"></slot>
      </div>
      <div class="text-gray-600 text-center mt-2">
        <slot name="descripcion"></slot>
      </div>
    </div>

    <!-- Info -->
    <div class="px-5 border-t border-gray-200 dark:border-dark-5">
      <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
        <slot name="contenido"></slot>
        <slot></slot>
        <div
          class="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-5"
        >
          <slot name="acciones"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Paso'
})
</script>

<style scoped>

</style>
