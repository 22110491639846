<template>
  <VibrartSelect
    v-model="valor"
    :placeholder="placeholder"
    :items="registrosOrdenados"
    @change="$emit('update:modelValue', valor); $emit('titulo', titulo)"
  />
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { orderBy } from 'lodash'
import VibrartSelect from '@/components/vibrat-select/vibrart-select.vue'

export default defineComponent({
  name: 'SeleccionUnica',
  components: { VibrartSelect },
  props: {
    modelValue: {
      type: String
    },
    placeholder: {
      type: String
    },
    tabla: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      valor: this.$props.modelValue,
      registros: []
    }
  },

  computed: {
    registrosOrdenados() {
      return orderBy(
        this.registros,
        'orden'
      )
    },
    titulo() {
      return this.valor ? this.registros.find(registro => registro.indice === this.valor).nombre : ''
    }
  },

  methods: {
    ...mapActions('alumnos/convocatorias', ['obtenerTablaParaDatosConvocatoria'])
  },

  mounted() {
    this.obtenerTablaParaDatosConvocatoria(this.$props.tabla)
      .then(registros => {
        this.registros = registros
      })
  }
})
</script>

<style scoped>

</style>
