import { fbAuth, fbFirestore } from '@/plugins/firebase'
import { collection, query, where, limit, onSnapshot } from 'firebase/firestore'
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged
} from 'firebase/auth'
import { useRouter } from 'vue-router'
import { orderBy } from 'lodash'

const menuCampuses = {
  icon: 'BriefcaseIcon',
  title: 'Campus',
  subMenu: [
    {
      icon: 'BookmarkIcon',
      pageName: 'admin.stats.campus.interesesConvocatoria',
      title: 'Intereses por Convocatoria'
    },
    {
      icon: 'BookOpenIcon',
      pageName: 'admin.stats.campus.alumnosPorEstudios',
      title: 'Alumnos por Nivel de Estudios'
    }
  ]
}

const menuConvocatorias = {
  icon: 'ClipboardIcon',
  title: 'Convocatoria',
  subMenu: [
    {
      icon: 'BriefcaseIcon',
      pageName: 'admin.stats.convocatoria.interesesCampus',
      title: 'Intereses por Campus'
    }
  ]
}

const menuGlobal = {
  icon: 'GlobeIcon',
  title: 'Global',
  subMenu: [
    {
      icon: 'UsersIcon',
      pageName: 'admin.stats.global.alumnosRegistradosCampus',
      title: 'Alumnos por Campus'
    },
    {
      icon: 'UsersIcon',
      pageName: 'admin.stats.global.alumnosRegistradosCampusEstudios',
      title: 'Alumnos por Campus y Nivel de Estudio'
    },
    {
      icon: 'UsersIcon',
      pageName: 'admin.stats.global.alumnosRegistradosComunidad',
      title: 'Alumnos por Comunidad'
    },
    {
      icon: 'UsersIcon',
      pageName: 'admin.stats.global.alumnosRegistradosConvocatoria',
      title: 'Alumnos por Convocatoria'
    },
    {
      icon: 'UsersIcon',
      pageName: 'admin.stats.global.alumnosRegistradosEstudios',
      title: 'Alumnos por Nivel de Estudios'
    },
    {
      icon: 'UsersIcon',
      pageName: 'admin.stats.global.alumnosInteresadosCampusConvocatoria',
      title: 'Interesados por Campus y Convocatoria'
    }
  ]
}

export function login({ commit }, { email, password }) {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(fbAuth, email, password)
      .then(resolve)
      .catch(reject)
  })
}

export function logout() {
  signOut(fbAuth).then(() => {
    // window.location.reload()
  })
}

function obtenerMenuFinalistas(administrador, convocatorias) {
  const subMenuFinalistas = {
    icon: 'CheckSquareIcon',
    title: 'Finalistas',
    subMenu: [],
    sort: 5
  }

  convocatorias = orderBy(
    convocatorias.filter(convocatoria =>
      administrador.finalistasConvocatorias_indices.includes(
        convocatoria.indice
      )
    ),
    'orden'
  )

  convocatorias.forEach(convocatoria => {
    subMenuFinalistas.subMenu.push({
      icon: 'SlackIcon',
      title: convocatoria.nombre,
      pageName: 'admin.finalistas.show',
      sort: convocatoria.orden,
      params: {
        convocatoria: convocatoria.indice
      }
    })
  })

  return subMenuFinalistas
}

function obtenerMenuSeleccion(administrador, convocatorias) {
  const subMenuSeleccion = {
    icon: 'CheckSquareIcon',
    title: 'Selección',
    subMenu: [],
    sort: 3
  }

  convocatorias = orderBy(
    convocatorias.filter(convocatoria =>
      administrador.seleccionConvocatorias_indices.includes(convocatoria.indice)
    ),
    'orden'
  )

  convocatorias.forEach(convocatoria => {
    subMenuSeleccion.subMenu.push({
      icon: 'SlackIcon',
      title: convocatoria.nombre,
      pageName: 'admin.seleccion.show',
      sort: convocatoria.orden,
      params: {
        convocatoria: convocatoria.indice
      }
    })
  })

  return subMenuSeleccion
}

/* function obtenerMenuCalificacion(administrador, convocatorias) {
  const subMenuCalificacion = {
    icon: 'HomeStats',
    title: 'Calificación',
    subMenu: [],
    sort: 4
  }

  convocatorias = orderBy(convocatorias, 'orden')

  convocatorias.forEach(convocatoria => {
    subMenuCalificacion.subMenu.push({
      title: convocatoria.nombre,
      pageName: 'admin.calificacion.show',
      sort: convocatoria.orden,
      params: {
        convocatoria: convocatoria.indice
      }
    })
  })

  return subMenuCalificacion
} */

function obtenerMenuEstadisticas(administrador) {
  const subMenuEstadisticas = {
    icon: 'BarChartIcon',
    title: 'Estadisticas',
    subMenu: [],
    sort: 2
  }

  if (administrador.global) {
    subMenuEstadisticas.subMenu.push(menuCampuses)
    subMenuEstadisticas.subMenu.push(menuConvocatorias)
    subMenuEstadisticas.subMenu.push(menuGlobal)
  } else if (administrador.campuses_indice) {
    subMenuEstadisticas.subMenu.push(menuCampuses)
  } else if (administrador.convocatorias_indice) {
    subMenuEstadisticas.subMenu.push(menuConvocatorias)
  }

  return subMenuEstadisticas
}

function obtenerMenuActualizacion() {
  return {
    icon: 'DatabaseIcon',
    pageName: 'admin.bridge',
    title: 'Bridge',
    sort: 100
  }
}

function obtenerMenuAcciones() {
  return {
    icon: 'ZapIcon',
    title: 'Acciones',
    subMenu: [
      {
        icon: 'MailIcon',
        title: 'Recordatorio Cierre',
        pageName: 'admin.actions.reminders.index'
      }
    ],
    sort: 99
  }
}

export function generarMenu({ rootState, dispatch, commit }, administrador) {
  if (rootState.administradores.menuAsignado) {
    return
  }

  const subMenuEstadisticas = obtenerMenuEstadisticas(administrador)
  const subMenuActualizacion = obtenerMenuActualizacion()
  const subMenuAcciones = obtenerMenuAcciones()

  if (subMenuEstadisticas.subMenu.length) {
    commit('AGREGAR_ITEM_MENU', subMenuEstadisticas)
  }

  if (
    !rootState.administradores.actualizacionOtorgada &&
    administrador.actualizacion
  ) {
    commit('AGREGAR_ITEM_MENU', subMenuAcciones)
    commit('AGREGAR_ITEM_MENU', subMenuActualizacion)
    commit('ACTUALIZACION_OTORGADA', true)
  }

  commit('MENU_ASIGNADO', true)

  dispatch(
    'administradores/convocatorias/obtenerConvocatoriasSinReactividad',
    {},
    { root: true }
  ).then(convocatorias => {
    const subMenuSeleccion = obtenerMenuSeleccion(administrador, convocatorias)
    const subMenuFinalistas = obtenerMenuFinalistas(
      administrador,
      convocatorias
    )

    if (subMenuSeleccion.subMenu.length) {
      commit('AGREGAR_ITEM_MENU', subMenuSeleccion)
    }
    if (subMenuFinalistas.subMenu.length) {
      commit('AGREGAR_ITEM_MENU', subMenuFinalistas)
    }
  })
}

export function asignarUsuario({ dispatch, commit }, { fbUser, tecUser }) {
  dispatch('generarMenu', tecUser)
  // commit('AGREGAR_MENU', tecUser)
  commit('ASIGNAR_USUARIO', fbUser)
  commit('ASIGNAR_ADMINISTRADOR', tecUser)
  commit('CAMBIAR_ACCESO', true)
}

export function handleAuthStateChange({ commit }) {
  const router = useRouter()
  onAuthStateChanged(fbAuth, fbUser => {
    if (fbUser) {
      commit('ASIGNAR_USUARIO', fbUser)
      const q = query(
        collection(fbFirestore, 'administradores'),
        where('usuario_indice', '==', fbUser.uid),
        limit(1)
      )

      onSnapshot(q, querySnapshot => {
        if (!querySnapshot.docs.length) {
          signOut(fbAuth).then(() => {
            alert(
              'Tu cuenta todavía no tiene los permisos de administrador asignados. Por favor envía un correo a diego@vlivemedia.com para actualizar tu usuario.'
            )
            console.log('No existe el administrador.')
          })
        }

        querySnapshot.forEach(doc => {
          commit('ASIGNAR_ADMINISTRADOR', doc.data())
          commit('CAMBIAR_ACCESO', true)
          router.push({ name: 'admin.dashboard' })
        })

        querySnapshot.docChanges().forEach(change => {
          if (change.type !== 'modified' || change.type !== 'added') {
            return
          }
          commit('ASIGNAR_ADMINISTRADOR', change.doc.data())
          commit('CAMBIAR_ACCESO', true)
        })
      })
    } else {
      commit('ASIGNAR_USUARIO', null)
      commit('ASIGNAR_ADMINISTRADOR', null)
      commit('CAMBIAR_ACCESO', false)

      router.push({ name: 'admin.login' })
    }
  })
}
