<template>
  <input
    v-model="valor"
    type="text"
    class="form-control"
    :placeholder="placeholder"
    @input="$emit('update:modelValue', valor)"
  />
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Texto',

  props: {
    modelValue: {
      type: String
    },
    placeholder: {
      type: String
    }
  },

  data() {
    return {
      valor: this.$props.modelValue
    }
  }
})
</script>

<style scoped>

</style>
