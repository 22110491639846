// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getAuth, connectAuthEmulator, GoogleAuthProvider } from 'firebase/auth'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getRemoteConfig } from 'firebase/remote-config'
import { getStorage, connectStorageEmulator } from 'firebase/storage'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAGs1tOWGE4qdNzs0cbnlQ4wp_pYvVD5-U',
  authDomain: 'vibrart-2022.firebaseapp.com',
  projectId: 'vibrart-2022',
  storageBucket: 'vibrart-2022.appspot.com',
  messagingSenderId: '1075182260930',
  appId: '1:1075182260930:web:f4954df34a9dfc64f9a15b',
  measurementId: 'G-R918X643T5'
}

// Initialize Firebase
const fbApp = initializeApp(firebaseConfig)
const fbAnalytics = getAnalytics(fbApp)
const fbAuth = getAuth(fbApp)
const googleProvider = new GoogleAuthProvider()
const fbFirestore = getFirestore(fbApp)
const fbFunctions = getFunctions(fbApp)
const fbRemoteConfig = getRemoteConfig(fbApp)
const fbStorage = getStorage(fbApp)

googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email')

if (location.hostname === 'localhost') {
  console.log("Locally, let's use the emulators")
  connectFirestoreEmulator(fbFirestore, 'localhost', 8078)
  connectFunctionsEmulator(fbFunctions, 'localhost', 5001)
  connectAuthEmulator(fbAuth, 'http://localhost:9099')
  connectStorageEmulator(fbStorage, 'localhost', 9199)
}

export {
  fbApp,
  fbAnalytics,
  fbAuth,
  fbFirestore,
  fbFunctions,
  fbRemoteConfig,
  fbStorage,
  googleProvider
}
