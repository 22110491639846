export function ASIGNAR_USUARIO(state, payload) {
  state.usuario = payload
}

export function ASIGNAR_COLABORADOR(state, payload) {
  state.colaborador = payload
}

export function CAMBIAR_ACCESO(state, payload) {
  state.loggedIn = payload
}
