<template>
  <div>
    <!-- BEGIN: Hoverable Table -->
    <AlumnosRegistradosCampus :campuses="campuses"/>

    <!-- END: Hoverable Table -->
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import Airtable from 'airtable'
import { orderBy } from 'lodash'
import AlumnosRegistradosCampus from '@/views/administradores/components/estadisticas/AlumnosRegistradosCampus'
const base = new Airtable({ apiKey: 'patmSk0sdzVet2VQD.067b5939a8026476fb7e425b473c39417eecafad467e1ca526516af244112bdc' }).base('appYzmr5BAYcj9vHc')

export default defineComponent({
  components: { AlumnosRegistradosCampus },
  data() {
    return {
      campuses: []
    }
  },
  computed: {
    ...mapState('administradores/auth', ['administrador', 'usuario']),
    estudiosOrdenados() {
      return orderBy(
        this.estudios,
        'orden_herramienta'
      )
    }
  },
  methods: {
    obtenerDatos() {
      this.campuses = []
      this.estudios = []
      const filter = this.administrador.campuses_indice ? `AND(indice_herramienta='${this.administrador.campuses_indice}')` : ''

      base('campuses').select({
        filterByFormula: filter
      }).eachPage((records, fetchNextPage) => {
        const recs = []

        records.forEach(record => {
          record.fields.id = record.id
          recs.push(record.fields)
        })
        this.campuses.push(...recs)
        fetchNextPage()
      })
    }
  },
  mounted() {
    if (this.administrador.global) {
      this.obtenerDatos()
    }
  }
})
</script>

<style scoped>

</style>
