import auth from './auth'
import bridge from './bridge'
import convocatorias from './convocatorias'
import documentos from './documentos'
import menu from './menu'

export default {
  namespaced: true,
  state: {
    actualizacionOtorgada: false,
    menuAsignado: false,
    menu
  },
  modules: {
    auth,
    bridge,
    convocatorias,
    documentos
  }
}
