<template>
  <div class="container flex-shrink-0 my-4 col-span-12">
    <div class="grid grid-cols-12 gap-4">
      <div
        class="intro-y col-span-12 lg:col-span-4"
      >
        <div class="flex justify-center items-center align-middle h-full">
          <h3 class="text-2xl">
            <slot name="titulo" />
          </h3>
        </div>
      </div>

      <div
        class="intro-y col-span-12 lg:col-span-8"
      >
        <div
          class="intro-y box p-8"
        >
          <slot />

          <div
            v-if="$slots.acciones"
            class="grid grid-cols-12 mt-4"
          >
            <div class="col-span-12 text-center justify-center">
              <slot name="acciones"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SeccionDatos'
})
</script>

<style scoped>

</style>
