<template>
  <SeccionDatos>
    <template #titulo>
      Administrador
    </template>
    <DatoGroup>
      <!-- nombres -->
      <Dato v-if="participacion.administrador.administradorPrimerNombre_valorManual">
        <template #nombre>Primer Nombre</template>
        <template #dato>{{ participacion.administrador.administradorPrimerNombre_valorManual }}</template>
      </Dato>
      <Dato v-if="participacion.administrador.administradorSegundoNombre_valorManual">
        <template #nombre>Segundo Nombre</template>
        <template #dato>{{ participacion.administrador.administradorSegundoNombre_valorManual }}</template>
      </Dato>
      <!-- /nombres -->

      <!-- apellidos -->
      <Dato v-if="participacion.administrador.administradorApellidoPaterno_valorManual">
        <template #nombre>Apellido Paterno</template>
        <template #dato>{{ participacion.administrador.administradorApellidoPaterno_valorManual }}</template>
      </Dato>
      <Dato v-if="participacion.administrador.administradorApellidoMaterno_valorManual">
        <template #nombre>Apellido Materno</template>
        <template #dato>{{ participacion.administrador.administradorApellidoMaterno_valorManual }}</template>
      </Dato>
      <!-- /apellidos -->

      <!-- generales -->
      <Dato v-if="participacion.administrador.administradorCorreoPersonal_valorManual">
        <template #nombre>Correo</template>
        <template #dato>{{ participacion.administrador.administradorCorreoPersonal_valorManual }}</template>
      </Dato>
      <Dato v-if="participacion.administrador.administradorCelular_valorManual">
        <template #nombre>Celular</template>
        <template #dato>{{ participacion.administrador.administradorCelular_valorManual }}</template>
      </Dato>
      <!-- /apellidos -->
    </DatoGroup>
  </SeccionDatos>

  <SeccionDatos>
    <template #titulo>
      Información
    </template>

    <DatoGroup>
      <!-- participantes -->
      <Dato
        v-for="(dato, key) in datosOrdenados"
        :key="`revision-datos-${key}`"
      >
        <template #nombre>{{ dato.nombre }}</template>
        <template #dato>{{ dato.tipo === "Archivo" ? dato.valor.name : (dato.titulo ? dato.titulo : dato.valor) }}</template>
      </Dato>
    </DatoGroup>
  </SeccionDatos>

  <SeccionDatos>
    <template #titulo>
      Participantes
    </template>

    <DatoGroup>
      <!-- participantes -->
      <Dato
        v-for="(participante, key) in participacion.participantes"
        :key="`revision-participantes-${key}`"
      >
        <template #nombre>{{ participante.credito }}</template>
        <template #dato>{{ participante.alumno.matricula }} - {{ participante.alumno.nombre }}</template>
      </Dato>
    </DatoGroup>
  </SeccionDatos>
</template>

<script>
import { defineComponent } from 'vue'
import { orderBy } from 'lodash'
import SeccionDatos from '@/views/alumnos/convocatorias/participaciones/crear/partials/SeccionDatos.vue'
import DatoGroup from '@/views/alumnos/convocatorias/participaciones/crear/partials/form/DatoGroup.vue'
import Dato from '@/views/alumnos/convocatorias/participaciones/crear/partials/form/Dato.vue'

export default defineComponent({
  name: 'Revision',
  components: { SeccionDatos, Dato, DatoGroup },
  props: {
    participacion: {
      type: Object,
      required: true
    }
  },
  computed: {
    datosOrdenados() {
      return orderBy(
        this.$props.participacion.datos,
        'orden'
      )
    }
  }
})
</script>

<style scoped>

</style>
