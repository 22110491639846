<template>
  <div id="single-file-upload" class="py-5 w-full">
    <div class="preview">
      <Dropzone
        ref-key="dropzoneSingleRef"
        :options="{
                  url: 'https://festivalvibrart.com',
                  autoProcessQueue: false,
                  thumbnailWidth: 150,
                  maxFilesize: 5120,
                  maxFiles: 1,
                  addRemoveLinks: true,
                  dictRemoveFile: 'Quitar Archivo',
                  acceptedFiles: extensiones.join(',')
                }"
        class="dropzone"
      >
        <div class="text-lg pt-3 font-medium">
          Agrega aquí tu archivo.
        </div>
        <div class="text-sm text-gray-600" v-if="extensiones.length">
          {{ extensiones.length === 1 ? 'Extensión permitida:' : 'Extensiones permitidas:'}} {{ extensiones.join(',') }}
        </div>
      </Dropzone>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, provide, onMounted } from 'vue'

export default defineComponent({
  name: 'Archivo',
  props: {
    extensiones: {
      type: Array,
      required: true,
      default: () => []
    },
    pasoMaximo: {
      type: Number,
      required: false,
      default: 2
    }
  },
  setup(props, { emit }) {
    const dropzoneSingleRef = ref()

    provide('bind[dropzoneSingleRef]', el => {
      dropzoneSingleRef.value = el
    })

    onMounted(() => {
      const elDropzoneSingleRef = dropzoneSingleRef.value
      elDropzoneSingleRef.dropzone.on('addedfile', (file, done) => {
        const reader = new FileReader()
        reader.addEventListener('loadend', function(event) {
          file.status = elDropzoneSingleRef.dropzone.SUCCESS
          elDropzoneSingleRef.dropzone.emit('success', file)
          elDropzoneSingleRef.dropzone.emit('complete', file)
        })
        reader.readAsText(file)
      })
      elDropzoneSingleRef.dropzone.on('success', (file) => {
        emit('update:modelValue', file)
      })
      elDropzoneSingleRef.dropzone.on('removedfile', () => {
        emit('update:modelValue', '')
      })
      elDropzoneSingleRef.dropzone.on('error', (file, message) => {
        elDropzoneSingleRef.dropzone.removeFile(file)
        if (!file.accepted) {
          if (message.indexOf('File is too big') > -1) {
            alert('El archivo supera el tamaño máximo.')
            return
          }

          alert('Hubo un error.')
        }
      })
    })
  }
})
</script>
