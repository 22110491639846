<template>
  <div class="mt-8">
    <hr />
    <div class="container mt-8">
      <div class="flex flex-wrap justify-around gap-4">
        <div
          v-for="patrocinador in patrocinadoresOrdenados"
          :key="`patrocinador-${patrocinador.indice}`"
          class="max-w-[150px] xl:max-w-[300px]"
        >
          <img :src="patrocinador.imagen" class="w-full" :alt="patrocinador.codigo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('alumnos/convocatorias', ['patrocinadoresOrdenados'])
  },
  methods: {
    ...mapActions('alumnos/convocatorias', ['obtenerPatrocinadores'])
  },
  mounted() {
    this.obtenerPatrocinadores()
  }
}
</script>
