<template>
  <div class="flex flex-col sm:flex-row items-center p-5 border-gray-200">
    <h2 class="font-medium text-base mx-auto">
      Estadísticas de {{ campus.nombre_herramienta }}
    </h2>
  </div>
  <div id="hoverable-table" class="p-5 pt-0">
    <div class="preview">
      <div class="overflow-x-auto">
        <table class="table">
          <thead>
          <tr>
            <th
              class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
            >
              Convocatoria
            </th>
            <th
              class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
            >
              Cantidad de Alumnos
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="convocatoria in convocatoriasOrdenadas"
            :key="`convocatoria-${convocatoria.indice_herramienta}`"
            class="hover:bg-gray-200"
          >
            <td class="border">
              <router-link v-if="campus[convocatoria.campos_cantidadInteres_herramienta]!==0" :to="{name: 'admin.stats.alumnos.intereses', params: {campus: campus.codigo_herramienta, convocatoria: convocatoria.codigo_herramienta}}">
                {{ convocatoria.nombre_herramienta }}
              </router-link>
              <p v-else>{{ convocatoria.nombre_herramienta }}</p>
            </td>
            <td class="border">
              <router-link v-if="campus[convocatoria.campos_cantidadInteres_herramienta]!==0" :to="{name: 'admin.stats.alumnos.intereses', params: {campus: campus.codigo_herramienta, convocatoria: convocatoria.codigo_herramienta}}">
                {{ campus[convocatoria.campos_cantidadInteres_herramienta] }}
              </router-link>
              <p v-else>{{ campus[convocatoria.campos_cantidadInteres_herramienta] }}</p>
            </td>
          </tr>
          <tr>
            <th class="border">Total</th>
            <th class="border">
              {{ sumaCampus(campus) }}
            </th>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>

<script>
import { defineComponent } from 'vue'
import { orderBy } from 'lodash'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'interesesConvocatoria',
  props: {
    campus: {
      type: Object,
      required: true
    },
    convocatorias: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('administradores/auth', ['administrador', 'usuario']),
    convocatoriasOrdenadas() {
      return orderBy(
        this.$props.convocatorias.filter(convocatoria => {
          return this.administrador.convocatorias_indice
            ? this.administrador.convocatorias_indice ===
            convocatoria.indice_herramienta
            : true
        }),
        'orden_herramienta'
      )
    },
    sumaCampus() {
      return campus => {
        let suma = 0
        this.convocatoriasOrdenadas.forEach(conv => {
          suma += campus[conv.campos_cantidadInteres_herramienta] ?? 0
        })

        return suma
      }
    }
  }
})
</script>

<style scoped>

</style>
