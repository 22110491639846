import { fbAuth, fbFirestore } from '@/plugins/firebase'
import { collection, query, where, limit, onSnapshot } from 'firebase/firestore'
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth'
import { useRouter } from 'vue-router'

export function login({ commit }, { email, password }) {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(fbAuth, email, password)
      .then(resolve)
      .catch(reject)
  })
}

export function logout() {
  signOut(fbAuth).then(() => {
  })
}

export function asignarUsuario({ commit }, { fbUser, tecUser }) {
  commit('ASIGNAR_USUARIO', fbUser)
  commit('ASIGNAR_COLABORADOR', tecUser)
  commit('CAMBIAR_ACCESO', true)
}

export function handleAuthStateChange({ commit }) {
  const router = useRouter()
  onAuthStateChanged(fbAuth, fbUser => {
    if (fbUser) {
      commit('ASIGNAR_USUARIO', fbUser)
      const q = query(
        collection(fbFirestore, 'colaboradores'),
        where('usuario_indice', '==', fbUser.uid),
        limit(1)
      )

      onSnapshot(q, (querySnapshot) => {
        if (!querySnapshot.docs.length) {
          router.push({ name: 'colab.dashboard' })
        }

        querySnapshot.forEach(doc => {
          commit('ASIGNAR_COLABORADOR', doc.data())
          commit('CAMBIAR_ACCESO', true)
          router.push({ name: 'colab.dashboard' })
        })

        querySnapshot.docChanges().forEach((change) => {
          if (change.type !== 'modified' || change.type !== 'added') {
            return
          }
          commit('ASIGNAR_COLABORADOR', change.doc.data())
          commit('CAMBIAR_ACCESO', true)
        })
      })
    } else {
      commit('ASIGNAR_USUARIO', null)
      commit('ASIGNAR_COLABORADOR', null)
      commit('CAMBIAR_ACCESO', false)

      router.push({ name: 'colab.login' })
    }
  })
}
