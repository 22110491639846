<template>
  <li
    class="pl-3 pr-4 py-3 text-sm"
  >
    <div class="flex items-center justify-between">
      <div class="w-0 flex-1 flex items-center">
        <PaperclipIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
        <span class="ml-2 flex-1 w-0 truncate"> {{ participacion.codigo }}_{{ archivo.clave }}</span>
      </div>
      <div class="ml-4 flex-shrink-0" v-if="participacion[archivo.clave]">
        <button class="font-medium text-indigo-600 hover:text-indigo-500"
                @click="previsualizar = !previsualizar">
          {{ previsualizar ? 'Cerrar' : 'Previsualizar' }}
        </button>
      </div>
      <div class="ml-4 flex-shrink-0" v-if="participacion[archivo.clave]">
        <a :href="participacion[archivo.clave]"
           class="font-medium text-indigo-600 hover:text-indigo-500"
           download
        >
          Descargar
        </a>
      </div>
    </div>

    <div v-if="previsualizar" class="mt-2">
      <Component
        :is="archivo.previsualizador"
        :link="participacion[archivo.clave]"
      />
    </div>
  </li>
</template>

<script>
import { defineComponent } from 'vue'
import Audio from '@/views/administradores/components/participaciones/previsualizadores/Audio.vue'
import Documento from '@/views/administradores/components/participaciones/previsualizadores/Documento.vue'
import Imagen from '@/views/administradores/components/participaciones/previsualizadores/Imagen.vue'
import Video from '@/views/administradores/components/participaciones/previsualizadores/Video.vue'

export default defineComponent({
  name: 'ArchivoParticipacion',
  components: {
    Audio,
    Documento,
    Imagen,
    Video
  },

  props: {
    participacion: {
      type: Object,
      required: true
    },
    archivo: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      previsualizar: false
    }
  }
})
</script>

<style scoped>

</style>
