<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="intro-y text-lg font-medium mr-auto ml-1">
        {{ convocatoria.nombre }}
      </h2>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-10 mt-8">
      <div class="flex justify-center">
        <button
          :class="option === 1 ? 'btn-primary' : 'bg-gray-200 dark:bg-dark-1 text-gray-600'"
          class="intro-y w-10 h-10 rounded-full btn mx-2"
        >
          1
        </button>
        <button
          :class="option === 2 ? 'btn-primary' : 'bg-gray-200 dark:bg-dark-1 text-gray-600'"
          class="intro-y w-10 h-10 rounded-full btn mx-2"
        >
          2
        </button>
        <button
          :class="option === 3 ? 'btn-primary' : 'bg-gray-200 dark:bg-dark-1 text-gray-600'"
          class="intro-y w-10 h-10 rounded-full btn mx-2"
        >
          3
        </button>
        <button
          :class="option === 4 ? 'btn-primary' : 'bg-gray-200 dark:bg-dark-1 text-gray-600'"
          class="intro-y w-10 h-10 rounded-full btn mx-2"
        >
          4
        </button>
        <button
          :class="option === 5 ? 'btn-primary' : 'bg-gray-200 dark:bg-dark-1 text-gray-600'"
          class="intro-y w-10 h-10 rounded-full btn mx-2"
        >
          5
        </button>
      </div>
      <!-- Tipo de Participación -->
      <Paso
        v-if="option === 1 && convocatoria && convocatoria.indice"
      >
        <template #titulo>{{ convocatoria.paso01 }}</template>
        <template #descripcion>{{ convocatoria.paso01descripcion }}</template>
        <template #contenido>
          <SeleccionarTipo
            class="intro-y col-span-12 mt-10"
            v-model="participacion.participacionTipo_indice"
            :convocatoria="convocatoria"
          />
        </template>

        <template #acciones>
          <button
            :disabled="!participacion.participacionTipo_indice"
            class="btn btn-primary w-24 ml-2"
            @click="option = 2"
          >
            Continuar
          </button>
        </template>
      </Paso>
      <!-- /Tipo de Participación -->

      <!-- Datos de Participación -->
      <Paso
        v-if="option === 2"
      >
        <template #titulo>{{ convocatoria.paso02 }}</template>
        <template #descripcion>{{ convocatoria.paso02descripcion }}</template>

        <template #contenido>
          <LlenarDatos
            :participacion-tipo-indice="participacion.participacionTipo_indice"
            v-model="participacion.datos"
            @update="actualizarDatos"
            class="intro-y col-span-12"
          />

          <LlenarParticipantes
            :participacion-tipo-indice="participacion.participacionTipo_indice"
            v-model="participacion.participantes"
            class="intro-y col-span-12"
          />
        </template>

        <template #acciones>
          <button class="btn btn-secondary w-24" @click="option = 1">Anterior</button>
          <button
            :disabled="
                validarDatosLlenos
              "
            class="btn btn-primary w-24 ml-2" @click="option = 3"
          >
            Continuar
          </button>
        </template>
      </Paso>
      <!-- /Datos de Participación -->

      <!-- Revisión de Participación -->
      <Paso
        v-if="option === 3"
      >
        <template #titulo>{{ convocatoria.paso03 }}</template>
        <template #descripcion>{{ convocatoria.paso03descripcion }}</template>

        <template #contenido>
          <Revision
            :participacion="participacion"
          />
        </template>

        <template #acciones>
          <button class="btn btn-secondary w-24" @click="option = 2">Anterior</button>
          <a
            href="javascript:;"
            class="btn btn-primary w-24 ml-2"
            data-toggle="modal"
            data-target="#delete-modal-preview"
          >
            Continuar
          </a>
        </template>
      </Paso>
      <!-- /Revisión de Participación -->

      <!-- Proceso de Participación -->
      <Paso
        v-if="option === 4"
      >
        <template #titulo>{{ convocatoria.paso04 }}</template>
        <template #descripcion>{{ convocatoria.paso04descripcion }}</template>

        <template #contenido>
          <ProcesarParticipacion
            :convocatoria="convocatoria"
            :participacion="participacion"
            @procesado="procesoFinalizado"
          />
        </template>
      </Paso>
      <!-- /Proceso de Participación -->

      <!-- Confirmación de Participación -->
      <Paso
        v-if="option === 5"
      >
        <template #titulo>{{ convocatoria.paso05 }}</template>
        <template #descripcion>{{ convocatoria.paso05descripcion }}</template>

        <template #contenido>
          <div class="mb-20 col-span-12 pt-10">
            <p class="text-center text-[#0D9205]">
              <CheckIcon class="w-40 h-40" />
            </p>
            <h1 class="text-3xl text-center">
              Gracias, tu participación ha sido registrada.
            </h1>
          </div>
        </template>
      </Paso>
      <!-- /Confirmación de Participación -->

    </div>
    <!-- END: Wizard Layout -->
  </div>

  <div
    id="delete-modal-preview"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <div class="text-3xl mt-5">Confirmación</div>
            <div class="text-gray-600 my-4" v-html="terminosYCondiciones">
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
            >
              Regresar
            </button>
            <button
              data-dismiss="modal"
              @click="aceptarTerminos"
              type="button"
              class="btn bg-[#0D9205] text-white w-24"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import Paso from '@/views/alumnos/convocatorias/participaciones/crear/partials/Paso.vue'
import SeleccionarTipo from '@/views/alumnos/convocatorias/participaciones/crear/partials/SeleccionarTipo.vue'
import LlenarDatos from '@/views/alumnos/convocatorias/participaciones/crear/partials/LlenarDatos.vue'
import LlenarParticipantes from '@/views/alumnos/convocatorias/participaciones/crear/partials/LlenarParticipantes.vue'
import Revision from '@/views/alumnos/convocatorias/participaciones/crear/partials/Revision.vue'
import ProcesarParticipacion from '@/views/alumnos/convocatorias/participaciones/crear/partials/ProcesarParticipacion.vue'

export default defineComponent({
  components: {
    Paso,
    ProcesarParticipacion,
    Revision,
    LlenarParticipantes,
    LlenarDatos,
    SeleccionarTipo
  },

  data() {
    return {
      participacion: {
        participacionTipo_indice: '',
        datos: [],
        participantes: []
      },
      convocatoria: {
        nombre: '',
        terminos: ''
      },
      option: 1
    }
  },

  computed: {
    ...mapState('alumnos/auth', ['alumno']),
    terminosYCondiciones() {
      return this.convocatoria.terminos.replaceAll('\n', '<br />')
    },
    validarDatosLlenos() {
      if (this.participacion.datos &&
        Object.values(this.participacion.datos).filter(p => p.opcional ? false : p.valor === '').length) {
        return true
      } else {
        return !!(this.participacion.participantes &&
        Object.values(this.participacion.participantes).filter(p => !p.alumno.validado).length)
      }
    }
  },
  methods: {
    ...mapActions('alumnos/convocatorias', [
      'obtenerConvocatoriaPorCodigo'
    ]),
    aceptarTerminos() {
      this.participacion.terminosAceptados = this.convocatoria.terminos
      this.option = 4
    },
    actualizarDatos(datos) {
      this.participacion.datos = datos
    },
    calcularOpcion() {
      /* if (this.participacion.participacionTipo_indice) {
        this.option = 2
      } */
    },
    procesoFinalizado() {
      this.option = 5
    }
  },

  mounted() {
    this.calcularOpcion()
    this.obtenerConvocatoriaPorCodigo(this.$route.params.codigo).then((convocatoria) => {
      this.convocatoria = convocatoria

      this.participacion.administrador = {
        administrador_indice: this.alumno.indice,
        administradorPrimerNombre_valorManual: this.alumno.nombrePrimero,
        administradorSegundoNombre_valorManual: this.alumno.nombreSegundo,
        administradorApellidoPaterno_valorManual: this.alumno.apellidoPaterno,
        administradorApellidoMaterno_valorManual: this.alumno.apellidoMaterno,
        administradorCelular_valorManual: this.alumno.celular,
        administradorCorreoPersonal_valorManual: this.alumno.correoPersonal,
        administradorMatricula_valorManual: this.alumno.matricula,
        campuses_relacionManual: [this.alumno.campuses_indice]
      }
    })
  }
})
</script>
