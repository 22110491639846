<template>
  <div v-if="Object.keys(documento).length">
    <h1 class="text-5xl mt-6">{{ documento.convocatorias_nombre }}</h1>
    <span class="text-base italic">{{ documento.documentosTipos_nombre }}</span>

    <p class="mt-4 text-lg" v-html="documento.descripcion">
    </p>

    <!-- nivel01 -->
    <div
      v-for="documentoNivel01 in documentosNivel01Ordenados"
      :key="`n01-${documentoNivel01.indice}`"
      class="mt-8"
    >
      <p class="text-2xl" v-html="documentoNivel01.nombre"></p>
      <p class="mt-2 text-lg" v-html="documentoNivel01.descripcion"></p>

      <!-- nivel02 -->
      <div
        v-for="(documentoNivel02) in documentosNivel02Ordenados(documentoNivel01.indice)"
        :key="`n01-${documentoNivel01.indice}-n02-${documentoNivel02.indice}`"
        class="mt-2"
      >
        <ul>
          <li
            class="mt-2 ml-6 text-lg flex"
          >
            <div class="pr-4" v-html="documentoNivel02.vineta">
            </div>
            <div v-html="documentoNivel02.descripcion">
            </div>
          </li>

          <ul>
            <li
              v-for="(documentoNivel03) in documentosNivel03Ordenados(documentoNivel02.indice)"
              :key="`n01-${documentoNivel01.indice}-n02-${documentoNivel02.indice}-n03-${documentoNivel03.indice}`"
              class="ml-16 text-base flex"
            >
              <div class="pr-4" v-html="documentoNivel03.vineta">
              </div>
              <div v-html="documentoNivel03.descripcion">
              </div>
            </li>
          </ul>
        </ul>
      </div>
      <!-- /nivel02 -->
    </div>
    <!-- /nivel01 -->
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { orderBy } from 'lodash'

export default defineComponent({
  props: {
    documentoCodigo: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const documento = ref({})

    const obtenerDocumento = (documentoCodigo) => store.dispatch('alumnos/documentos/obtenerDocumentoPorCodigo', documentoCodigo)
    const obtenerDocumentosNivel01 = (documentoIndice) => store.dispatch('alumnos/documentos/obtenerDocumentosNivel01PorDocumentoIndice', documentoIndice)
    const obtenerDocumentosNivel02 = (documentoIndice) => store.dispatch('alumnos/documentos/obtenerDocumentosNivel02PorDocumentoIndice', documentoIndice)
    const obtenerDocumentosNivel03 = (documentoIndice) => store.dispatch('alumnos/documentos/obtenerDocumentosNivel03PorDocumentoIndice', documentoIndice)
    const documentosNivel01 = ref([])
    const documentosNivel02 = ref([])
    const documentosNivel03 = ref([])
    const documentosNivel01Ordenados = computed(() => {
      return orderBy(documentosNivel01.value, 'orden')
    })
    const documentosNivel02Ordenados = computed(() => {
      return (documentoNivel01Indice) => {
        return orderBy(
          documentosNivel02.value.filter(documento => {
            return documento.documentosNiveles01_indice === documentoNivel01Indice &&
              documento.mostrar
          }),
          'orden'
        )
      }
    })
    const documentosNivel03Ordenados = computed(() => {
      return (documentoNivel02Indice) => {
        return orderBy(
          documentosNivel03.value.filter(documento => {
            return documento.documentosNiveles02_indice === documentoNivel02Indice &&
              documento.mostrar
          }),
          'orden'
        )
      }
    })

    onMounted(() => {
      obtenerDocumento(props.documentoCodigo)
        .then((doc) => {
          documento.value = doc
          obtenerDocumentosNivel01(doc.indice).then(docs => { documentosNivel01.value = docs })
          obtenerDocumentosNivel02(doc.indice).then(docs => { documentosNivel02.value = docs })
          obtenerDocumentosNivel03(doc.indice).then(docs => { documentosNivel03.value = docs })
        })
    })

    return {
      documento,
      documentosNivel01Ordenados,
      documentosNivel02Ordenados,
      documentosNivel03Ordenados
    }
  }
})
</script>
