<template>
  <div  class="py-4" v-if="cargado===1">

    <h1 class="text-xl">Alumnos Registrados por Comunidad: {{comunidad.nombre}}</h1>
    <TablaAlumnos :alumnos="alumnos"></TablaAlumnos>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'vuex'
import TablaAlumnos from '@/components/alumnos/TablaAlumnos'
import { orderBy } from 'lodash'
export default defineComponent({
  name: 'AlumnosRegistradosComunidad',
  components: { TablaAlumnos },
  data() {
    return {
      comunidad: null,
      cargado: 0,
      alumnos: []
    }
  },
  computed: {
    ...mapState('administradores/auth', ['administrador']),
    alumnosOrdenados() {
      return orderBy(
        this.alumnos,
        'orden'
      )
    }
  },
  methods: {
    ...mapActions('administradores/convocatorias', ['obtenerComunidadPorCodigo', 'obtenerAlumnosComunidad']),
    buscarAlumnos: function () {
      if (this.cargado < 1) {
        return
      }
      this.obtenerAlumnosComunidad({
        comunidadIndice: this.comunidad.indice
      })
        .then(alumnos => {
          this.alumnos = this.administrador.campuses_indice
            ? alumnos.filter(alumno => {
              return alumno.campuses_indice === this.administrador.campuses_indice
            })
            : alumnos
        })
    }
  },
  mounted() {
    this.obtenerComunidadPorCodigo(this.$route.params.comunidad)
      .then(comunidad => {
        this.comunidad = comunidad
        this.cargado++
        this.buscarAlumnos()
      })
  }
})
</script>

<style scoped>

</style>
