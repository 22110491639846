<template>
  <div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
  computed: {
    ...mapGetters('administradores/documentos', ['documentosOrdenados'])
  },
  methods: {
    ...mapActions('administradores/documentos', ['obtenerDocumentos'])
  },
  mounted() {
    this.obtenerDocumentos()
  }
})
</script>

<style scoped>

</style>
