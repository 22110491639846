<template>
  <div>
<!--    <DarkModeSwitcher />-->
  <Register>
    <template #inicio>
      <h2
        class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
      >
        Registro para Administradores
      </h2>
    </template>
    <template #form>
      <div class="intro-x mt-8" v-if="!loading">
        <!-- nombre -->
        <input
          v-model="validate.nombre.$model"
          type="text"
          class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
          :class="{ 'border-theme-5': validate.nombre.$error }"
          placeholder="Nombre"
        />
        <template v-if="validate.nombre.$error">
          <div
            v-for="(error, index) in validate.nombre.$errors"
            :key="index"
            class="text-theme-5 mt-2"
          >
            {{ error.$message }}
          </div>
        </template>
        <!-- /nombre -->

        <!-- correo -->
        <input
          v-model="validate.email.$model"
          type="text"
          class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
          :class="{ 'border-theme-5': validate.email.$error }"
          placeholder="Correo"
        />
        <template v-if="validate.email.$error">
          <div
            v-for="(error, index) in validate.email.$errors"
            :key="index"
            class="text-theme-5 mt-2"
          >
            {{ error.$message }}
          </div>
        </template>
        <!-- /correo -->

        <!-- contrasena -->
        <input
          v-model="validate.password.$model"
          type="password"
          class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
          :class="{ 'border-theme-5': validate.password.$error }"
          placeholder="Contraseña"
          @keydown.enter="register"
        />
        <template v-if="validate.password.$error">
          <div
            v-for="(error, index) in validate.password.$errors"
            :key="index"
            class="text-theme-5 mt-2"
          >
            {{ error.$message }}
          </div>
        </template>
        <!-- /contrasena -->
      </div>
    </template>
    <template #actions>
      <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
        <button
          class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
          @click="register"
        >
          Registrar
        </button>
        <router-link
          :to="{name: 'login'}"
          tag="button"
          class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
        >
          Iniciar Sesión
        </router-link>
      </div>
    </template>
    <template #failed-notificacion>
      <div
        id="failed-notification-content"
        class="toastify-content hidden flex"
      >
        <XCircleIcon class="text-theme-5" />
        <div class="ml-4 mr-4">
          <div class="font-medium">Registro Incorrecto</div>
          <div class="text-gray-600 mt-1">
            Campos incompletos.
          </div>
        </div>
      </div>
    </template>
    <template #success-notificacion>
      <div
        id="success-notification-content"
        class="toastify-content hidden flex"
      >
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
          <div class="font-medium">Registro exitoso</div>
          <div class="text-gray-600 mt-1">
            En un momento serás redirigido.
          </div>
        </div>
      </div>
    </template>
  </Register>
  </div>
</template>

<script>
import { reactive, defineComponent, onMounted, toRefs, ref } from 'vue'
import {
  required,
  minLength,
  email,
  helpers
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'
import { fbAuth, fbFunctions } from '@/plugins/firebase'
import { useRoute, useRouter } from 'vue-router'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import Airtable from 'airtable'
import Register from '@/components/form/Login.vue'
const base = new Airtable({ apiKey: 'patmSk0sdzVet2VQD.067b5939a8026476fb7e425b473c39417eecafad467e1ca526516af244112bdc' }).base('appYzmr5BAYcj9vHc')

export default defineComponent({
  components: {
    Register
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const administradorIndice = `rec${route.params.administradorIndice}`
    const loading = ref(true)

    const formData = reactive({
      indice: '',
      nombre: '',
      email: '',
      password: ''
    })
    const rules = {
      nombre: {
        required: helpers.withMessage('El campo es obligatorio', required)
      },
      email: {
        email: helpers.withMessage('Es necesaria una dirección de correo válida', email),
        required: helpers.withMessage('El campo es obligatorio', required)
      },
      password: {
        minLength: helpers.withMessage('Tu contraseña debe ser de al menos 8 caracteres', minLength(8)),
        required: helpers.withMessage('El campo es obligatorio', required)
      }
    }
    const validate = useVuelidate(rules, toRefs(formData))
    const register = () => {
      validate.value.$touch()

      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {
        const updateRecord = httpsCallable(fbFunctions, 'at-updateRecord')
        createUserWithEmailAndPassword(fbAuth, formData.email, formData.password, {})
          .then((response) => {
            base('administradores').update(administradorIndice, {
              nombre_valorManual: formData.nombre,
              correo_valorManual: formData.email,
              usuario_indice_valorManual: response.user.uid
            }).then(() => {
              updateRecord({ table: 'administradores', id: formData.indice })
                .then(() => {
                  router.push(router.resolve({ name: 'admin.dashboard' }))
                })
            })
            Toastify({
              node: cash('#success-notification-content')
                .clone()
                .removeClass('hidden')[0],
              duration: 1000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true
            }).showToast()
          })
      }
    }

    onMounted(() => {
      base('administradores').find(administradorIndice).then((admin) => {
        formData.indice = admin.id
        formData.nombre = admin.fields.nombre_herramienta
        formData.email = admin.fields.correo_herramienta
        loading.value = false
      })
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })

    return {
      loading,
      formData,
      validate,
      register
    }
  }
})
</script>
