import { orderBy } from 'lodash'

export function documentosNiveles01Ordenados(state) {
  return orderBy(
    state.documentosNiveles01,
    'orden'
  )
}

export function documentosNiveles02OrdenadosPorNivel01(state) {
  return (documentoNivel01Indice) => {
    return orderBy(
      state.documentosNiveles02.filter(documento => {
        return documento.documentosNiveles01_indice === documentoNivel01Indice &&
          documento.mostrar
      }),
      'orden'
    )
  }
}

export function documentosNiveles03OrdenadosPorNivel02(state) {
  return (documentoNivel02Indice) => {
    return orderBy(
      state.documentosNiveles03.filter(documento => {
        return documento.documentosNiveles02_indice === documentoNivel02Indice &&
          documento.mostrar
      }),
      'orden'
    )
  }
}
