export function ASIGNAR_USUARIO(state, payload) {
  state.usuario = payload
}

export function ASIGNAR_ALUMNO(state, payload) {
  state.alumno = payload
}

export function ASIGNAR_ADMINISTRADOR(state, payload) {
  state.isAdmin = payload
}

export function CAMBIAR_ACCESO(state, payload) {
  state.loggedIn = payload
}
