<template>
  <iframe height="600" width="100%"
          :src="`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(link)}`">
  </iframe>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Documento',
  props: {
    link: {
      type: String,
      required: true
    }
  }
})
</script>

<style scoped>

</style>
