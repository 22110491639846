export function AGREGAR_TABLAS(state, tablas) {
  state.tablas = tablas
}

export function LIMPIAR_TABLAS(state) {
  state.tablas = []
}

export function ACTUALIZANDO_TABLA(state, { tabla, updating }) {
  state.tablas.forEach(tab => {
    if (tab.indice === tabla.indice) {
      tab.updating = updating
    }
  })
}
