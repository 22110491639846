<template>
  <SeccionDatos v-if="datosManuales.length">
    <template #titulo>
      Participantes
    </template>

    <template #default>
      <DatoGroup
        v-for="participante in participantes"
        :key="`participante-${participante.key}`"
      >
        <Dato>
          <template #datoPersonalizado v-if="participante.adicional">
            <input
              v-if="!participante.alumno.validado"
              v-model="participante.credito"
              :id="`participante-${participante.key}-datoPersonalizado`"
              type="text"
              class="form-control"
              placeholder="Escribe el rol"
            />
            <b
              v-else
            >
              {{ participante.credito }}
            </b>
          </template>
          <template #nombre>
            {{ participante.participanteTipo.nombre }}
          </template>
          <template #descripcion v-if="!participante.adicional">
            {{ participante.participanteTipo.descripcion }}
          </template>
          <template #dato>
            <div class="grid grid-cols-12 align-middle items-center flex-shrink-0 w-full">
              <input
                v-model="participante.matricula"
                :id="`participante-${participante.key}-matricula`"
                type="text"
                :class="participante.alumno.validado ? 'border-[#1D7526]' : 'border-2 border-[#911924]'"
                class="form-control col-span-6 w-full"
                placeholder="Escribe la Matrícula"
                @change="participante.alumno.validado = false; participante.error = ''"
                :disabled="participante.alumno.validado"
                v-if="!participante.alumno.validado"
              />
              <p
                class="col-span-3"
                v-else
              >
                {{ participante.matricula }}
              </p>
              <button
                class="btn btn-primary col-span-3 text-white ml-4"
                :disabled="(participante.buscando) || participante.credito === '' || participante.matricula === ''"
                v-if="!participante.alumno.validado"
                @click="validarMatricula(participante)"
              >
                {{ participante.buscando ? 'Asignando Matrícula...' : 'Asignar Matrícula' }}
              </button>
              <button class="btn btn-primary col-span-3 text-white ml-4" v-if="participante.alumno.validado" @click="participante.matricula = ''; participante.alumno.validado = false;">
                Editar
              </button>
              <button class="btn bg-[#911924] col-span-3 text-white ml-4" v-if="participante.adicional" @click="borrarParticipante(participante)">
                Eliminar
              </button>
            </div>
            <div class="grid grid-cols-12 align-middle items-center flex-shrink-0">
              <small v-if="participante.error" class="text-theme-5 col-span-12">
                {{ participante.error }}
              </small>
            </div>
          </template>
        </Dato>
      </DatoGroup>
    </template>

    <template #acciones>
      <button class="btn btn-primary w-48 mx-auto" @click="agregarParticipante">
        Agregar Participante
      </button>
    </template>
  </SeccionDatos>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import { orderBy } from 'lodash'
import SeccionDatos from '@/views/alumnos/convocatorias/participaciones/crear/partials/SeccionDatos.vue'
import Dato from '@/views/alumnos/convocatorias/participaciones/crear/partials/form/Dato.vue'
import DatoGroup from '@/views/alumnos/convocatorias/participaciones/crear/partials/form/DatoGroup.vue'

export default defineComponent({
  name: 'LlenarParticipantes',
  components: { DatoGroup, Dato, SeccionDatos },
  props: {
    participacionTipoIndice: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      contador: 0,
      participacionTipo: {},
      participantesTipos: [],
      participantes: []
    }
  },

  computed: {
    ...mapState('alumnos/auth', ['alumno']),
    participantesTiposOrdenados() {
      return orderBy(
        this.participantesTipos,
        'orden'
      )
    },
    datosManuales() {
      return this.participantesTiposOrdenados.filter(participanteTipo => !participanteTipo.administrador)
    }
  },

  methods: {
    ...mapActions('alumnos/convocatorias', [
      'obtenerParticipacionTipoPorIndice',
      'obtenerParticipantesTiposPorParticipacionTipoIndice',
      'validarMatriculaAlumno'
    ]),
    validarMatricula(participante) {
      participante.buscando = true
      participante.matricula = participante.matricula.replace(/ /g, '')
      this.validarMatriculaAlumno(participante.matricula)
        .then((alumno) => {
          participante.alumno = alumno
          participante.validado = true
        })
        .catch(() => {
          participante.error = 'El alumno no está registrado en Vibrart Festival 2022'
        })
        .finally(() => {
          participante.buscando = false
        })
    },
    agregarParticipante() {
      const participanteTipo = this.adicionales
      this.contador++
      this.participantes.push({
        key: this.contador,
        participanteTipo: participanteTipo,
        adicional: participanteTipo.adicionales,
        participantesTipos_indice: participanteTipo.indice,
        credito: participanteTipo.adicionales ? '' : participanteTipo.nombre,
        matricula: participanteTipo.administrador ? this.alumno.matricula : '',
        alumno: {
          nombre: participanteTipo.administrador ? this.alumno.nombre : '',
          matricula: participanteTipo.administrador ? this.alumno.matricula : '',
          validado: !!participanteTipo.administrador
        }
      })
    },
    borrarParticipante(participante) {
      this.participantes = this.participantes.filter(part => {
        return part.key !== participante.key
      })

      this.$emit('update:modelValue', this.participantes)
    }
  },

  mounted() {
    this.obtenerParticipantesTiposPorParticipacionTipoIndice(this.$props.participacionTipoIndice)
      .then(participantesTipos => {
        participantesTipos = orderBy(participantesTipos, 'orden')

        participantesTipos.forEach(participanteTipo => {
          this.contador++
          if (participanteTipo.adicionales) {
            this.adicionales = participanteTipo
          } else {
            this.participantes.push({
              key: this.contador,
              buscando: false,
              participanteTipo: participanteTipo,
              adicional: participanteTipo.adicionales,
              participantesTipos_indice: participanteTipo.indice,
              credito: participanteTipo.adicionales ? '' : participanteTipo.nombre,
              matricula: participanteTipo.administrador ? this.alumno.matricula : '',
              alumno: {
                nombre: participanteTipo.administrador ? this.alumno.nombre : '',
                matricula: participanteTipo.administrador ? this.alumno.matricula : '',
                validado: !!participanteTipo.administrador
              }
            })
          }
        })
        this.participantesTipos = participantesTipos

        this.$emit('update:modelValue', this.participantes)
      })
  }
})
</script>

<style scoped>

</style>
