<template>
  <div>
    <!-- BEGIN: Hoverable Table -->
    <div
      class="intro-y box mt-5 max-w-[600px] mx-auto"
      v-for="convocatoria in convocatoriasOrdenadas"
      :key="`convocatoria-${convocatoria.indice_herramienta}`"
    >
      <InteresesCampus  :convocatoria="convocatoria" :campuses="campuses"/>
    </div>
    <!-- END: Hoverable Table -->
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import Airtable from 'airtable'
import { orderBy } from 'lodash'
import InteresesCampus from '@/views/administradores/components/estadisticas/InteresesCampus'
const base = new Airtable({ apiKey: 'patmSk0sdzVet2VQD.067b5939a8026476fb7e425b473c39417eecafad467e1ca526516af244112bdc' }).base(
  'appYzmr5BAYcj9vHc'
)

export default defineComponent({
  components: { InteresesCampus },
  data() {
    return {
      campuses: [],
      convocatorias: []
    }
  },
  computed: {
    ...mapState('administradores/auth', ['administrador', 'usuario']),
    convocatoriasOrdenadas() {
      return orderBy(
        this.convocatorias.filter(convocatoria => {
          return this.administrador.convocatorias_indice
            ? this.administrador.convocatorias_indice ===
                convocatoria.indice_herramienta
            : true
        }),
        'orden_herramienta'
      )
    }
  },
  methods: {
    obtenerDatos() {
      this.campuses = []
      this.convocatorias = []

      base('campuses')
        .select()
        .eachPage((records, fetchNextPage) => {
          const recs = []

          records.forEach(record => {
            record.fields.id = record.id
            recs.push(record.fields)
          })
          this.campuses.push(...recs)

          fetchNextPage()
        })

      base('convocatorias')
        .select({
          filterByFormula: this.administrador.convocatorias_indice
            ? `AND(indice_herramienta='${this.administrador.convocatorias_indice}', activo_herramienta=1)`
            : 'AND(activo_herramienta=1)'
        })
        .eachPage((records, fetchNextPage) => {
          const recs = []

          records.forEach(record => {
            record.fields.id = record.id
            recs.push(record.fields)
          })
          this.convocatorias.push(...recs)

          fetchNextPage()
        })
    }
  },
  mounted() {
    this.obtenerDatos()
  }
})
</script>

<style scoped></style>
