import { fbFirestore, fbFunctions } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'
import {
  collection,
  onSnapshot,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  limit
} from 'firebase/firestore'
import Airtable from 'airtable'

export function obtenerEdiciones({ rootState, commit }) {
  return new Promise(resolve => {
    if (rootState.alumnos.convocatorias.ediciones.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'ediciones' })

    const q = query(collection(fbFirestore, 'ediciones'))
    onSnapshot(q, querySnapshop => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(
              () =>
                commit('AGREGAR', {
                  table: 'ediciones',
                  payload: change.doc.data()
                }),
              i
            )
            break
          }

          case 'modified': {
            setTimeout(
              () =>
                commit('EDITAR', {
                  table: 'ediciones',
                  payload: change.doc.data()
                }),
              i
            )
            break
          }

          case 'removed': {
            commit('BORRAR', { table: 'ediciones', payload: change.doc.data() })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerVisualizaciones({ commit }, administradorIndice) {
  return new Promise(resolve => {
    const docRef = doc(fbFirestore, 'visualizaciones', administradorIndice)
    const snap = () => {
      const unsub = onSnapshot(docRef, doc => {
        commit('ASIGNAR', { table: 'visualizaciones', payload: doc.data() })
        resolve(unsub)
      })
    }
    getDoc(docRef).then(docSnap => {
      if (!docSnap.exists()) {
        setDoc(docRef, {
          administrador_indice: administradorIndice,
          participaciones_indices: []
        }).then(() => {
          snap()
        })
      } else {
        snap()
      }
    })
  })
}

export function agregarVisualizacionParticipacion(
  { commit },
  { administradorIndice, participacionIndice }
) {
  return new Promise((resolve, reject) => {
    const asistenciaRef = doc(
      collection(fbFirestore, 'visualizaciones'),
      administradorIndice
    )

    updateDoc(asistenciaRef, {
      participaciones_indices: arrayUnion(participacionIndice)
    })
      .then(() => {
        resolve()
      })
      .catch(e => {
        reject(e)
        console.error(e)
      })
  })
}

export function quitarVisualizacionParticipacion(
  { commit },
  { administradorIndice, participacionIndice }
) {
  return new Promise((resolve, reject) => {
    const asistenciaRef = doc(
      collection(fbFirestore, 'visualizaciones'),
      administradorIndice
    )

    updateDoc(asistenciaRef, {
      participaciones_indices: arrayRemove(participacionIndice)
    })
      .then(() => {
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

export function obtenerConvocatorias({ rootState, commit }) {
  return new Promise(resolve => {
    if (rootState.alumnos.convocatorias.convocatorias.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'convocatorias' })

    const q = query(
      collection(fbFirestore, 'convocatorias'),
      where('activo', '==', true)
    )
    onSnapshot(q, querySnapshop => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(
              () =>
                commit('AGREGAR', {
                  table: 'convocatorias',
                  payload: change.doc.data()
                }),
              i
            )
            break
          }

          case 'modified': {
            setTimeout(
              () =>
                commit('EDITAR', {
                  table: 'convocatorias',
                  payload: change.doc.data()
                }),
              i
            )
            break
          }

          case 'removed': {
            commit('BORRAR', {
              table: 'convocatorias',
              payload: change.doc.data()
            })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerParticipacionesTipos({ rootState, commit }) {
  return new Promise(resolve => {
    if (rootState.administradores.convocatorias.participacionesTipos.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'participacionesTipos' })

    const q = query(collection(fbFirestore, 'participacionesTipos'))
    onSnapshot(q, querySnapshop => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(
              () =>
                commit('AGREGAR', {
                  table: 'participacionesTipos',
                  payload: change.doc.data()
                }),
              i
            )
            break
          }

          case 'modified': {
            setTimeout(
              () =>
                commit('EDITAR', {
                  table: 'participacionesTipos',
                  payload: change.doc.data()
                }),
              i
            )
            break
          }

          case 'removed': {
            commit('BORRAR', {
              table: 'participacionesTipos',
              payload: change.doc.data()
            })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerParticipacionesDatos({ rootState, commit }) {
  return new Promise(resolve => {
    if (rootState.administradores.convocatorias.participacionesDatos.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'participacionesDatos' })

    const q = query(collection(fbFirestore, 'participacionesDatos'))
    onSnapshot(q, querySnapshop => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(
              () =>
                commit('AGREGAR', {
                  table: 'participacionesDatos',
                  payload: change.doc.data()
                }),
              i
            )
            break
          }

          case 'modified': {
            setTimeout(
              () =>
                commit('EDITAR', {
                  table: 'participacionesDatos',
                  payload: change.doc.data()
                }),
              i
            )
            break
          }

          case 'removed': {
            commit('BORRAR', {
              table: 'participacionesDatos',
              payload: change.doc.data()
            })
          }
        }
      })

      resolve()
    })
  })
}

export function obtenerAlumnosInteresadosPorCampusYConvocatoria(
  _,
  { campusIndice, convocatoriaIndice }
) {
  return new Promise(resolve => {
    const q = query(
      collection(fbFirestore, 'alumnos'),
      where('campuses_indice', '==', campusIndice),
      where('convocatorias_indices', 'array-contains', convocatoriaIndice)
    )
    getDocs(q).then(querySnapshot => {
      resolve(querySnapshot.docs.map(doc => doc.data()))
    })
  })
}

export function obtenerParticipantesPorParticipacionIndice(
  _,
  { participacionIndice }
) {
  return new Promise(resolve => {
    const q = query(
      collection(fbFirestore, 'participantes'),
      where('participaciones_indice', '==', participacionIndice)
    )
    getDocs(q).then(querySnapshot => {
      resolve(querySnapshot.docs.map(doc => doc.data()))
    })
  })
}

export function obtenerAlumnosPorNivelEstudiosCampus(
  _,
  { campusIndice, estudiosIndice }
) {
  return new Promise(resolve => {
    const q = query(
      collection(fbFirestore, 'alumnos'),
      where('campuses_indice', '==', campusIndice),
      where('estudios_indice', '==', estudiosIndice)
    )

    getDocs(q).then(querySnapshot => {
      resolve(querySnapshot.docs.map(doc => doc.data()))
    })
  })
}
export function obtenerAlumnosPorConvocatoria(_, { convocatoriaIndice }) {
  return new Promise(resolve => {
    const q = query(
      collection(fbFirestore, 'alumnos'),
      where('convocatorias_indices', 'array-contains', convocatoriaIndice)
      // limit(20)
    )

    getDocs(q).then(querySnapshot => {
      resolve(querySnapshot.docs.map(doc => doc.data()))
    })
  })
}
export function obtenerAlumnosComunidad(_, { comunidadIndice }) {
  return new Promise(resolve => {
    const q = query(
      collection(fbFirestore, 'alumnos'),
      where('comunidades_indice', '==', comunidadIndice)
    )

    getDocs(q).then(querySnapshot => {
      resolve(querySnapshot.docs.map(doc => doc.data()))
    })
  })
}
export function obtenerAlumnosPorCampus(_, { campusIndice }) {
  return new Promise(resolve => {
    const q = query(
      collection(fbFirestore, 'alumnos'),
      where('campuses_indice', '==', campusIndice)
    )
    getDocs(q).then(querySnapshot => {
      resolve(querySnapshot.docs.map(doc => doc.data()))
    })
  })
}
export function obtenerAlumnosEstudios(_, { estudiosIndice }) {
  return new Promise(resolve => {
    const q = query(
      collection(fbFirestore, 'alumnos'),
      where('estudios_indice', '==', estudiosIndice)
    )

    getDocs(q).then(querySnapshot => {
      resolve(querySnapshot.docs.map(doc => doc.data()))
    })
  })
}
export function obtenerConvocatoriaPorAlumnoCampus(
  _,
  { campusIndice, estudiosIndice }
) {
  return new Promise(resolve => {
    const q = query(
      collection(fbFirestore, 'alumnos'),
      where('campuses_indice', '==', campusIndice),
      where('estudios_indice', '==', estudiosIndice),
      limit(2)
    )

    getDocs(q).then(querySnapshot => {
      resolve(querySnapshot.docs.map(doc => doc.data()))
    })
  })
}
export function obtenerAlumnosInteresados(
  _,
  { campusIndice, convocatoriaIndice }
) {
  return new Promise(resolve => {
    const q = query(
      collection(fbFirestore, 'alumnos'),
      where('campuses_indice', '==', campusIndice),
      where('convocatorias_indices', 'array-contains', convocatoriaIndice)
    )

    getDocs(q).then(querySnapshot => {
      resolve(querySnapshot.docs.map(doc => doc.data()))
    })
  })
}
export function obtenerConvocatoriasSinReactividad({ rootState, commit }) {
  return new Promise(resolve => {
    if (rootState.alumnos.convocatorias.convocatorias.length) {
      resolve()
      return
    }

    commit('LIMPIAR', { table: 'convocatorias' })

    const q = query(
      collection(fbFirestore, 'convocatorias'),
      where('activo', '==', true)
    )

    getDocs(q).then(querySnapshot => {
      resolve(querySnapshot.docs.map(doc => doc.data()))
    })
  })
}

export function obtenerParticipacionesPorConvocatoriaIndice(
  { rootState, commit },
  { convocatoriaIndice, campusesIndices }
) {
  return new Promise(resolve => {
    commit('LIMPIAR', { table: 'participaciones' })

    const q = query(
      collection(fbFirestore, 'participaciones'),
      where('convocatorias_indice', '==', convocatoriaIndice)
    )
    const unsuscribe = onSnapshot(q, querySnapshop => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1
        const participacion = change.doc.data()

        switch (change.type) {
          case 'added': {
            if (campusesIndices.includes(participacion.campuses_indice)) {
              setTimeout(
                () =>
                  commit('AGREGAR', {
                    table: 'participaciones',
                    payload: change.doc.data()
                  }),
                i
              )
            }
            break
          }

          case 'modified': {
            setTimeout(
              () =>
                commit('EDITAR', {
                  table: 'participaciones',
                  payload: change.doc.data()
                }),
              i
            )
            break
          }

          case 'removed': {
            commit('BORRAR', {
              table: 'participaciones',
              payload: change.doc.data()
            })
          }
        }
      })

      resolve(unsuscribe)
    })
  })
}

export function obtenerParticipacionesFinalistasPorConvocatoriaIndice(
  { rootState, commit },
  { convocatoriaIndice, campusesIndices }
) {
  return new Promise(resolve => {
    commit('LIMPIAR', { table: 'participaciones' })

    const q = query(
      collection(fbFirestore, 'participaciones'),
      where('convocatorias_indice', '==', convocatoriaIndice),
      where('finalista', '==', true)
    )
    const unsuscribe = onSnapshot(q, querySnapshop => {
      let i = 0

      querySnapshop.docChanges().forEach(change => {
        i += 1

        switch (change.type) {
          case 'added': {
            setTimeout(
              () =>
                commit('AGREGAR', {
                  table: 'participaciones',
                  payload: change.doc.data()
                }),
              i
            )
            break
          }

          case 'modified': {
            setTimeout(
              () =>
                commit('EDITAR', {
                  table: 'participaciones',
                  payload: change.doc.data()
                }),
              i
            )
            break
          }

          case 'removed': {
            commit('BORRAR', {
              table: 'participaciones',
              payload: change.doc.data()
            })
          }
        }
      })

      resolve(unsuscribe)
    })
  })
}

export function obtenerConvocatoriaPorIndice(_, convocatoriaIndice) {
  return new Promise((resolve, reject) => {
    const docRef = doc(fbFirestore, 'convocatorias', convocatoriaIndice)
    getDoc(docRef)
      .then(documentSnapshot => {
        resolve(documentSnapshot.data())
      })
      .catch(reject)
  })
}

export function obtenerConvocatoriaPorCodigo(_, convocatoriaCodigo) {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(fbFirestore, 'convocatorias'),
      where('codigo', '==', convocatoriaCodigo),
      limit(1)
    )

    getDocs(q)
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          resolve(doc.data())
        })
      })
      .catch(reject)
  })
}
export function obtenerEstudiosPorCodigo(_, estudiosPorCodigo) {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(fbFirestore, 'estudios'),
      where('codigo', '==', estudiosPorCodigo),
      limit(1)
    )

    getDocs(q)
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          resolve(doc.data())
        })
      })
      .catch(reject)
  })
}
export function obtenerCampusPorCodigo(_, campusCodigo) {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(fbFirestore, 'campuses'),
      where('codigo', '==', campusCodigo),
      limit(1)
    )

    getDocs(q)
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          resolve(doc.data())
        })
      })
      .catch(reject)
  })
}
export function obtenerComunidadPorCodigo(_, comunidadCodigo) {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(fbFirestore, 'comunidades'),
      where('codigo', '==', comunidadCodigo),
      limit(1)
    )

    getDocs(q)
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          resolve(doc.data())
        })
      })
      .catch(reject)
  })
}
export function obtenerDatoDeTablaDatosConvocatoria(_, { tabla, indice }) {
  return new Promise((resolve, reject) => {
    const docRef = doc(fbFirestore, tabla, indice)
    getDoc(docRef)
      .then(documentSnapshot => {
        resolve(documentSnapshot.data())
      })
      .catch(reject)
  })
}

export function actualizarSeleccionParticipacion(
  _,
  { indiceParticipacion, seleccionadoPorIndice }
) {
  return new Promise((resolve, reject) => {
    const airtable = new Airtable({ apiKey: 'patmSk0sdzVet2VQD.067b5939a8026476fb7e425b473c39417eecafad467e1ca526516af244112bdc' })
    const base = airtable.base('appYzmr5BAYcj9vHc')
    const updateRecord = httpsCallable(fbFunctions, 'at-updateRecord')

    base('participaciones')
      .update(indiceParticipacion, {
        seleccionadaPor_relacionManual: seleccionadoPorIndice
          ? [seleccionadoPorIndice]
          : null
      })
      .then(() => {
        updateRecord({ table: 'participaciones', id: indiceParticipacion })
          .then(() => {
            resolve()
          })
          .catch(console.error)
      })
      .catch(console.error)
  })
}

export function actualizarFinalistaParticipacion(
  _,
  { indiceParticipacion, finalistaPorIndice }
) {
  return new Promise((resolve, reject) => {
    const airtable = new Airtable({ apiKey: 'patmSk0sdzVet2VQD.067b5939a8026476fb7e425b473c39417eecafad467e1ca526516af244112bdc' })
    const base = airtable.base('appYzmr5BAYcj9vHc')
    const updateRecord = httpsCallable(fbFunctions, 'at-updateRecord')

    base('participaciones')
      .update(indiceParticipacion, {
        finalistaPor_valorManual: finalistaPorIndice
          ? [finalistaPorIndice]
          : null
      })
      .then(() => {
        updateRecord({ table: 'participaciones', id: indiceParticipacion })
          .then(() => {
            resolve()
          })
          .catch(console.error)
      })
      .catch(console.error)
  })
}
