<template>
  <div
    class="grid grid-cols-12 my-4 dato-group"
  >
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DatoGroup'
})
</script>

<style scoped>

</style>
