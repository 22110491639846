<template>
  <div>
    <!-- BEGIN: Hoverable Table -->
    <AlumnosRegistradosComunidad :comunidades="comunidades"/>
    <!-- END: Hoverable Table -->
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import Airtable from 'airtable'
import { orderBy } from 'lodash'
import AlumnosRegistradosComunidad from '@/views/administradores/components/estadisticas/AlumnosRegistradosComunidad'
const base = new Airtable({ apiKey: 'patmSk0sdzVet2VQD.067b5939a8026476fb7e425b473c39417eecafad467e1ca526516af244112bdc' }).base('appYzmr5BAYcj9vHc')

export default defineComponent({
  components: { AlumnosRegistradosComunidad },
  data() {
    return {
      comunidades: []
    }
  },
  computed: {
    ...mapState('administradores/auth', ['administrador', 'usuario']),
    estudiosOrdenados() {
      return orderBy(
        this.estudios,
        'orden_herramienta'
      )
    }
  },
  methods: {
    obtenerDatos() {
      this.comunidades = []
      this.estudios = []

      base('comunidades').select().eachPage((records, fetchNextPage) => {
        const recs = []

        records.forEach(record => {
          record.fields.id = record.id
          recs.push(record.fields)
        })

        this.comunidades.push(...recs)

        fetchNextPage()
      })
    }
  },
  mounted() {
    if (this.administrador.global) {
      this.obtenerDatos()
    }
  }
})
</script>

<style scoped>

</style>
