import { orderBy } from 'lodash'

export function campusesOrdenados(state) {
  return orderBy(
    state.campuses,
    'orden'
  )
}

export function comunidadesOrdenados(state) {
  return orderBy(
    state.comunidades,
    'orden'
  )
}

export function departamentosOrdenados(state) {
  return orderBy(
    state.departamentos,
    'orden'
  )
}

export function estudiosOrdenados(state) {
  return orderBy(
    state.estudios,
    'orden'
  )
}

export function generosOrdenados(state) {
  return orderBy(
    state.generos,
    'orden'
  )
}

export function generosTiposOrdenados(state) {
  return orderBy(
    state.generosTipos,
    'orden'
  )
}

export function programasOrdenados(state) {
  return orderBy(
    state.programas,
    'orden'
  )
}

export function nacimientosAniosOrdenados(state) {
  return orderBy(
    state.nacimientosAnios,
    'orden',
    'desc'
  )
}

export function nacimientosDiasOrdenados(state) {
  return orderBy(
    state.nacimientosDias,
    'orden'
  )
}

export function nacimientosMesesOrdenados(state) {
  return orderBy(
    state.nacimientosMeses,
    'orden'
  )
}
