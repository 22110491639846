<template>
<div class="py-4" v-if="cargado===1">

  <h1 class="text-xl">Alumnos por Nivel de Estudios {{ estudios.nombre }}</h1>
  <TablaAlumnos :alumnos="alumnos"></TablaAlumnos>
</div>
</template>

<script>
import { mapActions } from 'vuex'
import TablaAlumnos from '@/components/alumnos/TablaAlumnos'
import { orderBy } from 'lodash'

export default {
  name: 'AlumnosRegistradosEstudios',
  components: { TablaAlumnos },
  data() {
    return {
      estudios: null,
      cargado: 0,
      alumnos: []
    }
  },
  computed: {
    alumnosOrdenados() {
      return orderBy(
        this.alumnos,
        'orden'
      )
    }
  },
  methods: {
    ...mapActions('administradores/convocatorias', ['obtenerEstudiosPorCodigo', 'obtenerAlumnosEstudios']),
    buscarAlumnos: function () {
      if (this.cargado < 1) {
        return
      }
      this.obtenerAlumnosEstudios({
        estudiosIndice: this.estudios.indice
      })
        .then(alumnos => {
          this.alumnos = alumnos
        })
    }
  },
  mounted() {
    this.obtenerEstudiosPorCodigo(this.$route.params.estudios)
      .then(estudios => {
        this.estudios = estudios
        this.cargado++
        this.buscarAlumnos()
      })
  }
}
</script>

<style scoped>

</style>
