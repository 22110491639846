<template>
  <div
    class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
  >
    <dt class="text-sm font-medium text-gray-500">{{ dato.nombre }}:</dt>
    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" v-html="texto"></dd>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

export default defineComponent({
  name: 'DatoParticipacion',
  props: {
    participacion: {
      type: Object,
      required: true
    },
    dato: {
      type: Object,
      required: true
    },
    textoManual: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      datoVinculado: ''
    }
  },

  computed: {
    texto() {
      return this.$props.textoManual ? this.$props.textoManual : this.$props.dato.tablas ? this.datoVinculado : this.$props.participacion[this.$props.dato.clave]
    }
  },

  methods: {
    ...mapActions('administradores/convocatorias', ['obtenerDatoDeTablaDatosConvocatoria'])
  },

  mounted() {
    if (this.$props.dato.tablas) {
      this.obtenerDatoDeTablaDatosConvocatoria({
        tabla: this.$props.dato.tablas,
        indice: this.$props.participacion[this.$props.dato.clave]
      })
        .then(response => {
          if (response.nombre) { this.datoVinculado = response.nombre }
        })
    }
  }
})
</script>

<style scoped>

</style>
