<template>
  <div>
    <MobileMenu />
    <div class="flex">
      <!-- BEGIN: Side Menu -->
      <nav class="side-nav">
        <!-- BEGIN: Logo -->
        <router-link
          :to="{ name: 'admin.dashboard' }"
          tag="a"
          class="intro-x flex items-center pl-5 pt-4"
        >
          <img
            alt="Vibrart 2025"
            class="w-full mx-auto"
            src="@/assets/images/vibrart-logo.png"
          />
        </router-link>
        <!-- END: Logo -->
        <div class="side-nav__devider my-6"></div>
        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="menu === 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
            ></li>
            <li v-else :key="menu + menuKey">
              <SideMenuTooltip
                tag="a"
                :content="menu.title"
                :href="
                  menu.subMenu
                    ? 'javascript:;'
                    : router.resolve({
                        name: menu.pageName,
                        params: menu.params ?? null
                      }).path
                "
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <component :is="menu.icon" />
                </div>
                <div class="side-menu__title">
                  {{ menu.title }}
                  <div
                    v-if="menu.subMenu"
                    class="side-menu__sub-icon"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </SideMenuTooltip>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subMenu && menu.activeDropdown" class="ml-2">
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                    :key="subMenuKey"
                  >
                    <SideMenuTooltip
                      tag="a"
                      :content="subMenu.title"
                      :href="
                        subMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({
                              name: subMenu.pageName,
                              params: subMenu.params ?? null
                            }).path
                      "
                      class="side-menu"
                      :class="{ 'side-menu--active': subMenu.active }"
                      @click="linkTo(subMenu, router, $event)"
                    >
                      <!--                      <div class="side-menu__icon">
                        <ActivityIcon />
                      </div>-->
                      <div class="menu__icon">
                        <component :is="subMenu.icon" />
                      </div>
                      <div class="side-menu__title">
                        {{ subMenu.title }}
                        <div
                          v-if="subMenu.subMenu"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul
                        v-if="subMenu.subMenu && subMenu.activeDropdown"
                        class="ml-2"
                      >
                        <li
                          v-for="(lastSubMenu,
                          lastSubMenuKey) in subMenu.subMenu"
                          :key="lastSubMenuKey"
                        >
                          <SideMenuTooltip
                            tag="a"
                            :content="lastSubMenu.title"
                            :href="
                              lastSubMenu.subMenu
                                ? 'javascript:;'
                                : router.resolve({
                                    name: lastSubMenu.pageName,
                                    params: lastSubMenu.params ?? null
                                  }).path
                            "
                            class="side-menu"
                            :class="{ 'side-menu--active': lastSubMenu.active }"
                            @click="linkTo(lastSubMenu, router, $event)"
                          >
                            <!--                            <div class="side-menu__icon">
                              <ActivityIcon />
                            </div>-->
                            <div class="menu__icon">
                              <component :is="lastSubMenu.icon" />
                            </div>
                            <div class="ml-12 side-menu__title">
                              {{ lastSubMenu.title }}
                            </div>
                          </SideMenuTooltip>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div class="content">
        <TopBar />
        <router-view :key="$route.fullPath" />
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
// Libraries / Utilities
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { helper as $h } from '@/utils/helper'

// Components
import TopBar from '@/layouts/administradores/components/top-bar/Main.vue'
import MobileMenu from '@/layouts/administradores/components/mobile-menu/Main.vue'
import SideMenuTooltip from '@/layouts/administradores/components/side-menu-tooltip/Main.vue'
import { linkTo, nestedMenu, enter, leave } from '../index'

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    SideMenuTooltip
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const formattedMenu = ref([])
    const store = useStore()

    let sideMenu = computed(() =>
      nestedMenu(store.state.administradores.menu, route)
    )

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
    )

    watch(store.state.administradores.menu, () => {
      sideMenu = computed(() =>
        nestedMenu(store.state.administradores.menu, route)
      )
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })

    onMounted(() => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .addClass('main')
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })

    return {
      router,
      formattedMenu,
      linkTo,
      enter,
      leave
    }
  }
})
</script>

<style>
.app-version {
  color: rgba(0, 0, 0, 0.5);
}
</style>
