<template>
  <div id="hoverable-table" class="p-5 pt-0 mt-8">
    <h4 class="mb-5 font-bold">Alumnos: {{ alumnos.length }}</h4>
    <div class="preview">
      <div class="overflow-x-auto">
        <table class="table">
          <thead>
          <tr>
            <th
              v-for="(column, key) in columns"
              :key="`column-${key}`"
              class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              :class="column.headerClass"
            >
              {{ column.title }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="alumno in alumnosOrdenados"
            :key="`alumno-${alumno.indice}`"
            class="hover:bg-gray-200"
          >
            <td
              v-for="(column, key) in columns"
              :key="`alumno-${alumno.indice}-columna-${key}`"
              class="border"
            >
              {{ column.value(alumno) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { orderBy } from 'lodash'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'TablaAlumnos',

  props: {
    alumnos: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      columns: [
        { title: 'Nombre', value: (item) => item.nombre, headerClass: 'w-60' },
        { title: 'Correo', value: (item) => item.correoPersonal, headerClass: 'w-48' },
        { title: 'Celular', value: (item) => this.formatPhoneNumber(item.celular), headerClass: 'w-36' },
        { title: 'Convocatorias de Interés', value: (item) => item.convocatorias_nombres.join(', ').replaceAll('Concurso de ', ''), headerClass: '' },
        { title: 'Campus', value: (item) => item.campuses_nombre, headerClass: 'w-48' },
        { title: 'Estudios', value: (item) => item.estudios_nombre, headerClass: 'w-48' },
        { title: 'Programa', value: (item) => item.programas_nombre, headerClass: 'w-48' }
      ]
    }
  },

  computed: {
    ...mapState('administradores/auth', ['administrador', 'usuario']),
    alumnosOrdenados() {
      return orderBy(
        this.$props.alumnos,
        /* this.$props.alumnos.filter((campus) => {
          return this.administrador.convocatorias_indice ? this.administrador.convocatorias_indice === campus.campuses_indices : true
        }), */
        'orden'
      )
    },
    formatPhoneNumber() {
      return (phoneNumberString) => {
        if (typeof phoneNumberString !== 'string') { return phoneNumberString }

        const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          const intlCode = (match[1] ? '+1 ' : '')
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }

        return phoneNumberString
      }
    }
  }
})
</script>
