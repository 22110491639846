import { fbAuth, fbFirestore } from '@/plugins/firebase'
import { collection, limit, onSnapshot, query, where } from 'firebase/firestore'
import store from '@/store'

export default () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = fbAuth.onAuthStateChanged(fbUser => {
      if (fbUser) {
        unsubscribe()
        const q = query(
          collection(fbFirestore, 'colaboradores'),
          where('usuario_indice', '==', fbUser.uid),
          limit(1)
        )

        onSnapshot(q, (querySnapshot) => {
          if (!querySnapshot.docs.length) {
            store.dispatch('colaboradores/auth/asignarUsuario', { fbUser, tecUser: null })
            resolve(fbUser)
          }

          querySnapshot.docChanges().forEach((change) => {
            if (change.type === 'added' || change.type === 'modified') {
              store.dispatch('colaboradores/auth/asignarUsuario', { fbUser, tecUser: change.doc.data() })
            }

            if (change.type === 'added') {
              resolve(change.doc.data())
            }
          })
        })
      } else {
        resolve()
      }
    }, reject)
  })
}
