<template>
  <div v-if="convocatoria" class="mt-8">
    <div class="flex flex-row justify-items-around pl-2">
      <h2 class="my-auto text-lg">Alumnos: {{ alumnos.length }}</h2>

      <button class="btn btn-primary ml-auto" @click="enviarRecordatorios" :disabled="enviando">
        {{ enviando ? 'Programando...' : 'Programar Envio a Todos' }}
      </button>
    </div>

    <div id="hoverable-table" class="pt-0 mt-8">
      <div class="preview">
        <div class="overflow-x-auto">
          <table class="table">
            <thead>
            <tr>
              <th
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Convocatoria
              </th>
              <th
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Correo
              </th>
              <th
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Campus
              </th>
              <th
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap"
              >
                Estudios
              </th>
              <th
                class="border border-b-2 dark:border-dark-5 whitespace-nowrap w-56"
              >
                Acciones
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="alumno in alumnosOrdenados"
              :key="`alumno-${alumno.indice}`"
              class="hover:bg-gray-200"
            >
              <td class="border">
                {{ alumno.nombre }}
              </td>
              <td class="border">
                {{ alumno.correoPersonal }}
              </td>
              <td class="border">
                {{ alumno.campuses_nombre }}
              </td>
              <td class="border">
                {{ alumno.estudios_nombre }}
              </td>
              <td class="border text-center">
                <button
                  class="btn"
                  :class="alumno.recordatorioEnviado ? 'btn-success' : 'btn-primary'"
                  @click="enviarRecordatorio(alumno)" :disabled="alumno.enviandoRecordatorio || alumno.recordatorioEnviado"
                >
                  {{ alumno.enviandoRecordatorio ? 'Enviando Recordatorio' : alumno.recordatorioEnviado ? 'Recordatorio Enviado' : 'Enviar Recordatorio'}}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <p v-else class="p-8">Cargando...</p>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { orderBy } from 'lodash'
import { init, send } from 'emailjs-com'

export default defineComponent({
  data() {
    return {
      enviando: false,
      programados: 0,
      convocatoria: null,
      alumnos: []
    }
  },

  computed: {
    alumnosOrdenados() {
      return orderBy(
        this.alumnos,
        [
          'campuses_nombre',
          'estudios_nombre',
          'nombre'
        ]
      )
    }
  },

  methods: {
    ...mapActions('administradores/convocatorias', ['obtenerConvocatoriaPorCodigo', 'obtenerAlumnosPorConvocatoria']),
    enviarRecordatorios() {
      let i = 0
      const porEnviar = this.alumnosOrdenados.length

      this.programados = 0
      this.enviando = true

      this.alumnosOrdenados.forEach(alumno => {
        setTimeout(() => {
          this.programados++
          if (this.programados === porEnviar) { this.enviando = false }
          this.enviarRecordatorio(alumno)
        }, i)
        i += 250
      })
    },
    enviarRecordatorio(alumno) {
      console.log(`Enviando recordatorio a alumno ${alumno.nombre} al correo ${alumno.correoPersonal}`)
      alumno.enviandoRecordatorio = true

      send(
        'tec_lifers',
        this.convocatoria.recordatorio,
        {
          to_name: alumno.nombre,
          to_mail: alumno.correoPersonal,
          convocatoria_nombre: this.convocatoria.nombre,
          reply_to: 'lifers@itesm.mx'
        }
      ).then(() => {
        alumno.recordatorioEnviado = true
        alumno.enviandoRecordatorio = false
      })
    }
  },

  mounted() {
    this.obtenerConvocatoriaPorCodigo(this.$route.params.convocatoria)
      .then(convocatoria => {
        this.convocatoria = convocatoria
        this.obtenerAlumnosPorConvocatoria({ convocatoriaIndice: convocatoria.indice })
          .then(alumnos => {
            this.alumnos = alumnos.map(alumno => {
              alumno.enviandoRecordatorio = false
              alumno.recordatorioEnviado = false
              if (window.webpackHotUpdatevibrart_2022) {
                alumno.correoPersonal = 'diegoayalae@gmail.com'
              }
              return alumno
            })

            init('user_xto8jymyyGEELd5ygG9uT')
          })
          .catch(console.error)
      })
      .catch(console.error)
  }
})
</script>

<style scoped>

</style>
