import auth from './auth'
import convocatorias from './convocatorias'
import documentos from './documentos'
import menu from './menu'

export default {
  namespaced: true,
  state: {
    menu
  },
  modules: {
    auth,
    convocatorias,
    documentos
  }
}
