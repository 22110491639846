<template>
  <div class="border-t border-gray-200">
    <div class="bg-white shadow overflow-hidden sm:rounded-lg m-8">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">{{ participacion.nombre }}</h3>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:p-0" v-if="administrador.seleccionar">
        <dl class="sm:divide-y sm:divide-gray-200">
          <DatoParticipacion :participacion="participacion" :dato="{ nombre: 'Campus', tablas: null }"
                             :texto-manual="participacion.campuses_nombre"/>
          <DatoParticipacion :participacion="participacion" :dato="{ nombre: 'Matrícula', tablas: null }"
                             :texto-manual="participacion.administradorMatricula"/>
          <DatoParticipacion :participacion="participacion" :dato="{ nombre: 'Alumno', tablas: null }"
                             :texto-manual="participacion.administradorNombre"/>
          <DatoParticipacion :participacion="participacion" :dato="{ nombre: 'Correo', tablas: null }"
                             :texto-manual="participacion.administradorCorreoPersonal"/>
          <DatoParticipacion :participacion="participacion" :dato="{ nombre: 'Celular', tablas: null }"
                             :texto-manual="participacion.administradorCelular"/>
        </dl>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl class="sm:divide-y sm:divide-gray-200">
          <DatoParticipacion :participacion="participacion" :dato="{ nombre: 'Estudios', tablas: null }"
                             :texto-manual="participacion.estudios_nombre"/>
          <DatoParticipacion
            v-for="dato in datos"
            :key="`participacion-${participacion.indice}-dato-${dato.indice}`"
            :participacion="participacion"
            :dato="dato"
          />
          <!-- Participantes -->
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
               v-if="participantes.length && administrador.seleccionar">
            <dt class="text-sm font-medium text-gray-500" :class="galeria ?? 'col-span-3'">Participantes</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0" :class="galeria ? 'sm:col-span-3' : 'sm:col-span-2'">
              <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                <ParticipanteParticipacion
                  v-for="participante in participantesOrdenados"
                  :key="`participacion-${participacion.indice}-participante-${participante.indice}`"
                  :participante="participante"
                />
              </ul>
            </dd>
          </div>

          <!-- Archivos -->
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500" :class="galeria ?? 'col-span-3'">Archivos</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0" :class="galeria ? 'sm:col-span-3' : 'sm:col-span-2'">
              <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                <ArchivoParticipacion
                  v-for="archivo in archivos"
                  :key="`participacion-${participacion.indice}-archivo-${archivo.indice}`"
                  :participacion="participacion"
                  :archivo="archivo"
                />
              </ul>
            </dd>
          </div>

          <!-- Comentarios -->
          <div class="border-t border-gray-200 px-4 py-5 sm:p-0" v-if="administrador.seleccionar">
            <dl class="sm:divide-y sm:divide-gray-200">
              <DatoParticipacion :participacion="participacion" :dato="{ nombre: 'Comentarios', tablas: null }"
                                 :texto-manual="nl(participacion.comentarios)" />
            </dl>
          </div>
        </dl>
      </div>
    </div>
  </div>

  <!-- Acciones -->
  <div class="flex flex-row gap-4 justify-center" v-if="!finalistas">
    <div class="text-center pb-5" v-if="administrador.finalistas">
      <button class="btn btn-primary mx-auto" :disabled="procesandoFinal" v-if="!participacion.finalista"
              @click="finalistaParticipacion">
        {{ procesandoFinal ? 'Seleccionando...' : 'Finalista' }}
      </button>
      <button class="btn bg-theme-5 text-white mx-auto" :disabled="procesandoFinal" v-if="participacion.finalista"
              @click="quitarFinalistaParticipacion">
        {{ procesandoFinal ? 'Quitando de Finalistas' : 'Quitar de Finalistas' }}
      </button>
    </div>
    <div class="text-center pb-5" v-if="administrador.seleccionar">
      <button class="btn btn-primary mx-auto" :disabled="procesando" v-if="!participacion.seleccionada"
              @click="seleccionarParticipacion">
        {{ procesando ? 'Seleccionando...' : 'Seleccionar' }}
      </button>
      <button class="btn bg-theme-5 text-white mx-auto" :disabled="procesando" v-if="participacion.seleccionada"
              @click="quitarSeleccionParticipacion">
        {{ procesando ? 'Quitando de Selección' : 'Quitar de Selección' }}
      </button>
    </div>
    <div class="text-center pb-5">
      <button class="btn btn-primary mx-auto" :disabled="revisando" v-if="!visualizada(participacion.indice)"
              @click="agregarVisualizacion">
        {{ revisando ? 'Guardando como Revisada...' : 'Revisada' }}
      </button>
      <button class="btn bg-secondary mx-auto" :disabled="revisando" v-if="visualizada(participacion.indice)"
              @click="quitarVisualizacion">
        {{ revisando ? 'Quitando como Revisada...' : 'No Revisada' }}
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { orderBy } from 'lodash'
import DatoParticipacion from '@/views/administradores/components/participaciones/DatoParticipacion.vue'
import ArchivoParticipacion from '@/views/administradores/components/participaciones/ArchivoParticipacion.vue'
import ParticipanteParticipacion from '@/views/administradores/components/participaciones/ParticipanteParticipacion.vue'

export default defineComponent({
  components: {
    ParticipanteParticipacion,
    ArchivoParticipacion,
    DatoParticipacion
  },

  props: {
    finalistas: {
      type: Boolean,
      default: false
    },
    participacion: {
      required: true,
      type: Object
    },
    galeria: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      procesandoFinal: false,
      procesando: false,
      revisando: false,
      participantes: []
    }
  },

  computed: {
    ...mapState('administradores/auth', ['administrador']),
    ...mapState('administradores/convocatorias', ['visualizaciones']),
    ...mapGetters('administradores/convocatorias', ['participacionesDatosPorParticipacionTipo']),
    nl() {
      return (texto) => {
        return texto ? texto.replaceAll('\n', '<br />') : ''
      }
    },
    datos() {
      return this.participacionesDatosPorParticipacionTipo(this.$props.participacion.participacionesTipos_indice)
        .filter(participacionDato => participacionDato.tipo !== 'Archivo')
    },
    archivos() {
      return this.participacionesDatosPorParticipacionTipo(this.$props.participacion.participacionesTipos_indice)
        .filter(participacionDato => participacionDato.tipo === 'Archivo' && (this.administrador.seleccionar ? true : !participacionDato.privado))
    },
    visualizada() {
      return (participacionIndice) => {
        return this.visualizaciones && this.visualizaciones.participaciones_indices && Array.isArray(this.visualizaciones.participaciones_indices) ? this.visualizaciones.participaciones_indices.includes(participacionIndice) : false
      }
    },
    participantesOrdenados() {
      return orderBy(
        this.participantes,
        'orden'
      )
    }
  },

  methods: {
    ...mapActions('administradores/convocatorias', [
      'actualizarSeleccionParticipacion',
      'agregarVisualizacionParticipacion',
      'quitarVisualizacionParticipacion',
      'obtenerParticipantesPorParticipacionIndice',
      'actualizarFinalistaParticipacion'
    ]),
    finalistaParticipacion() {
      this.procesandoFinal = true
      this.actualizarFinalistaParticipacion({
        indiceParticipacion: this.$props.participacion.indice,
        finalistaPorIndice: this.administrador.indice
      })
        .finally(() => {
          this.procesandoFinal = false
        })
    },
    quitarFinalistaParticipacion() {
      this.procesandoFinal = true
      this.actualizarFinalistaParticipacion({
        indiceParticipacion: this.$props.participacion.indice,
        finalistaPorIndice: null
      })
        .finally(() => {
          this.procesandoFinal = false
        })
    },
    seleccionarParticipacion() {
      this.procesando = true
      this.actualizarSeleccionParticipacion({
        indiceParticipacion: this.$props.participacion.indice,
        seleccionadoPorIndice: this.administrador.indice
      })
        .finally(() => {
          this.procesando = false
        })
    },
    quitarSeleccionParticipacion() {
      this.procesando = true
      this.actualizarSeleccionParticipacion({
        indiceParticipacion: this.$props.participacion.indice,
        seleccionadoPorIndice: null
      })
        .finally(() => {
          this.procesando = false
        })
    },
    agregarVisualizacion() {
      this.revisando = true
      this.agregarVisualizacionParticipacion({
        administradorIndice: this.administrador.indice,
        participacionIndice: this.$props.participacion.indice
      }).then(() => {
        this.revisando = false
      })
    },
    quitarVisualizacion() {
      this.revisando = true
      this.quitarVisualizacionParticipacion({
        administradorIndice: this.administrador.indice,
        participacionIndice: this.$props.participacion.indice
      }).then(() => {
        this.revisando = false
      })
    }
  },

  mounted() {
    this.obtenerParticipantesPorParticipacionIndice({ participacionIndice: this.participacion.indice })
      .then(participantes => {
        this.participantes = participantes
      })
  }
})
</script>
